<template>
  <div id="pc-root">
    <Header />
    <Sidebar />
    <router-view class="root-router" />
    <Footer />
  </div>
</template>


<script setup>
import { ref, onMounted } from "vue";
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import log from "@/utils/logger";
import { bus } from "@/components/core/bus";
import { watch } from "vue";
import { reactive } from "vue";
import { LiveChatWidget } from '@livechat/widget-vue'
import Header from "@/views/pc/components/Header.vue";
import Sidebar from "@/views/pc/components/Sidebar.vue";
import Footer from "@/views/pc/components/Footer.vue";

const router = useRouter();
const curRouter = useRoute();
const { isLogged } = useGetters("tcp", ["isLogged"]);


const openLiveChat = ref(false);

bus.off(bus.event.jumpToTop);
bus.on(bus.event.jumpToTop, () => {
  jumpToTop();
})
bus.on(bus.event.openLiveChat, (bool) => {
  openLiveChat.value = true;
})
function jumpToTop() {
  document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  let pcRoot = document.getElementById('pc-root');
  if (pcRoot) {
    pcRoot.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

</script>

<style lang="scss">
#pc-root {
  height: 100%;
  position: relative;
  min-width: 1000px;
}
.com-container {
  width: 1340px;
}
.root-router {
  // margin: -70px 0 0;
}
.root-router:before {
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .com-container {
    width: 1000px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1340px) {
  .com-container {
    width: 1200px !important;
  }
}
@media screen and (max-width: 1200px) {
  .com-container {
    width: 1200px !important;
  }
}
</style>
