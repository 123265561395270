import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import log from "@/utils/logger";
import i18n from "./language/i18n";
// import "lib-flexible";
import { img } from "@/utils/img";
import "@/components/core/native";
import "@/utils/date";
import { theme } from "@/views/theme";
import "xe-utils";
import tools from "@/utils/tools";
import action from "@/components/core/action";
import "@/assets/fonts/element-icons.css";
import "@/assets/fonts/fontawesome.css";
import "@/assets/fonts/glyphicon.css";
import "@/assets/css/reset.css";
import "@/assets/css/common.css";
/*-------------------------------------------------------------------------*/
const app = createApp(App);
app.config.globalProperties.$tools = tools;
app.config.globalProperties.$act = action;
app.config.globalProperties.$img = img;
app.config.globalProperties.$log = log;
app.use(i18n);
app.use(store);
app.use(router);
require("./main_ui").install(app);
app.mount("#app");
