<template>
  <div class="systemMailPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="close-wrapper">
        <button type="button" class="dark-close" @click="close()"></button>
      </div>
      <div class="remindinfo-block">
        <div class="title-block">
          <div class="popup-icon"></div>
          <h2 class="remindinfo-title">{{ props.data.Title }}</h2>
        </div>
        <div class="remindinfo-bg">
          <div class="remindinfo-inner">
            <div class="v-html-default overflowContent blockContent" v-html="tools.getCurLangValue(props.data.Content)"></div>
          </div>
          <div class="submitbtn-wrap">
            <button class="delete-msg-btn" @click="deleteClick()">
              <i class="glyphicon glyphicon-trash"></i>
              <span>{{t('Delete')}}</span>
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {}
})

function deleteClick() {
  let parms = {
    MailType: props.data.MailType,
    Oid: props.data.Oid,
  }
  log.info('parms--', parms)
  mqant.request(topic.deleteMail, parms, function (data, topicName, msg) {
    log.info('deleteMail--', data.Data)
    if (data.Code == 0) {
      ElMessage.success(t('Operation successful'))
      log.info('deleteMail--', data.Data)
      bus.emit(bus.event.systemMailNtf);
      close();
    } else {
    }
  });
}

function close() {
  bus.emit(bus.event.systemMailPop, { bool: false, data: {} });
}
</script>

<style lang="scss" scoped>
.systemMailPop {
  position: relative;
  text-align: center;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    border-radius: 2px;
    position: relative;
    margin: 0 auto 50px;
    width: 700px;
    max-width: 100%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #606266;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .close-wrapper {
        .dark-close {
          position: absolute;
          top: 80px;
          right: 40px;
          z-index: 10;
          padding: 0;
          border: 0;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          background: url("@/assets/pc/common/dark-close.png");
        }
      }

      .remindinfo-block {
        position: relative;
        padding-top: 45px;

        .title-block {
          background-color: #dfe6ea;
          padding: 20px;
          text-align: center;
          position: relative;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;

          .popup-icon {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -45px;
            background: #ff8a00;
          }

          .popup-icon:before {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 50%;
            filter: grayscale(100%) brightness(250%);
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: url("@/assets/pc/pop/systemnews-icon.png");
          }

          .remindinfo-title {
            color: #ff8a00;
            word-break: break-all;
            margin: 50px 0 0;
            word-wrap: break-word;
            font-size: 30px;
            font-weight: 500;
          }
        }

        .remindinfo-bg {
          background-color: #dfe6ea;
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
          margin-top: -1px;
          padding-top: 1px;

          .remindinfo-inner {
            *zoom: 1;
            text-align: left;
            padding: 20px;

            .overflowContent {
              overflow-x: auto;
            }
          }

          .submitbtn-wrap {
            text-align: center;
            padding-bottom: 40px;

            button {
              background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(#2bb),
                to(#1a9090)
              );
              background-image: linear-gradient(180deg, #2bb 0, #1a9090);
              background-repeat: repeat-x;
              -webkit-box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
              box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
              -webkit-transition: all 0.5s ease;
              transition: all 0.5s ease;
              cursor: pointer;
              display: inline-block;
              width: auto;
              height: auto;
              padding: 10px 40px;
              font-size: 18px;
              color: #fff;
              line-height: 30px;
              letter-spacing: 1px;
              text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
              border-radius: 50px;
              border: 2px solid #1a9090;
            }

            button:hover {
              background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(#1a9090),
                to(#2bb)
              );
              background-image: linear-gradient(180deg, #1a9090 0, #2bb);
              background-repeat: repeat-x;
            }

            .delete-msg-btn {
              margin: 0 5px;
              display: -webkit-inline-box !important;
              display: -ms-inline-flexbox !important;
              display: inline-flex !important;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              background: #959595;
              color: #000;
              font-size: 16px;
              font-weight: 500;
              border-radius: 200px;
              letter-spacing: normal;
              text-shadow: none;
              -webkit-box-shadow: none;
              box-shadow: none;
              border: none;

              .glyphicon-trash {
                margin-right: 5px;
              }
            }
          }

          h4 {
            font-size: 18px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 500;
          }

          .link {
            cursor: pointer;
            display: inline-block;

            img {
              width: 200px;
              height: 200px;
            }
          }
        }
      }
    }
  }
}
</style>