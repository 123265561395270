<template>
  <div class="loginRegisterPop">
    <el-dialog v-model="show" align-center :show-close="false" @close="close()">
      <div class="modal-content -modal-content">
        <button type="button" class="close" @click="close()">
          <i class="fas fa-times"></i>
        </button>
        <div class="x-modal-account-security-tabs">
          <button type="button" class="-btn" :class="tabIndex == 1 ? '-active':''" @click="changeTabIndex(1)">
            {{t('Apply for membership')}}
            <img class="img-fluid " src="@/assets/pc/login/register-badge-new-user.png">
          </button>
          <button type="button" class="-btn" :class="tabIndex == 0 ? '-active':''" @click="changeTabIndex(0)">
            {{t('Login')}}
          </button>
        </div>
        <div class="modal-body -modal-body">
          <div class="x-register-tab-container">
            <div v-if="tabIndex == 0" class="tab-content">
              <div class="tab-pane" :class="loginForm.stepObj.phone ? 'active':''">
                <div class="x-modal-body-base">
                  <div class="row -register-container-wrapper">
                    <div class="col">
                      <div class="x-title-register-modal-v3 ">
                        <span class="-title">{{t('Enter your phone number.')}}</span>
                        <span class="-sub-title">{{t('Phone number used to register to log in')}}</span>
                      </div>
                    </div>
                    <div class="col animated fadeInRegister">
                      <div class="-fake-inner-body">
                        <div class="card-bank-info">
                          <div class="-x-input-icon">
                            <img class="-icon img-fluid" src="@/assets/pc/login/ic-input-phone.png">
                            <input type="text" v-model="loginForm.phone" :maxlength="phoneNumberLen+3" autocomplete="off" id="loginPhoneNumber" name="loginPhoneNumber" :placeholder="getPhonePlace()" class="x-form-control" @input="loginPhoneInput">
                          </div>
                          <div class="text-center">
                            <div class="js-bank-number-and-name-container">
                              <div class="-x-input-icon">
                                <img class="-icon img-fluid" src="@/assets/pc/login/ic-input-lock.png">
                                <input :type="loginForm.showPass ? 'text' : 'password'" v-model="loginForm.password" class="x-form-control" placeholder="XXXXXXXX">
                                <span class="icon">
                                  <img v-if="loginForm.showPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="loginForm.showPass = !loginForm.showPass">
                                  <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="loginForm.showPass = !loginForm.showPass">
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                          <button type="submit" class="btn -submit" :disabled="!loginForm.phoneClick" @click="loginFinalSubmit()">
                            {{t('Login')}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="tab-pane" :class="loginForm.stepObj.password ? 'active':''">
                <div class="x-modal-body-base">
                  <div class="row -register-container-wrapper">
                    <div class="col">
                      <div class="x-title-register-modal-v3">
                        <span class="-title">{{t('Password')}}</span>
                        <span class="-sub-title">{{t('Enter the login code.')}}</span>
                      </div>
                    </div>
                    <div class="col animated fadeInRegister">
                      <div class="x-form-register">
                        <div class="-animatable-container animated fadeInRegister">
                          <div class="x-bank-account-form">
                            <div class="-animatable-container animated fadeInModal">
                              <div class="card-bank-info">
                                <div class="text-center">
                                  <div class="js-bank-number-and-name-container">
                                    <div class="-x-input-icon">
                                      <img class="-icon img-fluid" src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezs/ic-library-book-v3.png">
                                      <input type="password" v-model="loginForm.password" class="x-form-control" placeholder="XXXXXXXX">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-center">
                                <button class="btn  -submit login-submit " @click="loginFinalSubmit()">
                                  {{t('Confirm')}}
                                </button>
                              </div>
                              <div class="x-reset-pw-text-container">
                                <button class="-btn-reset-password" @click="forgotpass()">
                                  <u>{{t('Forgot your password?')}}</u>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div v-if="tabIndex == 1" class="tab-content">
              <div class="tab-pane" :class="registerForm.stepObj.phone ? 'active':''">
                <div class="x-modal-body-base">
                  <div class="row -register-container-wrapper">
                    <div class="col">
                      <div class="x-title-register-modal-v3 ">
                        <span class="-title">{{t('Enter your phone number.')}}</span>
                        
                      </div>
                    </div>
                    <div class="col animated fadeInRegister">
                      <div class="-fake-inner-body">
                        <div class="-x-input-icon">
                          <input type="text" v-model="registerForm.phone" :maxlength="phoneNumberLen+3" autocomplete="off" id="registerPhoneNumber" name="phoneNumber" :placeholder="getPhonePlace()" class="form-control" @input="registerPhoneInput">
                        </div>
                        <div class="x-text-with-link">
                          <span class="-message">{{t('Encountering problems')}} </span>
                          <a class="-btn-link -x" @click="forgotpass()">{{t('Please contact customer service')}}</a>
                        </div>
                        <div class="text-center">
                          <button type="submit" class="btn -submit" :disabled="!registerForm.phoneClick" @click="registerPhoneSubmit()">
                            {{t('Confirm')}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" :class="registerForm.stepObj.otp ? 'active':''">
                <div class="x-modal-body-base">
                  <div class="row -register-container-wrapper">
                    <div class="col">
                      <div class="x-title-register-modal-v3 ">
                        <span class="-title">{{t('Enter OTP code')}}</span>
                        <span class="-sub-title">{{t('5 digit code sent to')}}
                          <span class="js-phone-number -highlight">{{registerForm.phone}}</span>
                        </span>
                      </div>
                    </div>
                    <div class="col animated fadeInRegister">
                      <div class="x-modal-separator-container x-form-register">
                        <div class="-top">
                          <div class="-animatable-container -otp-body animated fadeInRegister">
                            <div class="d-flex -otp-input-container js-register-v3-input-group">
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerOtp0" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="registerOtpKeyup0">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerOtp1" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="registerOtpKeyup1">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerOtp2" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="registerOtpKeyup2">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerOtp3" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="registerOtpKeyup3">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerOtp4" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="registerOtpKeyup4">
                              </div>
                            </div>
                            <a class="-text-clear-otp mt-3" @click="close()">
                              {{t('Cancel')}}
                            </a>
                            <div class="text-center">
                              <button type="submit" class="btn -submit btn-primary my-lg-3 mt-0" @click="registerOtpSubmit()">
                                {{t('Confirm')}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" :class="registerForm.stepObj.setPass ? 'active':''">
                <div class="x-modal-body-base">
                  <div class="row -register-container-wrapper">
                    <div class="col">
                      <div class="x-title-register-modal-v3 ">
                        <span class="-title">{{t('Set a 6 digit code number.')}}</span>
                        <span class="-sub-title">{{t('Enter the 6 digit code number to log in.')}}
                        </span>
                      </div>
                    </div>
                    <div class="col animated fadeInRegister">
                      <div class="x-modal-separator-container x-form-register">
                        <div class="-top">
                          <div class="-animatable-container -otp-body animated fadeInRegister">
                            <div class="d-flex -password-input-container  js-register-v3-input-group">
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerSetPassword0" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="setPassKeyup0">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerSetPassword1" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="setPassKeyup1">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerSetPassword2" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="setPassKeyup2">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerSetPassword3" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="setPassKeyup3">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerSetPassword4" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="setPassKeyup4">
                              </div>
                              <div class="js-separator-container js-register-otp-container">
                                <input type="number" id="registerSetPassword5" oninput="if(value.length > 1) value = value.slice(1)" pattern="[0-9]*" class="-digit-otp js-otp-input" @keyup="setPassKeyup5">
                              </div>
                            </div>
                            <div class="text-center">
                              <button type="submit" class="btn -submit btn-primary my-lg-3 mt-0" @click="registerSetPassSubmit()">
                                {{t('Confirm')}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" :class="registerForm.stepObj.bank ? 'active':''">
                <div class="x-modal-body-base">
                  <div class="row -register-container-wrapper">
                    <div class="col">
                      <div class="x-title-register-modal-v3 ">
                        <span class="-title">{{t('bank account')}}</span>
                      </div>
                    </div>
                    <div class="col animated fadeInRegister">
                      <div class="x-form-register mt-0">
                        <div class="-animatable-container animated fadeInRegister">
                          <div class="x-bank-account-form flex-column js-bank-account-form-v3">
                            <div class="-animatable-container animated fadeInModal">
                              <div class="card-bank-info">
                                <div class="text-center">
                                  <div class="my-3 js-bank-select-container">
                                    <div class="x-bank-choices-type js-bank-account-selected-v3">
                                      <div class="-outer-wrapper">
                                        <label v-for="(item,index) in lobbyInfo.AllRegisterConf.douDouBtList" class="-label js-bank-item" @click="bankItemClick(item,index)">
                                          <img class="-logo -v3" :src="comApi.getNormalImg(item.Icon)">
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-center">
                                <button type="submit" class="btn  -submit " @click="registerBankSubmit()">
                                  {{t('Confirm')}}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" :class="registerForm.stepObj.nameAndCard ? 'active':''">
                <div class="x-modal-body-base">
                  <div class="row -register-container-wrapper">
                    <div class="col">
                      <div class="x-title-register-modal-v3 ">
                        <span class="-title">{{t('bank account')}}</span>
                      </div>
                    </div>
                    <div class="col animated fadeInRegister">
                      <div class="x-form-register mt-0">
                        <div class="-animatable-container animated fadeInRegister">
                          <div class="x-bank-name-form flex-column js-bank-account-form-v3">
                            <div class="-animatable-container animated fadeInModal">
                              <div class="card-bank-info">
                                <div class="text-center">
                                  <div class="my-3 js-bank-select-container">
                                    <div class="x-bank-choices-type js-bank-account-selected-v3">
                                      <div class="-outer-wrapper">
                                        <label class="-label js-bank-item">
                                          <img v-if="registerForm.bankIndex >= 0" class="-logo -v3" :src="comApi.getNormalImg(lobbyInfo.AllRegisterConf.douDouBtList[registerForm.bankIndex].Icon)">
                                          <div class="x-title-register-modal-v3 -bank-account-name">
                                            <span class="-title">{{registerForm.bank}}</span>
                                          </div>
                                          <i class="fas fa-pencil-square-o" @click="backToBank()"></i>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="js-bank-number-and-name-container">
                                    <div class="-x-input-icon">
                                      <img class="-icon img-fluid" src="@/assets/pc/login/yinhang_icon.png">
                                      <input type="text" v-model="registerForm.bankCard" class="x-form-control" :placeholder="t('Please enter Account Number.')" @input="registerBankNameInput">
                                    </div>
                                    <div v-if="isShowFullName()" class="-x-input-icon small-input">
                                      <img class="-icon img-fluid" src='@/assets/pc/login/xingm_icon.png'>
                                      <input type="text" v-model="registerForm.realName" :disabled="registerForm.verifyBankObj.resType == 'valid' " class="x-form-control small-input" :placeholder="t('Please enter Bank Account Holder.')">
                                    </div>

                                    <div class="-text-description">
                                      {{t('final step Fill in the details,complete and correct For your benefit')}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-center">
                                <button v-if="lobbyInfo.registerMethod == 3 && !registerForm.verifyBankObj.isVerify " type="submit" class="btn  -submit " @click="verifyBankCard()">
                                  {{t('Confirm')}}
                                </button>
                                <button v-else type="submit" class="btn  -submit " @click="registerFinalSubmit()">
                                  {{t('Confirm')}}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";


const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const { uuid, registerParams } = useGetters("global", ["uuid", "registerParams"]);
let needAutoLogin = getStatusBykey('register_autoLogin');
let needPhoneVerify = lobbyInfo.value.AllRegisterConf.RegisterPhoneVerify > 0;

const show = ref(true);
const props = defineProps({
  type: 0
})
const tabIndex = ref(props.type);
const phoneNumberLen = ref(Number(lobbyInfo.value.AllRegisterConf.phoneNumberLen));
const registerForm = reactive({
  stepObj: {
    phone: true,
    otp: false,
    setPass: false,
    bank: false,
    nameAndCard: false,
  },
  phone: '',
  bank: '',
  bankIndex: -1,
  bankCard: '',
  realName: '',
  phoneClick: false,
  verifyBankObj: {
    isVerify: false,
    resType: ''
  }
})

const loginForm = reactive({
  stepObj: {
    phone: true,
  },
  phone: '',
  password: '',
  showPass: false,
  phoneClick: false,
})

bus.on(bus.event.getBankSelectInfo, (data) => {
  registerForm.bank = data.BtName;
})


function isShowFullName() {
  if (lobbyInfo.value.registerMethod == 2) {
    return true;
  }
  if (lobbyInfo.value.registerMethod == 3) {
    return registerForm.verifyBankObj.isVerify
  }
}

function registerBankNameInput(val) {
  registerForm.bankCard = registerForm.bankCard.replace(/[^\d]/g, '');
}

function changeTabIndex(index) {
  if (index == tabIndex.value) {
    return
  }
  tabIndex.value = index;
}

function registerPhoneInput(val) {
  let elment = document.getElementById('registerPhoneNumber');
  if (val.data && (isNaN(val.data) || val.data == ' ')) {
    elment.value = elment.value.slice(0, -1);
  }
  registerForm.phone = changePhoneShow(elment.value);
  let newPhoneStr = replaceStr(registerForm.phone);
  if (newPhoneStr.length < phoneNumberLen.value || newPhoneStr.length > phoneNumberLen.value + 1) {
    registerForm.phoneClick = false;
  } else {
    if (newPhoneStr.length == phoneNumberLen.value) {
      registerForm.phoneClick = newPhoneStr[0] !== '0';
    }
    if (newPhoneStr.length == phoneNumberLen.value + 1) {
      registerForm.phoneClick = newPhoneStr[0] == '0';
    }
  }
}
function loginPhoneInput(val) {
  let elment = document.getElementById('loginPhoneNumber');
  if (val.data && (isNaN(val.data) || val.data == ' ')) {
    elment.value = elment.value.slice(0, -1);
  }
  loginForm.phone = changePhoneShow(elment.value);
  let newPhoneStr = replaceStr(loginForm.phone);
  if (newPhoneStr.length < phoneNumberLen.value || newPhoneStr.length > phoneNumberLen.value + 1) {
    loginForm.phoneClick = false;
  } else {
    if (newPhoneStr.length == phoneNumberLen.value) {
      loginForm.phoneClick = newPhoneStr[0] !== '0';
    }
    if (newPhoneStr.length == phoneNumberLen.value + 1) {
      loginForm.phoneClick = newPhoneStr[0] == '0';
    }
  }
}

function getPhonePlace() {
  let str = '095-123-';
  let limitNum = phoneNumberLen.value - 5;
  let maxNumStr = '45678900000000';
  return str + maxNumStr.slice(0, limitNum);
}
function registerBankjInput() {
  bus.emit(bus.event.bankSelectPop, { bool: true, data: { list: lobbyInfo.value.AllRegisterConf.douDouBtList } })
}

function changePhoneShow(str) {
  str = replaceStr(str);
  let [str1, str2, str3] = ['', '', ''];
  if (str.length > 3 && str.length <= 6) {
    str1 = str.slice(0, 3);
    str2 = str.slice(3);
    return str1 + '-' + str2;
  }
  if (str.length > 6) {
    str1 = str.slice(0, 3);
    str2 = str.slice(3, 6);
    str3 = str.slice(6);
    return str1 + '-' + str2 + '-' + str3;
  }
  return str;
}

function replaceStr(str) {
  return str = str.replace(/-/g, "");
}

async function registerPhoneSubmit() {
  if (!needPhoneVerify) {
    registerForm.stepObj.phone = false;
    registerForm.stepObj.setPass = true;
    return
  }
  let phoneParams = {
    phone: replaceStr(registerForm.phone),
    vType: 'phone',
    language: tools.getLanguage(),
  }
  log.info('phoneParams---', phoneParams)
  let rsp = await req.post(http.verifyRegistInfo, qs.stringify(phoneParams));
  if (rsp.status == 200 && rsp.data.Code == 0) {
    let getCodeParams = {
      phone: replaceStr(registerForm.phone),
      area: 0,
      event: 'verifyPhone',
      language: tools.getLanguage(),
    }
    log.info('getCodeParams---', getCodeParams)
    let rsp = await req.post(http.smsSend, qs.stringify(getCodeParams));
    if (rsp.status == 200 && rsp.data.Code == 0) {
      registerForm.stepObj.phone = false;
      registerForm.stepObj.otp = true;
      nextTick(() => {
        document.getElementById('registerOtp0').focus()
      })
    } else {
      registerForm.stepObj.phone = false;
      registerForm.stepObj.otp = true;
      nextTick(() => {
        document.getElementById('registerOtp0').focus()
      })
    }
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}

async function registerOtpSubmit() {
  let code = '';
  for (let i = 0; i < 5; i++) {
    code += document.getElementById('registerOtp' + i).value;
  }
  let params = {
    phone: replaceStr(registerForm.phone),
    vType: 'code',
    verifyPhone: code,
    language: tools.getLanguage(),
  }
  log.info('params---', params)
  let rsp = await req.post(http.verifyRegistInfo, qs.stringify(params));
  log.info('verifyRegistInfo---', rsp.data)
  if (rsp.status == 200 && rsp.data.Code == 0) {
    registerForm.stepObj.otp = false;
    registerForm.stepObj.setPass = true;
    nextTick(() => {
      document.getElementById('registerSetPassword0').focus()
    })
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}

async function registerSetPassSubmit() {
  let password = '';
  for (let i = 0; i < 6; i++) {
    password += document.getElementById('registerSetPassword' + i).value;
  }
  let params = {
    password: password,
    vType: 'password',
    language: tools.getLanguage(),
  }
  log.info('params---', params)
  let rsp = await req.post(http.verifyRegistInfo, qs.stringify(params));
  log.info('verifyRegistInfo---', rsp.data)
  if (rsp.status == 200 && rsp.data.Code == 0) {
    registerForm.stepObj.setPass = false;
    if (lobbyInfo.value.AllRegisterConf.RegisterBindCard > 0) {
      registerForm.stepObj.bank = true;
    } else {
      registerFinalSubmit();
    }
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}

function registerBankSubmit() {
  if (registerForm.bankIndex < 0) return;
}

function bankItemClick(item, index) {
  registerForm.bank = item.BtName;
  registerForm.bankIndex = index;
  registerForm.stepObj.bank = false;
  registerForm.stepObj.nameAndCard = true;

  registerForm.bankCard = '';
  registerForm.realName = '';
  registerForm.verifyBankObj.isVerify = false;
  registerForm.verifyBankObj.resType = ''

}

function backToBank() {
  registerForm.bank = '';
  registerForm.bankIndex = -1;
  registerForm.stepObj.bank = true;
  registerForm.stepObj.nameAndCard = false;
}
async function loginPhoneSubmit() {
  let phoneParams = {
    phone: replaceStr(loginForm.phone),
    vType: 'loginPhone',
    language: tools.getLanguage(),
  }
  log.info('phoneParams---', phoneParams)
  let rsp = await req.post(http.verifyRegistInfo, qs.stringify(phoneParams));
  if (rsp.status == 200 && rsp.data.Code == 0) {
    loginForm.stepObj.phone = false;
    loginForm.stepObj.password = true;
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}

async function verifyBankCard() {
  if (!registerForm.bankCard) {
    bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('Account Number number is required') } })
    return;
  }
  let params = {
    btName: registerForm.bank,
    cardNum: registerForm.bankCard,
  }
  log.info('verifyBankCard btName---', params)
  let rsp = await req.post(http.verifyBankCard, qs.stringify(params));
  log.info('verifyBankCard rsp---', rsp)
  if (rsp && rsp.status == 200 && rsp.data.Code == 0) {
    registerForm.verifyBankObj.resType = rsp.data.Data.resType
    //合法，不合法，不支持，不支持的话则手动填写名字
    if (rsp.data.Data.resType == 'valid') {
      registerForm.verifyBankObj.isVerify = true;
      registerForm.realName = rsp.data.Data.fullName;
    } else if (rsp.data.Data.resType == 'invalid') {
      registerForm.verifyBankObj.isVerify = false;
      bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('Illegal bank card') } })
    } else if (rsp.data.Data.resType == 'unsupport') {
      registerForm.verifyBankObj.isVerify = true;
    }
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}

function registerFinalSubmit() {
  if (lobbyInfo.value.AllRegisterConf.RegisterBindCard > 0) {
    if (!registerForm.bank) {
      bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('Member Account Type is required') } })
      return;
    }
    if (!registerForm.bankCard) {
      bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('Account Number number is required') } })
      return;
    }
    if (!registerForm.realName) {
      bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('Full Name is required') } })
      return;
    }
  }

  let password = '';
  for (let i = 0; i < 6; i++) {
    password += document.getElementById('registerSetPassword' + i).value;
  }
  let params = {
    account: '',
    phone: replaceStr(registerForm.phone),
    password: password,
    platform: tools.platform(),
    channel: "",
    uuid: uuid.value,
    uuid_web: uuid.value,
    code: '',
    codeId: '',
    btName: registerForm.bank,
    accountName: registerForm.realName,
    cardNum: registerForm.bankCard,
  };
  if (registerParams.value) {
    params.params = registerParams.value;
  }
  log.info('params---', params)
  if (needAutoLogin) {
    doLogin({
      params: params,
      url: http.userRegister,
    }).then(
      (resolve) => {
        ElMessage.success(t('Register succeeded'));
        close();
        router.push({ path: '/' })
      },
      (reject) => {
        log.info('reject', reject)
        ElMessage.error(reject.ErrMsg);
      }
    );
  } else {
    doRegister({
      params: params,
      url: http.userRegister,
    }).then(
      (resolve) => {
        ElMessage.success(t('Register succeeded'));
        close();
        router.push({ path: '/' })
      },
      (reject) => {
        log.info('reject', reject)
        ElMessage.error(reject.ErrMsg);
      }
    );
  }
}

function loginFinalSubmit() {
  if (!loginForm.phone) {
    bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('Phone is required.') } })
    return;
  }
  if (!loginForm.password) {
    bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('Password is required') } })
    return;
  }
  let params = {
    account: replaceStr(loginForm.phone),
    password: loginForm.password,
    platform: tools.platform(),
    uuid: uuid.value,
    uuid_web: uuid.value,
    code: '',
    codeId: '',
  };
  log.info('params---', params)
  doLogin({
    params: params,
    url: http.userLogin,
  }).then(
    (resolve) => {
      ElMessage.success(t('Login succeeded'));
      close();
      router.push({ path: '/' })
    },
    (reject) => {
      log.info('reject', reject);
      ElMessage.error(reject.ErrMsg);
    }
  );
}

function toComInfoPop(str) {
  let data = lobbyInfo.value.MainCustomContent.find(info => info.type == 'webRule');
  bus.emit(bus.event.comInfoPop, { bool: true, data: { title: str, data: data } });
}

function forgotpass() {
  let url = lobbyInfo.value.CustomerInfo.customerLiveChat;
  $act.openBrowser(url)
}

function getStatusBykey(key) {
  let needObj = lobbyInfo.value.MainCustomContent.find(info => (info.type == 'param' && info.key == key));
  return needObj && needObj.status;
}


function registerOtpKeyup0(val) {
  let element = document.getElementById('registerOtp0');
  if (document.getElementById('registerOtp0').value.length == 1) {
    document.getElementById('registerOtp1').focus()
  }
}
function registerOtpKeyup1(val) {
  if (document.getElementById('registerOtp1').value.length == 1) {
    document.getElementById('registerOtp2').focus()
  }
  if (document.getElementById("registerOtp1").value.length == 0) {
    document.getElementById("registerOtp0").focus();
  }
}
function registerOtpKeyup2(val) {
  if (document.getElementById('registerOtp2').value.length == 1) {
    document.getElementById('registerOtp3').focus()
  }
  if (document.getElementById("registerOtp2").value.length == 0) {
    document.getElementById("registerOtp1").focus();
  }
}
function registerOtpKeyup3(val) {
  if (document.getElementById('registerOtp3').value.length == 1) {
    document.getElementById('registerOtp4').focus()
  }
  if (document.getElementById("registerOtp3").value.length == 0) {
    document.getElementById("registerOtp2").focus();
  }
}
function registerOtpKeyup4(val) {
  let elment = document.getElementById('registerOtp4');

  if (document.getElementById("registerOtp4").value.length == 0) {
    document.getElementById("registerOtp3").focus();
  }
}

function setPassKeyup0(val) {
  if (document.getElementById('registerSetPassword0').value.length == 1) {
    document.getElementById('registerSetPassword1').focus()
  }
}

function setPassKeyup1(val) {
  if (document.getElementById('registerSetPassword1').value.length == 1) {
    document.getElementById('registerSetPassword2').focus()
  }
  if (document.getElementById("registerSetPassword1").value.length == 0) {
    document.getElementById("registerSetPassword0").focus();
  }
}
function setPassKeyup2(val) {
  if (document.getElementById('registerSetPassword2').value.length == 1) {
    document.getElementById('registerSetPassword3').focus()
  }
  if (document.getElementById("registerSetPassword2").value.length == 0) {
    document.getElementById("registerSetPassword1").focus();
  }
}
function setPassKeyup3(val) {
  if (document.getElementById('registerSetPassword3').value.length == 1) {
    document.getElementById('registerSetPassword4').focus()
  }
  if (document.getElementById("registerSetPassword3").value.length == 0) {
    document.getElementById("registerSetPassword2").focus();
  }
}
function setPassKeyup4(val) {
  if (document.getElementById('registerSetPassword4').value.length == 1) {
    document.getElementById('registerSetPassword5').focus()
  }
  if (document.getElementById("registerSetPassword4").value.length == 0) {
    document.getElementById("registerSetPassword3").focus();
  }
}
function setPassKeyup5(val) {
  if (document.getElementById('registerSetPassword5').value.length == 0) {
    document.getElementById('registerSetPassword4').focus()
  }
}

function close() {
  bus.emit(bus.event.loginState, false);
}

</script>

<style lang="scss" scoped>
.loginRegisterPop {
  position: relative;
  :deep(.el-dialog) {
    position: relative;
    transition: max-width 0.6s;
    width: 450px;
    max-width: 100%;
    padding: 0;
    border: 2px solid #c9c9ff;
    border-radius: 10px;
    background: linear-gradient(180deg, #fff, #eef);

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;

      .modal-content {
        min-height: auto;
        .close {
          color: #603d77;
          background: #fff;
          right: -50px;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 0;
          z-index: 5;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 0;
          float: right;
          font-weight: 700;
          line-height: 1;
          .fa-times {
            font-size: 1.25rem;
          }
        }
        .x-modal-account-security-tabs {
          margin-bottom: 1rem;
          border-top-left-radius: 13px;
          border-top-right-radius: 13px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          overflow: hidden;
          .-btn {
            font-weight: 400;
            position: relative;
            color: #603d77;
            background: #ffe4e1;
            flex: 1;
            padding: 1rem;
            font-size: 1.125rem;
            font-weight: 500;
            border: none;
            outline: none;
            text-align: center;
            transition: background-color 0.2s;
            cursor: pointer;
          }
          .img-fluid {
            position: absolute;
            top: 4px;
            left: 50%;
            width: 60px;
            -webkit-transform: translateX(-14%);
            transform: translateX(-14%);
            max-width: 100%;
            height: auto;
          }
          .-active {
            color: #603d77;
            background: transparent;
          }
        }
        .modal-body {
          overflow: unset;
          font-size: 1rem;
          padding: 0 2rem 1.5rem;
          position: relative;
          flex: 1 1 auto;
          .x-form-control {
            height: 50px;
            padding-left: 20px;
            padding-right: 20px;
            color: #603d77;
            background: #fff;
            font-size: 1.2rem;
            text-align: center;
            border: 1px solid #65bce1;
            border-radius: 30px;
            transition: all 0.5s;
            width: 100%;
            box-shadow: none;
          }
          .x-form-control[disabled] {
            color: #aaa;
            background: #f3f3f3;
            cursor: not-allowed;
          }
          .x-register-tab-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            .text-center {
              text-align: center !important;
            }
            .-submit[disabled] {
              color: #fafafa;
              background: #bdbdbd;
              filter: brightness(0.75);
              cursor: not-allowed;
            }
            .-submit {
              margin-top: 1rem !important;
              background: linear-gradient(180deg, #8acaff, #007ee4);
              color: #fafafa;
              text-shadow: none;
              border-radius: 30px;
              width: 100%;
              padding: 0.8rem 2.5rem;
              text-align: center;
              vertical-align: middle;
              border: none;
              cursor: pointer;
              font-size: 1rem;
              line-height: 1.5;
            }
            .login-submit {
              margin-bottom: 1rem !important;
              color: #fafafa;
              background: linear-gradient(180deg, #91f27f, #00b302);
            }
            .x-reset-pw-text-container {
              text-align: center;
            }
            .-btn-reset-password {
              background: none;
              color: #3478dd;
              border: none;
              outline: none;
              cursor: pointer;
            }
            .x-bank-account-form {
              .-submit {
                color: #fafafa;
                background: linear-gradient(180deg, #91f27f, #00b302);
              }
            }
            .x-bank-name-form {
              .-label {
                width: 100% !important;
                .-logo {
                  width: 40px !important;
                  height: 40px !important;
                }
                .x-title-register-modal-v3 {
                  display: block !important;
                  text-align: center !important;
                  margin: 0 !important;
                  .-title {
                    margin-left: 1rem;
                    color: #603d77;
                    font-weight: 400;
                  }
                }
                .fas {
                  margin-left: 0.75rem;
                  color: #b995a4;
                }
              }
            }
            .tab-content {
              .tab-pane {
                display: none;
              }
              .active {
                display: block;
              }
              .row {
                display: flex;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;
                flex-direction: column;
              }
              .col {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
                position: relative;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                .x-title-register-modal-v3 {
                  display: flex;
                  flex-direction: column;
                  text-align: center;
                  margin: auto;
                  .-title {
                    font-size: 1.25rem;
                    font-weight: 400;
                    color: #603d77;
                  }
                  .-sub-title {
                    font-size: 0.875rem;
                    font-weight: 300;
                    color: #828282;
                    .-highlight {
                      font-weight: 500;
                      color: #603d77;
                    }
                  }
                }
              }
              .-fake-inner-body {
                .-x-input-icon {
                  justify-content: center;
                  margin-top: 1rem;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  border-radius: 3px;
                  position: relative;
                  text-align: center !important;
                  width: 100%;
                  .form-control {
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                    font-size: 1.5rem;
                    color: #603d77;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    border-radius: 0;
                    font-family: FC Iconic Text, Helvetica Neue, Helvetica,
                      Arial, sans-serif;
                    width: 100%;
                    position: relative;
                    flex: 1 1 auto;
                    margin-bottom: 0;
                    text-align: center;
                    transition: all 0.5s;
                    box-shadow: none;
                    height: calc(1.5em + 1.6rem + 2px);
                    padding: 0.8rem;
                    font-weight: 400;
                    line-height: 1.5;
                  }
                  .bank-form-control {
                    font-size: 1rem;
                  }
                }
                .x-text-with-link {
                  margin-top: 1rem;
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: #603d77;
                  text-align: center;
                  .-btn-link {
                    color: #3478dd;
                    text-decoration: underline;
                  }
                }
              }
              .x-modal-separator-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                .-top {
                  position: relative;
                  z-index: 2;
                  .-otp-body {
                    position: relative;
                    text-align: center;
                  }
                  .-digit-otp {
                    width: 75%;
                    color: #603d77;
                    border: none;
                    border-bottom: 1px solid #603d77;
                    background: transparent;
                    padding: 12px 0;
                    font-size: 1.25rem;
                    font-weight: 600;
                    text-align: center;
                    border-radius: 0;
                    height: 35px;
                  }
                  .-digit-otp:focus,
                  .-digit-otp:focus-visible {
                    outline: 1px auto #603d77;
                  }
                  .-otp-input-container {
                    margin: 1rem auto;
                    padding: 0.5rem 2rem 0.75rem;
                    background: #fff;
                    border-radius: 50px;
                    width: 100%;
                    justify-content: center;
                    display: flex !important;
                  }
                  .-password-input-container {
                    width: 100%;
                    margin: 1rem auto;
                    padding: 0.5rem 2rem 0.75rem;
                    background: #fff;
                    border-radius: 50px;
                    justify-content: center;
                    display: flex !important;
                  }
                  .-text-clear-otp {
                    color: #6c757d;
                    margin-top: 1rem !important;
                  }
                }
                .-submit {
                  color: #fafafa;
                  background: linear-gradient(180deg, #91f27f, #00b302);
                }
              }
              .card-bank-info {
                .-x-input-icon {
                  justify-content: center;
                  margin-top: 1rem;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  gap: 10px;
                  border-radius: 3px;
                  position: relative;
                  margin-bottom: 1rem !important;
                  flex-direction: column !important;
                  .-icon {
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    left: 18px;
                    color: #9e5045;
                    width: 23px;
                  }
                  .icon {
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);

                    img {
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
                .-text-description {
                  margin-bottom: 1rem;
                  color: #603d77;
                  font-size: 0.875rem;
                }
                .js-bank-select-container {
                  margin-bottom: 1rem !important;
                  margin-top: 1rem !important;
                  .-outer-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding-top: 10px;
                    background: transparent;
                    .-label {
                      width: 40px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin: 0 4px 10px;
                      position: relative;
                      border-radius: 50%;
                      opacity: 0.75;
                      transition: all 0.1s;
                      .-logo {
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        transition: all 0.1s;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  input::-webkit-input-placeholder {
    opacity: 0.5;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
  .small-input::-webkit-input-placeholder {
    font-size: 14px !important;
  }
}

@media (max-width: 1000px) {
  .close {
    top: -3rem !important;
    color: #603d77 !important;
    background: #fff !important;
    right: 0.75rem !important;
    width: 36px !important;
    height: 36px !important;
  }
}
</style>