<template>
  <div class="member">
    <!-- <div class="x-cover">
      <img src="@/assets/pc/home/bg-index-login.png" alt="">
    </div> -->
    <div class="container">
      <div class="wrapper-main">
        <div class="menu-left">
          <div class="accountdata">
            <div v-for="(item, index) in memberObj.list" class="mem_main_menu" @click="itemClick(item, index)">
              <div class="mem-heading">
                <a>
                  <h5 :class="item.notRouter ? 'not-after' :  (item.open ? 'h5-open' : '')">
                    <img :src="item.icon" alt="">
                    <span>{{ t(item.lable) }}</span>
                    <span v-if="item.isNotice && mailInfo.mailUnreadNumAll > 0" class="badge">{{
                                            mailInfo.mailUnreadNumAll }}</span>

                  </h5>
                </a>
              </div>
              <div class="mem-slide" :class="item.open ? 'open' : ''">
                <ul>
                  <li v-for="(item1, index1) in item.children.list" :class="item.children.active == index1 ? 'li-active' : ''" @click.stop="itemChildClick(item1, index1, item.children.active)">
                    <a>{{ t(item1.lable) }}
                      <span v-if="item.isNotice">
                        <span v-if="item1.badgeType == 'SystemNotice' && mailInfo.SystemNotice > 0" class="badge">{{ mailInfo.SystemNotice }}</span>
                        <span v-if="item1.badgeType == 'manualAward' && mailInfo.manualAward > 0" class="badge">{{ mailInfo.manualAward }}</span>
                        <span v-if="item1.badgeType == 'ActivityNotice' && mailInfo.ActivityNotice > 0" class="badge">{{ mailInfo.ActivityNotice }}</span>
                        <span v-if="item1.badgeType == 'SystemNoticeAuto' && mailInfo.SystemNoticeAuto > 0" class="badge">{{ mailInfo.SystemNoticeAuto }}</span>
                        <span v-if="item1.badgeType == 'SelfInfo' && mailInfo.SelfInfo > 0" class="badge">{{ mailInfo.SelfInfo }}</span>
                      </span>
                    </a>

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-right">
          <div class="member-content">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo, mailInfo } = useGetters("user", ["userInfo", "mailInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const { loginOut } = useMutations('user', ['loginOut'])
const { disConnect } = useActions('tcp', ['disConnect'])

const memberObj = reactive({
  active: -1,
  list: [
    {
      lable: 'Profile',
      icon: require('@/assets/pc/member/icon_profile.png'),
      open: false,
      children: {
        active: -1,
        list: [
          {
            lable: 'Personal Information',
            router: [
              '/member/profile/personal',
            ]
          },
          {
            lable: 'Bet History',
            router: [
              '/member/profile/betHistory',
            ]
          },
          // {
          //   lable: 'Password management',
          //   router: [
          //     '/member/profile/password/passwordLogin',
          //   ]
          // },
        ]
      }
    },
    {
      lable: 'Cashier',
      icon: require('@/assets/pc/member/icon_cashier.png'),
      open: false,
      children: {
        active: -1,
        list: [
          {
            lable: 'Deposit',
            router: [
              '/member/cashier/deposit/depositInfo',
              '/member/cashier/deposit/depositRecord'
            ]
          },
          {
            lable: 'Withdrawal',
            router: [
              '/member/cashier/withdrawal/withdrawalInfo',
              '/member/cashier/withdrawal/withdrawalRecord'
            ]
          },
        ]
      }
    },
    // {
    //   lable: 'Referrals',
    //   icon: require('@/assets/pc/member/icon_profile.png'),
    //   open: false,
    //   children: {
    //     active: -1,
    //     list: [
    //       {
    //         lable: 'Referral Rebate Bonus',
    //         router: [
    //           '/member/referrals/referralRebateBonus',
    //         ]
    //       },
    //     ]
    //   }
    // },
    {
      lable: 'VIP',
      icon: require('@/assets/pc/member/icon_vip.png'),
      open: false,
      children: {
        active: -1,
        list: [
          {
            lable: 'VIP',
            router: [
              '/member/vipRoot/vipInfo',
            ]
          },
          {
            lable: 'Welfare Collection',
            router: [
              '/member/vipRoot/vipWelfare',
            ]
          },
          {
            lable: 'Vip History',
            router: [
              '/member/vipRoot/vipHistory',
            ]
          },
        ]
      }
    },
    {
      lable: 'Promotion List',
      icon: require('@/assets/pc/member/icon_promo.png'),
      open: false,
      children: {
        active: -1,
        list: [
          {
            lable: 'Apply for Promotions',
            router: [
              '/member/promoList/promoApply',
            ]
          },
          {
            lable: 'Promotion List',
            router: [
              '/member/promoList/promoInfoList',
            ]
          },
          {
            lable: 'Bonus History',
            router: [
              '/member/promoList/promoHistory',
            ]
          },
        ]
      }
    },
    {
      lable: 'Access application',
      icon: require('@/assets/pc/member/icon_xz.png'),
      notRouter: true,
      flag: 'downFlag',
      open: false,
      children: {
        active: -1,
        list: []
      }
    },
    {
      lable: 'Notifications',
      icon: require('@/assets/pc/member/icon_notice.png'),
      open: false,
      isNotice: true,
      children: {
        active: -1,
        list: [
          {
            lable: 'Accounting',
            badgeType: 'SystemNoticeAuto',
            router: [
              '/member/mailBox/mailBoxAccount',
            ]
          },
          {
            lable: 'Latest News',
            badgeType: '',
            router: [
              '/member/mailBox/mailBoxNew',
            ]
          },
          {
            lable: "Bonus",
            badgeType: "manualAward",
            router: ["/member/mailBox/mailBoxBonus"],
          },
          {
            lable: 'System',
            badgeType: 'SystemNotice',
            router: [
              '/member/mailBox/mailBoxSystem',
            ]
          },
          {
            lable: 'Event',
            badgeType: 'ActivityNotice',
            router: [
              '/member/mailBox/mailBoxEvent',
            ]
          },
          {
            lable: 'Personal',
            badgeType: 'SelfInfo',
            router: [
              '/member/mailBox/mailBoxPersonal',
            ]
          },
        ]
      }
    },
    {
      lable: 'Log out',
      icon: require('@/assets/pc/member/ic-menu-logout.png'),
      notRouter: true,
      flag: 'logoutFlag',
      open: false,
      children: {
        active: -1,
        list: []
      }
    },
  ]
})
const referralObj = {
  lable: 'Referrals',
  icon: require('@/assets/pc/member/icon_profile.png'),
  open: false,
  children: {
    active: -1,
    list: [
      {
        lable: 'Referral Rebate Bonus',
        router: [
          '/member/referrals/referralRebateBonus',
        ]
      },
    ]
  }
}
getAllMemberAgentConf();
bus.on('onConnected', () => {
  getAllMemberAgentConf();
})
function getAllMemberAgentConf() {
  let parms = {
    ActivityId: 'AllMemberAsAgent'
  }
  mqant.request(topic.getAllMemberAgentConf, parms, function (data, topicName, msg) {
    log.info("topic.getAllMemberAgentConf", data);
    if (data.Code == 0) {
      let notShow = data.Data.conf.notShow;
      if (notShow !== 1) {
        memberObj.list.splice(2, 0, referralObj)
      }
    }
  });

}

//处理选中路由 
function setSelectIndex() {
  if (curRouter.path.includes('member')) {
    //清空
    for (let key in memberObj.list) {
      let info = memberObj.list[key];
      info.open = false;
      info.children.active = -1;
    }
    //选择
    for (let key in memberObj.list) {
      let info = memberObj.list[key];
      for (let key1 in info.children.list) {
        let info1 = info.children.list[key1];
        for (let key2 in info1.router) {
          let info2 = info1.router[key2];
          if (curRouter.path == info2) {
            info.open = true;
            info.children.active = Number(key1);
            break;
          }
        }
      }
    }
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
})

function itemClick(item, index) {
  if (item.notRouter && item.flag == 'downFlag') {
    router.push('/download');
    return;
  }
  if (item.notRouter && item.flag == 'logoutFlag') {
    toQuitlogin();
    return;
  }
  for (let key in memberObj.list) {
    let info = memberObj.list[key];
    if (Number(key) == index) {
      info.open = !item.open;
    }
  }
}

function toQuitlogin() {
  bus.emit(bus.event.commonTipPop, {
    bool: true,
    data: {
      title: t('Want to log out?'),
      confirmTitle: t('Log out'),
      cannel: true,
      func: quitLogin
    }
  })
}

function quitLogin() {
  loginOut();
  disConnect();
  ElMessage.success(t('Exit successful'));
  router.push('/');
}

function itemChildClick(item, index, active_index) {
  if (active_index == index) return;
  if (item.router &&
    item.router.length > 0) {
    router.push({
      path: item.router[0]
    })
  }
}

</script>

<style lang="scss" scoped>
.member {
  background-image: linear-gradient(180deg, #fff, #fff3f3),
    linear-gradient(180deg, #ff6b56, #ffafa4);
  .x-cover {
    img {
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }
  }

  .container {
    *zoom: 1;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding: 40px 0;
    font-size: 16px;

    .wrapper-main {
      display: flex;
      width: 100%;

      .menu-left {
        background-color: #fff;
        padding: 40px 0;
        font-size: 16px;
        width: 22%;
        min-width: 250px;

        .accountdata {
          padding: 50px 0;
          color: #472c59;

          .badge {
            display: inline-block;
            background: #ffc107;
            border-radius: 10px;
            margin-left: 10px;
            padding: 3px 5px;
            min-width: 20px;
            color: #000;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
          }

          .mem_main_menu {
            margin-bottom: 20px;

            .mem-heading {
              a {
              }

              h5 {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 20px 30px 20px 30px;
                position: relative;
                font-size: 18px;
                line-height: 1.5;
                cursor: pointer;

                i {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background: #fff;
                  margin-right: 10px;
                  color: #333;
                  line-height: 30px;
                  text-align: center;
                }

                img {
                  width: 30px;
                  height: 30px;
                  // border-radius: 50%;
                  // // background: #fff;
                  margin-right: 10px;
                  // color: #333;
                  line-height: 30px;
                  text-align: center;
                  vertical-align: middle;
                }
              }

              h5:after {
                content: "";
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                position: absolute;
                z-index: 1;
                right: 30px;
                top: 50%;
                color: #9e5045;
                font-family: glyphicon;
                font-size: 24px;
                font-weight: 600;
              }

              .h5-open::after {
                content: "";
                color: #9e5045;
              }
              .not-after::after {
                content: "";
              }
            }

            .mem-slide {
              opacity: 0;
              height: 0;

              ul {
                padding-left: 15px;
                margin: 0;
                padding: 0;
                list-style-type: none;
                *zoom: 1;

                li {
                  cursor: pointer;
                  border-bottom: 1px dotted hsla(0, 0%, 100%, 0.2);
                  position: relative;

                  a {
                    display: block;
                    padding: 15px 30px 15px 50px;
                    color: #472c59;
                    line-height: 1.5;
                    text-decoration: none;
                  }
                }

                li:hover {
                  background: #ffe4e1;
                  border-bottom-color: transparent;
                  border-top-left-radius: 50px;
                  border-bottom-left-radius: 50px;
                  padding-right: 0;

                  a {
                    font-weight: 600;
                  }
                }

                .li-active {
                  background: linear-gradient(
                    90deg,
                    #ffe4e1,
                    #ffe4e1
                  ) !important;
                  border-bottom-color: transparent;
                  border-top-left-radius: 50px;
                  border-bottom-left-radius: 50px;
                  padding-right: 0;
                  color: #000;

                  a {
                    color: #472c59;
                    font-weight: 600;
                  }
                }
              }
            }

            .open {
              opacity: 1;
              height: auto;
              transition: all 0.5s ease;
            }
          }
        }
      }

      .menu-right {
        background: linear-gradient(180deg, #fff8f9, #fff8f9);
        // background: #fff;
        width: 78%;

        .member-content {
          position: relative;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1366px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
</style>