<template>
  <div class="VgPay">
    <form id="pay-form" method="POST" :action="props.data.apiUrl" target="_blank">
      <input type="hidden" v-model="props.data.ClientId" name="clientId" />
      <input type="hidden" v-model="props.data.TransactionId" name="transactionId" />
      <input type="hidden" v-model="props.data.CustName" name="custName" />
      <input type="hidden" v-model="props.data.CustBank" name="custBank" />
      <input type="hidden" v-model="props.data.Amount" name="amount" />
      <input type="hidden" v-model="props.data.ReturnUrl" name="returnUrl" />
      <input type="hidden" v-model="props.data.CallbackUrl" name="callbackUrl" />
      <input type="hidden" v-model="props.data.BankAcc" name="bankAcc" />
      <input type="hidden" v-model="props.data.HashVal" name="hashVal" />
    </form>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";
import { bus } from "@/components/core/bus";
import { useI18n } from "vue-i18n";
import log from "@/utils/logger";
const { t, locale } = useI18n();
const props = defineProps(reactive({
  data: {
  },
}));

onMounted(() => {
  log.info('props.data', props.data)
  document.getElementById('pay-form').submit()
})
</script>

<style lang="scss" scoped>
.vgPay {
}
</style>