<template>
  <div class="passwordLogin">
    <div class="memberform-wrap">
      <div class="form-group">
        <div class="FinalForm">
          <div class="form-group">
            <h4><span>{{t('Original password')}}</span></h4>
            <div class="FinalField">
              <div class="form-wrap">
                <input :type="ruleForm.showOldPass ? 'text' : 'password'" v-model="ruleForm.oldPass" autocomplete="off" class="form-input form-password" @input="validateOldPassword()">
                <span class="icon">
                  <img v-if="ruleForm.showOldPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="ruleForm.showOldPass = !ruleForm.showOldPass">
                  <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="ruleForm.showOldPass = !ruleForm.showOldPass">
                </span>
              </div>
              <div ref="oldPassRef" v-show="oldPassShow" class="poperrorMsg"></div>
            </div>
          </div>
          <div class="form-group">
            <h4><span>{{t('New password')}}</span></h4>
            <div class="FinalField">
              <div class="form-wrap">
                <input v-model="ruleForm.newPass" :type="ruleForm.showNewPass ? 'text' : 'password'" autocomplete="off" class="form-input form-password" @input="validateNewPassword()">
                <span class="icon">
                  <img v-if="ruleForm.showNewPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="ruleForm.showNewPass = !ruleForm.showNewPass">
                  <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="ruleForm.showNewPass = !ruleForm.showNewPass">
                </span>
              </div>
              <div ref="newPassRef" v-show="newPassShow" class="poperrorMsg"></div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <h4><span>{{t('Confirm new password')}}</span></h4>
          <div class="FinalField">
            <div class="form-wrap">
              <input v-model="ruleForm.checkPassword" :type="ruleForm.showCheckPass ? 'text' : 'password'" autocomplete="off" class="form-input form-password" @input="validateCheckPassword()">
              <span class="icon">
                <img v-if="ruleForm.showCheckPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="ruleForm.showCheckPass = !ruleForm.showCheckPass">
                <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="ruleForm.showCheckPass = !ruleForm.showCheckPass">
              </span>
            </div>
            <div ref="checkPassRef" v-show="checkPassShow" class="poperrorMsg"></div>
          </div>
        </div>
      </div>
      <div class="member-note">{{t('Recommended to use at least 8 characters including numbers and letters.')}}</div>
      <div class="submitbtn-wrap">
        <button @click="submitForm()">{{t('Change Password')}}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet, basicInfo } = useGetters("tcp", ["isLogged", "wallet", "basicInfo"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const [oldPassRef, newPassRef, checkPassRef] = [ref(), ref(), ref()];
const [oldPassShow, newPassShow, checkPassShow] = [ref(false), ref(false), ref(false)];
const ruleForm = reactive({
  oldPass: '',
  showOldPass: false,
  newPass: '',
  showNewPass: false,
  checkPassword: '',
  showCheckPass: false,
})

function validateOldPassword() {
  let value = ruleForm.oldPass;
  if (value === '') {
    oldPassShow.value = true;
    oldPassRef.value.innerText = t('Password is required.');
  } else {
    oldPassShow.value = false;
    oldPassRef.value.innerText = '';
  }
}

function validateNewPassword() {
  let value = ruleForm.newPass;
  if (value === '') {
    newPassShow.value = true;
    newPassRef.value.innerText = t('Password is required.');
  } else {
    newPassShow.value = false;
    newPassRef.value.innerText = '';
  }
}
function validateCheckPassword() {
  let value = ruleForm.checkPassword;
  if (value === '') {
    checkPassShow.value = true;
    checkPassRef.value.innerText = t('Password is required.');
  } else if (ruleForm.newPass !== ruleForm.checkPassword) {
    checkPassShow.value = true;
    checkPassRef.value.innerText = t('Confirmed password does not match.');
  } else {
    checkPassShow.value = false;
    checkPassRef.value.innerText = '';
  }
}

function submitForm() {
  validateOldPassword();
  validateNewPassword();
  validateCheckPassword();
  if (oldPassShow.value || newPassShow.value || checkPassShow.value) {
    return;
  } else {
    let params = {
      OldPwd: ruleForm.oldPass,
      NewPwd: ruleForm.newPass,
      ConfirmPwd: ruleForm.checkPassword,
    };
    log.info('params', params)
    mqant.request(topic.changePW, params, function (data, topicName, msg) {
      if (data.Code == 0) {
        basicInfo.value.Password = ruleForm.newPass
        ElMessage.success(t('Change successful'));
        ruleForm.oldPass = '';
        ruleForm.newPass = '';
        ruleForm.checkPassword = '';
      } else {
        ElMessage.error(data.ErrMsg);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.passwordLogin {
  position: relative;

  .memberform-wrap {
    padding: 0 15% 100px;

    .form-group {
      position: relative;
      margin-bottom: 30px;
      *zoom: 1;

      h4 {
        margin: 0;
        color: #0f161f;
        font-size: 18px;
        line-height: 30px;
        font-family: inherit;
        font-weight: 500;

        span {
          position: relative;
        }

        span:after {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #c00;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          position: absolute;
          top: 50%;
          z-index: 1;
          content: "";
          display: inline-block;
          right: -15px;
        }
      }

      .FinalForm {
      }
    }

    .form-wrap {
      position: relative;

      .icon {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
        }
      }
    }

    .poperrorMsg {
      position: absolute;
      z-index: 20;
      bottom: 50px;
      right: 0;
      width: auto;
      background: #c00;
      color: #fff;
      line-height: 20px;
      padding: 5px 10px;
      border-radius: 3px;
      -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
    }

    .poperrorMsg:before {
      content: "";
      font-family: glyphicon;
      font-weight: 600;
      margin-right: 5px;
      top: 1px;
      position: relative;
    }

    .poperrorMsg:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin: auto;
      right: 10px;
      bottom: -6px;
      display: block;
      border-color: #c00 transparent transparent;
      border-style: solid;
      border-width: 7px 7px 0;
    }

    .form-input {
      display: block;
      width: 100%;
      height: 50px;
      padding: 10px;
      font-size: 16px;
      line-height: 30px;
      color: #616161;
      background: #fff;
      border: none;
      border-bottom: 1px solid #dbe0e4;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: none;
      transition: none;
      border-radius: 0;
    }

    .form-input:focus {
      color: #000;
      border-color: #f7f7f7;
      background-color: #f7f7f7;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .form-password {
      padding-right: 34px !important;
    }

    .member-note {
      color: #8e8ec0;
      margin-bottom: 30px;
    }

    .member-note:before {
      content: "";
      margin-right: 10px;
      top: 2px;
      position: relative;
      font-family: glyphicon;
    }

    .submitbtn-wrap {
      text-align: center;

      button {
        background-image: linear-gradient(0deg, #ff8a00 4%, #ff8a00 100%),
          linear-gradient(#9b9b9b, #9b9b9b);
        background-repeat: repeat-x;
        transition: all 0.5s ease;
        cursor: pointer;
        display: inline-block;
        width: auto;
        height: auto;
        padding: 10px 40px;
        font-size: 18px;
        color: #fff;
        line-height: 30px;
        border: none;
        border-radius: 50px;
        margin: 0 auto;
      }

      button:hover {
        // background-image: linear-gradient(180deg, #1a9090 0, #2bb);
        background-repeat: repeat-x;
      }
    }
  }
}
</style>