<template>
  <div class="mailBoxTitle">
    <div class="memberform-fuild">
      <div class="bulk-action-button-group">
        <button :class="getDeleteState() ? 'disabled' : ''" class="left-btn" @click="deleteAllRead()">{{t('Delete READ')}}</button>
        <button :class="getMarkState() ? 'disabled' : ''" class="right-btn" @click="markAllRead()">{{t('Mark All as READ')}}</button>
      </div>
      <div v-if="!recordObj.loading" class="message_promotion">
        <div class="listrecord">
          <el-row class="hidden-xs-only list-th">
            <el-col :span="16">
              {{t('Topic')}}
            </el-col>
            <el-col :span="8">
              {{t('Time Sent')}}
            </el-col>
          </el-row>
          <div v-if="recordObj.allList.length <= 0" class="norecord">{{t('No Record')}}</div>
          <div v-else class="even-list">
            <el-row v-for="(item, index) in recordObj.list" class="list-td" @click="toRead(item)">
              <el-col :span="16">
                <div class="el-col-div" :class="item.ReadState == 'unread' ? 'unread' : 'isread'">
                  <i class="fa fa-envelope-open"></i>
                  <span>{{ tools.getCurLangValue(item.Title) }}</span>
                </div>
              </el-col>

              <el-col :span="8">
                <div class="el-col-div text-center" :class="item.ReadState == 'unread' ? 'unread' : 'isread'"> {{
                                        tools.formatDate(item.SendTime, "yyyy-MM-dd") }}
                  {{ tools.formatDate(item.SendTime, "hh:mm") }}</div>
              </el-col>

            </el-row>
          </div>
        </div>
        <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
          <el-pagination background layout="prev, pager, next" :pager-count="5" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const curType = 'SelfInfo';
const recordObj = reactive({
  allList: [],
  list: [],
  mailUnreadNum: 0,
  loading: false,
})

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 5,
  total: 0,
})

if (isLogged.value) {
  getRecord()
}

bus.on(bus.event.systemMailNtf, () => {
  getRecord()
})

function getRecord() {
  let parms = {
    MailType: curType
  }
  mqant.request(topic.mailList, parms, function (data, topicName, msg) {
    log.info('mailList--', data.Data)
    if (data.Code == 0) {
      recordObj.allList = data.Data.mail;
      recordObj.mailUnreadNum = data.Data.mailUnreadNum;
      //记录总数
      paginationObj.total = recordObj.allList.length;
      //分页截取
      sliceArr();
      recordObj.loading = false;
    } else {
    }
  });
}

function getMarkState() {
  if (recordObj.allList.length <= 0) {
    return true;
  }
  if (recordObj.mailUnreadNum == 0) {
    return true;
  }
  return false;
}

function getDeleteState() {
  if (recordObj.allList.length <= 0) {
    return true;
  }
  if (recordObj.mailUnreadNum == recordObj.allList.length) {
    return true;
  }
  return false;
}

function markAllRead() {
  if (getMarkState()) return;
  ElMessageBox.confirm(
    t('Are you sure you want to mark all as READ?'),
    t('Mark All as READ'),
    {
      confirmButtonText: t('Confirm'),
      cancelButtonText: t('Cancel'),
      type: 'warning',
    }
  )
    .then(() => {
      let parms = {
        MailType: curType,
      }
      log.info('parms--', parms)
      mqant.request(topic.updateMail, parms, function (data, topicName, msg) {
        log.info('updateMail--', data.Data)
        if (data.Code == 0) {
          getRecord();
        } else {
        }
      });
    })
    .catch(() => {

    })
}
function deleteAllRead() {
  if (getDeleteState()) return;
  ElMessageBox.confirm(
    'Are you sure you want to delete all READ?',
    'Delete READ',
    {
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  )
    .then(() => {
      let parms = {
        MailType: curType,
      }
      log.info('parms--', parms)
      mqant.request(topic.deleteMail, parms, function (data, topicName, msg) {
        log.info('deleteMail--', data.Data)
        if (data.Code == 0) {
          getRecord();
        } else {
        }
      });
    })
    .catch(() => {

    })


}
const handleSizeChange = (val) => {
  sliceArr();
}
const handleCurrentChange = (val) => {
  sliceArr();
}
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}

function toRead(item) {
  item.MailType = curType;
  if (item.ReadState == "unread") {
    let parms = {
      MailType: curType,
      Oid: item.Oid,
    }
    log.info('parms--', parms)
    mqant.request(topic.updateMail, parms, function (data, topicName, msg) {
      log.info('updateMail--', data.Data)
      if (data.Code == 0) {
        setReadStateInList(item);
        bus.emit(bus.event.systemMailPop, { bool: true, data: item });
      } else {
      }
    });
  } else {
    bus.emit(bus.event.systemMailPop, { bool: true, data: item });
  }
}

function setReadStateInList(item) {
  for (let key in recordObj.allList) {
    if (item.Oid == recordObj.allList[key].Oid) {
      recordObj.allList[key].ReadState = 'read';
      break;
    }
  }
  for (let key in recordObj.list) {
    if (item.Oid == recordObj.list[key].Oid) {
      recordObj.allList[key].ReadState = 'read';
      break;
    }
  }
  recordObj.mailUnreadNum = recordObj.mailUnreadNum - 1
}
</script>

<style lang="scss" scoped>
.mailBoxTitle {
  position: relative;
  color: #333;

  .memberform-fuild {
    border-radius: 10px;
    padding: 20px;
    background: #fff;

    .bulk-action-button-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 20px;

      .left-btn,
      .right-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 5px 20px;
        min-width: 120px;
        width: 50%;
        min-height: 40px;
        font-size: 14px;
        text-align: center;
        border: none;
        border-radius: 5px;
      }

      .disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
      }

      .left-btn {
        margin-right: 15px;
        color: #353535;
        border: 1px solid #ff8a00 !important;
      }

      .right-btn {
        color: #fff;
        background: #ff8a00;
      }
    }

    .message_promotion {
      .listrecord {
        margin-bottom: 20px;

        .norecord {
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          background: #fff;
          border: 1px solid #dbe0e4;
          border-top: 0;
          padding: 50px 10px;
          text-align: center;
        }

        .list-th {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          background: #f7f7f7;
          border: 1px solid #dbe0e4;

          > .el-col {
            border-right: 1px solid #dbe0e4;
            font-weight: 600;
          }
        }

        .list-td {
          background: #fff;
          border: 1px solid #dbe0e4;
          border-top: 0;

          > .el-col {
            border-right: 1px solid #dbe0e4;
            padding: 0;
          }
        }

        .list-td:hover {
          background: #167c7c;

          .el-col-div {
            color: #fff !important;
          }
        }

        .el-row {
          .el-col {
            display: table-cell;
            float: none;
            vertical-align: middle;
            padding: 10px;
            text-align: center;
            position: relative;

            .el-col-div {
              cursor: pointer;
              text-align: left;
              position: relative;
              top: 50%;
              transform: translateY(-50%);

              i {
                cursor: pointer;
                margin-right: 5px;
              }
            }

            .text-center {
              text-align: center !important;
            }

            .unread {
              color: #555;
            }

            .isread {
              color: #999;
            }
          }
        }
      }

      .pagination-wrapper {
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
        display: flex;
        justify-content: center;

        :deep(.el-pagination) {
          .is-active {
            background-color: #6c5f4e !important;
          }
        }
      }
    }
  }
}
</style>