<template>
  <Splash v-if="splashState" />
  <router-view v-if="!splashState" />
  <Pop v-if="!splashState" />
  <Loading v-if="loadState" />
  <!-- <Tawk v-if="!splashState && openLiveChat" /> -->
</template>

<script setup>
import bus from '@/utils/bus'
import { useGetters, useActions, useMutations } from '@/store/hooks/storeState/index'
import { ref, nextTick } from 'vue';
import { onMounted } from "vue";
import log from "./utils/logger";
import { useRouter, useRoute } from "vue-router";
import Splash from "@/views/common/Splash.vue";
import Loading from "@/views/common/Loading.vue";
import Tawk from "@/views/common/Tawk.vue";
import comApi from "@/utils/comApi";
import tools from "@/utils/tools";
import Pop from "@/views/common/components/pop/Pop.vue";
import { useI18n } from 'vue-i18n';


const { isLogged } = useGetters("tcp", ["isLogged"]);
const { getLobbyInfo } = useActions('global', ['getLobbyInfo'])
const { init } = useActions("global", ["init"]);
const { connect } = useActions("tcp", ["connect"]);
const { autoLogin } = useActions("user", ["autoLogin"]);
const { resetGame } = useActions("global", ["resetGame"]);
const { setRegisterParams, setShareParams } = useMutations("global", ["setRegisterParams", "setShareParams"]);
const router = useRouter();
const curRouter = useRoute();
const { t, locale } = useI18n();


const hasLoad = ref(false);//是否拿到了基础配置
const splashState = ref(true);//默认开启启动加载页
const loadState = ref(false);//通用的加载
const openLiveChat = ref(false);

//外部注册参数保存
let urlParams = tools.getQueryParam('params')
setRegisterParams(urlParams ? urlParams : '')
//外部分享参数保存
let shareParams = tools.getQueryParam('share')
setShareParams(shareParams ? shareParams : '')
//是否跳转注册页
let isJumpRegister = tools.getQueryParam('signUp') ? true : false;

/**启动 */
start();
/**监听 */
bus.on(bus.event.showLoading, (bool) => {
  loadState.value = bool
})
bus.on(bus.event.openLiveChat, (bool) => {
  openLiveChat.value = true;
})
/**函数 */
function start() {
  getLobbyInfo().then((lobbyInfo) => {
    hasLoad.value = true;
    //设置默认的语言
    setI18nLocale(lobbyInfo);
    //处理一下隐藏的路由
    setRouterStatus();
    init().then(() => {
      if (!isLogged.value) {
        autoLogin().then(() => {
          splashState.value = false;
        }, (err) => {
          splashState.value = false;
          if (isJumpRegister) {
            nextTick(() => {
              bus.emit(bus.event.resgisterPop, true);
            })
          }
        });
      }
    })
  });
}

function setI18nLocale(lobbyInfo) {
  let langList = [];
  for (let key in lobbyInfo.MainCustomContent) {
    let info = lobbyInfo.MainCustomContent[key];
    if (info.type == 'language' && info.status) {
      langList.push(lobbyInfo.MainCustomContent[key]);
    }
  }
  langList.sort((a, b) => {
    return a.sort - b.sort;
  })
  let finaleLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE;
  let localeLang = localStorage.getItem("localMyLanguage");
  if (!localeLang) {
    if (langList.length > 0) {
      let isHave = false;
      for (let key in langList) {
        if (langList[key].shortName == finaleLocale) {
          isHave = true;
        }
      }
      if (!isHave) {
        finaleLocale = langList[0].shortName;
      }
    }
  } else {
    if (langList.length > 0) {
      let isHave = false;
      for (let key in langList) {
        if (langList[key].shortName == localeLang) {
          finaleLocale = langList[key].shortName;
          isHave = true;
        }
      }
      if (!isHave) {
        finaleLocale = langList[0].shortName;
      }
    }
  }
  locale.value = finaleLocale;
  log.info('langList', langList);
  log.info('finaleLocale', finaleLocale);
}

function setRouterStatus() {
  //路由在导航栏中但不在数据中时需要处理
  if (comApi.isRouterInNav(curRouter.path)) {
    let isInNav = false;
    let navRouterList = comApi.getRouterList();
    for (let key in navRouterList) {
      let info = navRouterList[key];
      if (curRouter.path == info.router) {
        isInNav = true;
        break;
      }
    }
    if (!isInNav) {
      router.push('/')
    }
  }
}

</script>
<style lang="scss">
html,
body {
  margin: 0;
  font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  text-align: left;
  background-color: #fff;
  color: #fafafa;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
