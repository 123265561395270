<template>
  <div class="slot">
    <div class="top top-provider-img">
      <img loading="lazy" :src="comApi.getPromoImg('Slot')" class="provider-banner" alt="">
      <!--  <div v-if="false" class="top-provider-content ">
        <p class="provider-type">{{t('老虎机')}}</p>
        <div class="provider-desc">
          <p>{{t('游戏是我们的命运')}}</p>
          <p>{{t('今天就注册以享受无尽的奖励')}}</p>
        </div>
      </div> -->
    </div>
    <div class="mid com-container">
      <ul class="provider-box">
        <li v-for="(item,index) in navObj.list" class="tab-header" :class="index == navObj.index ? 'active':''" @click="itemClick(item,index)">
          <div class="tab-inner">
            <img :src="comApi.getFactoryIcon(item.factoryInfo.FactoryName)" alt="">
            <span>{{item.factoryInfo.Name}}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="bottom com-container">
      <div class="filter-container">
        <div class="filter-list">
          <div class="filter-header active">
            <span class="text">{{t('全部游戏')}}</span>
          </div>
        </div>
        <div class="sort-container">
          <div class="search-web">
            <div class="icon-sm">
              <img src="@/assets/pc/common/svg-search.svg" alt="">
            </div>
            <input v-model="searchObj.searchKey" type="seach" class="search-input" :placeholder="t('搜索')" v-on:input="searchFun">
          </div>
        </div>
      </div>
      <div v-if="!searchObj.isSearch" class="content-container">
        <div v-for="(item,index) in navObj.showList" class="item-col animated fadeInUp" @click="comApi.toFactory(item)">
          <div class="game-box">
            <div class="game-img-wrapper">
              <div class="game-img">
                <img :src="comApi.getFactoryGameIcon(item,3)" alt="" lazy="loaded">
              </div>
              <div class="btn-container">
                <button aria-label="launch-game" class="game-play-btn">{{t('开始')}}</button>
              </div>
            </div>
            <div class="game-details">
              <div class="row-1">
                <p class="game-name">{{ item.GameNameEn }}</p>
                <div class="icon-container">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="content-container">
        <div v-for="(item,index) in searchObj.list" class="item-col animated fadeInUp" @click="comApi.toFactory(item)">
          <div class="game-box">
            <div class="game-img-wrapper">
              <div class="game-img">
                <img :src="comApi.getFactoryGameIcon(item,3)" alt="" lazy="loaded">
              </div>
              <div class="btn-container">
                <button aria-label="launch-game" class="game-play-btn">{{t('开始')}}</button>
              </div>
            </div>
            <div class="game-details">
              <div class="row-1">
                <p class="game-name">{{ item.GameNameEn }}</p>
                <div class="icon-container">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import DiceLoad from "@/views/common/DiceLoad.vue";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import { watchEffect } from "vue";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

let navList = comApi.getCurNavListByType('Slot');
let navObj = reactive({
  index: 0,
  showList: [],
  list: navList,
})

let searchObj = reactive({
  searchKey: '',
  isSearch: false,
  list: []
});


if (curRouter.query && curRouter.query.fc) {
  for (let key in navObj.list) {
    let info = navObj.list[key];
    if (info.factoryInfo.FactoryName == curRouter.query.fc) {
      navObj.index = Number(key);
      break;
    }
  }
}
navObj.showList = navObj.list[navObj.index].gameList;

function itemClick(item, index) {
  if (index == navObj.index) return;
  navObj.index = index;
  navObj.showList = [];
  nextTick(() => {
    navObj.showList = navObj.list[navObj.index].gameList;
  })
}

function searchFun(key) {
  if (searchObj.searchKey.length == 0) {
    searchObj.isSearch = false;
    return;
  }
  searchObj.list = [];
  navObj.showList.forEach(item => {
    if (item.GameNameEn) {
      if (item.GameNameEn.toLowerCase().indexOf(searchObj.searchKey.toLowerCase()) >= 0) {
        searchObj.list.push(item);
      }
    }
  });
  searchObj.isSearch = true;
}

function setSelectIndex() {
  let index = -1;
  if (curRouter.query && curRouter.query.fc) {
    for (let key in navObj.list) {
      let info = navObj.list[key];
      if (info.factoryInfo.FactoryName == curRouter.query.fc) {
        index = Number(key);
        break;
      }
    }
  }
  if (index != -1) {
    itemClick(null, index);
  }

}

watch(() => {
  curRouter.path
}, (newValue, oldValue) => {
  setSelectIndex();
}, { deep: true })
</script>

<style lang="scss" scoped>
.slot {
  .top {
    position: relative;
    margin: auto;
    width: 100%;
    height: 0;
    padding-bottom: 25%;
    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .top-provider-content {
      position: absolute;
      bottom: 15%;
      left: 15%;
      color: #000;
      width: 100%;
      word-break: normal;
      overflow-wrap: break-word;
      max-width: 1340px;
      .provider-type {
        font-weight: 600;
        font-size: 55px;
        margin: 0 0 5px;
      }
      .provider-desc {
        width: 27%;
        p:first-child {
          font-size: 30px;
          margin: 0 0 10px;
        }
        p:last-child {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
  .mid {
    background: #f3f5f6;
    border-radius: 10px;
    margin: 50px auto;
    padding: 22px 52px;
    .provider-box {
      display: grid;
      flex-wrap: wrap;
      grid-gap: 10px;
      grid-template-columns: repeat(7, 0.7fr);
      .tab-header {
        background: #fff;
        border: 1px solid transparent;
        border-radius: 50px;
        padding: 5px 0;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .float-dot {
          border-radius: 4px;
          font-size: 8px;
          padding: 3px 8px;
          position: absolute;
          right: -4px;
          text-align: center;
          top: -5px;
          line-height: normal;
          border-radius: 10px;
          font-family: helvetica neue, helvetica-regular;
        }
        .new-provider {
          background: #e0092c;
          color: #fff;
        }
        .tab-inner {
          align-items: center;
          display: flex;
          justify-content: center;
          opacity: 0.5;
          img {
            filter: grayscale(100%);
            margin: 0 5px 0 0;
            height: 20px;
            width: auto;
            object-fit: contain;
          }
          span {
            color: #8697a2;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
      .tab-header:hover,
      .active {
        border: 1px solid #ff8a00;
        .tab-inner {
          opacity: 1;
          img {
            filter: grayscale(0%);
          }
          span {
            color: #0f161f;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    flex-flow: column;
    margin: 26px auto 0;
    .filter-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #8697a2;
      font-size: 14px;
      margin: 0 0 26px;
      padding: 0 0 0 52px;
      .filter-list {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        .filter-header {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          padding: 0 25px;
          border-radius: 20px;
          margin: 0 20px 0 0;
          cursor: pointer;
          span {
            text-transform: lowercase;
          }
        }
        .active {
          background: #ff8a00;
          color: #fff;
        }
      }
      .sort-container {
        display: flex;
        align-items: center;
        height: 35px;
        .search-web {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #fff;
          border: 1px solid #e8e8e8;
          border-radius: 20px;
          margin: 0 0 0 15px;
          .icon-sm {
            position: relative;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            width: 20px;
            height: 20px;
            margin: 0 5px 0 10px;
            transition: 0.3s;
            img {
              overflow: hidden;
              width: 100%;
              height: 100%;
            }
          }
          input {
            background: transparent;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 5px;
            border: none;
            color: #000;
          }
        }
      }
    }
    .content-container {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 45px 10px;
      margin: 20px 0 0;
      min-height: 300px;
      .item-col {
        .game-box {
          position: relative;
          .game-img-wrapper {
            width: 100%;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            .game-img {
              width: 100%;
              padding-bottom: 145%;
              height: 0;
              overflow: hidden;
              position: relative;
              background: rgba(0, 0, 0, 0.1019607843);
              img {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
              }
            }

            // img {
            //   width: 100%;
            //   object-fit: contain;
            //   border-radius: 11px;
            // }
            .btn-container {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              position: absolute;
              transition: 0.3s;
              transform: translateY(0%);
              opacity: 0;
              visibility: hidden;
              background: rgba(255, 255, 255, 0.8);
              .game-play-btn {
                background: #ff8a00;
                width: 105px;
                height: 30px;
                color: #fff;
                border-radius: 20px;
                font-size: 14px;
              }
              button {
                border: none;
                cursor: pointer;
              }
            }
          }
          .game-img-wrapper:hover {
            .btn-container {
              transform: translateY(-100%);
              visibility: visible;
              opacity: 1;
            }
          }
          .game-details {
            flex-direction: column;
            align-items: flex-start;
            width: 85%;
            margin: 15px auto 0;
            .row-1 {
              display: flex;
              justify-content: space-between;
              width: 100%;
              .game-name {
                font-size: 14px;
                color: #0f161f;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}
</style>