<template>
  <div class="iosDownPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="sign-wrapper">
        <button type="button" class="dark-close" @click="close()"></button>
      </div>
      <div class="header-popup header-popup-border">
        <div class="header-popup-bg">
          <div class="equal-block">
            <h1>{{t('IOS')}}</h1>
            <div class="ios-pwa-container">
              <ul class="step-content">
                <li>
                  <h3><span>{{t('step')}}</span><b>1</b>
                  </h3>
                  <p>{{t('Launch Safari and open this website')}}</p>
                </li>
                <li class="ios-step2">
                  <h3><span>{{t('step')}}</span><b>2</b>
                  </h3>
                  <p>{{t('Press the share button')}}</p>
                  <div class="step-img">
                    <img src="@/assets/pc/header/ios_step2.jpg" lazy="loaded">
                  </div>
                </li>
                <li class="ios-step3">
                  <h3><span>{{t('step')}}</span><b>3</b>
                  </h3>
                  <p>{{t("Select 'Add to Home Screen'")}}</p>
                  <div class="step-img">
                    <span class="step3-content">{{t('Add to Home Screen')}}</span>
                    <img src="@/assets/pc/header/ios_step3.jpg" lazy="loaded">
                  </div>
                </li>
                <li class="ios-step4">
                  <h3><span>{{t('step')}}</span><b>4</b>
                  </h3>
                  <p>{{t("Press the 'Add' button")}}</p>
                  <div class="step-img">
                    <div class="step4-content">
                      <span class="span-1">{{t('Cancel')}}</span>
                      <span class="span-2">{{t('Add to Home Screen')}}</span>
                      <span class="span-3">{{t('Add')}}</span>
                    </div>
                    <img src="@/assets/pc/header/ios_step4.jpg" lazy="loaded">
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {}
})
function close() {
  bus.emit(bus.event.iosDownPop, false);
}
</script>

<style lang="scss" scoped>
.iosDownPop {
  position: relative;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    border-radius: 2px;
    position: relative;
    margin: 10px auto 50px !important;
    width: 100%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #606266;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .sign-wrapper {
        .dark-close {
          position: absolute;
          top: 30px;
          right: 40px;
          z-index: 10;
          padding: 0;
          border: 0;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          background: url("@/assets/pc/common/dark-close-white.png");
        }
      }

      .header-popup-border {
        border-radius: 10px;
        background: url("@/assets/pc/header/ios_down_bg.png") 50%;
        padding: 5px;
        position: relative;

        .header-popup-bg {
          border-radius: 10px;
          position: relative;
        }

        .header-popup-bg:before {
          opacity: 0.5;
          content: "";
          display: block;
          border-radius: 10px;
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0, #3273c6),
            to(#8b3bce)
          );
          background: linear-gradient(180deg, #3273c6 0, #8b3bce);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .equal-block {
        color: #fff;
        padding: 60px 10% 30px;
        position: relative;
        z-index: 1;
        padding-top: 50px;

        h1 {
          text-align: center;
          margin-bottom: 40px;
          font-size: 36px;
          color: #fff;
          margin-top: 20px;
          font-weight: 500;
        }

        .ios-pwa-container {
          .step-content {
            background: rgba(0, 0, 0, 0.4);
            padding: 20px;
            margin: 10px auto 0;
            border-radius: 10px;
            list-style-type: none;

            li {
              margin-bottom: 30px;
              color: #ccc;

              h3 {
                background: hsla(0, 0%, 100%, 0.2);
                padding: 3px 10px;
                margin: 0 10px 5px 0;
                font-size: 16px;
                border-radius: 3px;
                font-style: italic;
                float: left;
                color: #fff;
                font-weight: 500;

                span {
                  vertical-align: middle;
                }

                b {
                  font-size: 20px;
                  vertical-align: middle;
                  margin-left: 5px;
                  color: #fff;
                  font-weight: 700;
                }
              }

              p {
                padding: 5px 0 0;
                font-size: 14px;
                text-align: left;
                color: #fff;
                word-break: break-word;
              }

              .step-img {
                width: 100%;
                height: 100%;
                position: relative;
                .step3-content {
                  color: #000;
                  position: absolute;
                  font-size: 12px;
                  left: 30px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                .step4-content {
                  width: 100%;
                  padding: 0 10px;
                  color: #000;
                  position: absolute;
                  font-size: 12px;
                  left: 50%;
                  top: 60%;
                  transform: translate(-50%, -50%);
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .span-1,
                  .span-3 {
                    color: #3273c6;
                  }
                }
                img {
                  width: 100%;
                  border-radius: 10px;
                  vertical-align: middle;
                }
              }

              .step-img:before {
                animation: tap-data 5s ease-in-out infinite;
              }

              .step-img:before,
              .step-img::after {
                content: "";
                position: absolute;
                z-index: 1;
                width: 10%;
                height: 70%;
                background: rgba(0, 0, 0, 0.1);
                border-radius: 50%;
              }

              @keyframes tap-data {
                0% {
                  -webkit-transform: scale(1.2);
                  transform: scale(1.2);
                }

                25% {
                  -webkit-transform: scale(2);
                  transform: scale(2);
                }

                50% {
                  -webkit-transform: scale(1.2);
                  transform: scale(1.2);
                }

                75% {
                  -webkit-transform: scale(2);
                  transform: scale(2);
                }

                100% {
                  -webkit-transform: scale(1.2);
                  transform: scale(1.2);
                }
              }
            }

            .ios-step2 {
              .step-img {
                width: 100%;
                height: 100%;
                position: relative;
              }

              .step-img:before,
              .step-img::after {
                // top: 13%;
                left: 44.6%;
              }
            }

            .ios-step3 {
              .step-img:before,
              .step-img::after {
                top: 18%;
                right: 6.3%;
              }
            }

            .ios-step4 {
              .step-img:before,
              .step-img::after {
                // top: 14%;
                right: 4%;
              }
            }
          }
        }
      }
    }
  }
}
</style>