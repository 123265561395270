<template>
  <div class="vipDetail">
    <div class="x-cover">
      <img src="@/assets/pc/home/bg-index-login.png" alt="">
    </div>
    <div class="container-fluid">
      <div class="article-detail-page">
        <a class="back-btn nuxt-link-active" @click="toVip()"></a>
        <div class="wrapperBg">
          <div class="content">
            <h1 class="contentTitle">{{ tools.getCurLangValue(curObj.title) }}</h1>
            <div v-html="tools.getCurLangValue(curObj.content)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch
} from "vue";

import {
  useGetters,
  useActions,
  useMutations
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const curTag = "artical";
let curObj = ref({
  title: '',
  content: ''
});
//处理选中路由 
function setSelectIndex() {
  if (curRouter.path.includes('vipDetail')) {
    if (!curRouter.query || curRouter.query.id == undefined) {
      toVip();
    } else {
      getCurObjById(curRouter.query.id);
    }
  }
}

function getCurObjById(id) {
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key];
    if (info.type == curTag && info._id == id) {
      curObj.value = info;
      break;
    }
  }
}

function toVip() {
  router.push('/vip');
}
watch(() => {
  curRouter.path, setSelectIndex();
})


</script>

<style lang="scss" scoped>
.vipDetail {
  position: relative;

  .x-cover {
    img {
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }
  }

  .container-fluid {
    *zoom: 1;
    padding-right: 80px;
    padding-left: 80px;

    .article-detail-page {
      .back-btn {
        width: 30px;
        height: 30px;
        display: block;
        background: url("@/assets/pc/common/back-hall.png");
        z-index: 3;
        line-height: 30px;
        color: transparent;
        margin: 10px 0;
      }

      .wrapperBg {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        padding: 30px 20px;
        color: #0f161f;
        font: 15px bold;

        .content {
          .contentTitle {
            display: block;
            margin-bottom: 20px;
            color: #000;
            font-weight: bolder;
            font-size: 32px;
            text-align: center;
            margin-top: 20px;
          }
          :deep(.blockContent) {
            position: relative;

            img {
              position: relative;
              max-width: 100%;
              max-height: 100%;
              height: auto;
            }
            table {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
</style>