import { getCurrentInstance } from "vue";
import tools from "@/utils/tools";
import native from "./native";
import store from "@/store";
import axios from "axios";
import { img } from "@/utils/img";
import router from "@/router";
import i18n from "@/language/i18n";
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
let iconMap = {};
axios.get("./img/icon_map.json").then((rsp) => {
  if (rsp.status == 200) {
    iconMap = rsp.data.assets;
  }
});

//业务逻辑类
const action = {
  openPage(url) {
    //不跳出应用
    if (tools.isNative()) {
      native.openWebPage(url);
    } else {
      window.open(url);
    }
  },
  openBrowser(url, name, mode = "externalApplication") {
    //externalApplication 跳出应用
    if (tools.isNative()) {
      native.launch(url, name, mode);
    } else {
      window.open(url, name);
    }
  },
  imageUrl(subUrl) {
    let hotDomain = store.getters["global/lobbyInfo"].HotDomain;
    return hotDomain + subUrl;
  },
  gameIconUrl(gameCode, language = false) {
    let codeL = { zh: "CN", vi: "VN", en: "EN" };
    if (language == false) {
      language = tools.getLanguage();
    }
    var keyGameCode = gameCode + "_" + codeL[language];
    // console.log("gameIconUrl:" + keyGameCode)
    if (typeof iconMap[keyGameCode] == "undefined") {
      return img("empty.png"); //TODO
    }
    let hotDomain = store.getters["global/lobbyInfo"].HotDomain;
    //console.log(hotDomain + "/" + iconMap[keyGameCode])
    return hotDomain + "/" + iconMap[keyGameCode];
  },

  showStatus(status) {
    const s = {
      9: "成功",
      4: "失败",
      0: "处理中",
    };
    return s[status];
  },
  checkLogged() {
    let isLogged = store.getters["tcp/isLogged"];
    if (!isLogged) {
      // router.replace({ name: "login" })
      return false;
    }
    return true;
  },
  reportError(err) {
    // console.log(err.stack)
    // if (err.stack.length = 0){
    //     return
    // }
    if (!tools.isRelease()) return;
    for (let i in err.stack) {
      err.stack[i].context = null;
    }
    let parmas = {
      uuid: store.getters["global/uuid"],
      platform: tools.platform(),
      version: store.getters["global/version"],
      language: tools.getLanguage(),
      mode: err.mode,
      message: err.message,
      name: err.name,
      stackStr: JSON.stringify(err.stack),
      ua: navigator.userAgent,
    };
    req.post(http.errReport, qs.stringify(parmas));
  },
};

export default action;
