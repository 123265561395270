<template>
  <div class="footer">
    <div v-if="informationObj.list.length >0" class="footer-wrapper-web">
      <div v-if="informationObj.list.length >0" class="footer-info-web">
        <h4>{{t('INFORMATION')}}</h4>
        <ul class="footer-nav-web">
          <li v-for="(item, index) in informationObj.list" @click="itemClick(item)">
            <a :class="index == informationObj.active ? 'active' : ''">{{ tools.getCurLangValue(item.title) }}</a>
          </li>
        </ul>
      </div>
      <!-- <div v-if="webDesc && webDesc.status" class="license-wrapper undefined-custom-style">
        <div class="v-html-default">
          <div style="display: flex; align-items: center" v-html="webDesc.content"></div>
        </div>
      </div> -->
    </div>
    <div class="-inner-wrapper lazyloaded">
      <div class="container -image-wrapper">
        <!-- <img class="-img" :src="comApi.getLogoImg()" alt /> -->
        <div class="slide-seo-container">
          <div class="banner">
            <div class="banner-wrapper">
              <swiper :modules="modules" :autoplay="{ delay: 3000, disableOnInteraction: false }" :loop="true" :speed="1000" :circular="true" :pagination="{ clickable: true }" slides-per-view="auto">
                <swiper-slide v-for="(item, index) in webDescObj.list" :key="item">
                  <h2 class="-text">
                    {{tools.getCurLangValue(item.title)}}
                  </h2>
                  <p class="-sub-text" v-html="tools.getCurLangValue(item.content)">
                  </p>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

        <!-- <p class="-sub-text">
          <span class="-highlight">beteasy88</span>
          {{$t("Slot Online FullHD supports App iOS, Android, slots, casinos, fish shooting, and sports. Complete. One website from a famous camp in every corner of the world. There are more, but it's easier. Lots of games, beautiful pictures, new and popular. We include the most online slots and slot games. in thailand")  }}
        </p> -->
      </div>
      <div class="container">
        <div class="-footer-menu -vertical">
          <div class="-block-provider">
            <h6 class="-text-title -provider">{{t('在线老虎机')}}</h6>
            <div class="-list-menu">
              <div v-for="(item,index) in slotList" class="-list-item">
                <a class="-btn-link -provider" @click="toGameCampRouter(item)">{{item.factoryInfo.Name}}</a>
              </div>
            </div>
          </div>
          <div class="-block-menu">
            <h6 class="-text-title -menu">{{t('网页目录')}}</h6>
            <div class="-list-menu">
              <div v-for="(item, index) in navObj.list" class="-list-item -two-column" @click="navItemClick(item, index)">
                <a class="-btn-link">{{tools.getCurLangValue(item.Title)}}</a>
              </div>
            </div>
          </div>
          <!-- <div class="-block-tag">
            <h6 class="-text-title -tag">{{$t('More tags')}}</h6>
            <div class="-list-menu">
              <div class="-list-item -two-column">
                <a class="-btn-link">หน้าแรก</a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="text-center -copy-right-container">
      <p class="mb-0 -copy-right-text">
        Copyright ©️ {{ new Date().getFullYear() }} beteasy88 All Rights Reserved
      </p>
      <!-- <p class="mb-0 -copy-right-text">v:{{version}}</p> -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch
} from "vue";

import {
  useGetters,
  useActions,
  useMutations
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation, EffectFade } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const { version } = useGetters("global", ["version"]);
const modules = ref([Autoplay, Pagination, EffectFade]);

//信息
let informationObj = reactive({
  active: -1,
  list: getInfomationList()
})
//厂商列表
let factoryList = ref(getFactoryList());
//网站描述
let webDescObj = reactive({
  active: -1,
  list: getWebDescList()
})
// let webDesc = ref(lobbyInfo.value.MainCustomContent.find(info => (info.type == "webDesc" && info.platFormType == "pc")));

//老虎机厂商
const slotList = reactive(getDataListByType('Slot'));
//导航列表
const navObj = reactive({
  active: -1,
  list: getNavList(),
})

function getInfomationList() {
  let arr = [];
  lobbyInfo.value.MainCustomContent.forEach(info => {
    if (info.type == 'information' && info.status) {
      arr.push(info);
    }
  });
  arr.sort((a, b) => {
    return a.sort - b.sort;
  })
  return arr;
}

function getFactoryList() {
  let arr = [];
  for (let key in lobbyInfo.value.FactoryList) {
    if (lobbyInfo.value.FactoryList[key].Status == 1) {
      arr.push(lobbyInfo.value.FactoryList[key])
    }
  }
  return arr;
}

function getWebDescList() {
  let arr = [];
  lobbyInfo.value.MainCustomContent.forEach(info => {
    if (info.type == 'webDesc' && info.status) {
      if (tools.isMobile()) {
        if (info.platFormType == "app") {
          arr.push(info);
        }
      } else {
        if (info.platFormType == "pc") {
          arr.push(info);
        }
      }
    }
  });
  arr.sort((a, b) => {
    return a.sort - b.sort;
  })

  return arr;
}

function getDataListByType(id) {
  let arr = [];
  let curObj = lobbyInfo.value.MainPageConf.find(info => info._id == id).SubFactory;
  if (!curObj) return arr;
  for (let key in curObj) {
    arr.push(curObj[key])
  }
  arr.sort((a, b) => {
    return a.factoryInfo.Sort - b.factoryInfo.Sort;
  })

  if (id == "Fish" || id == "Card") {
    let allArr = [];
    for (let key in arr) {
      allArr.push(...arr[key].gameList);
    }
    return allArr;
  } else {
    return arr;
  }

}

function toGameCampRouter(item) {
  let parms = {
    flagId: 'gameCamp',
    hasChild: true,
    factoryName: '',
  }
  if (item) {
    parms.factoryName = item.factoryInfo.FactoryName
  }
  window.sessionStorage.setItem("slotRouterParams", JSON.stringify(parms));
  router.push({
    name: 'slot',
    params: {
      parms: JSON.stringify(parms)
    }
  })
}

function getNavList() {
  let routerList = comApi.getRouterList();
  for (let key in routerList) {
    let info = routerList[key];
    if (info._id == "Slot") {
      info.routerData = { name: 'slot', parms: { flagId: 'gameCamp', hasChild: true, factoryName: '' } }
    } else if (info._id == "Lottery") {
      info.routerData = { name: 'slot', parms: { flagId: 'lottery' } }
    } else if (info._id == "Fish") {
      info.routerData = { name: 'slot', parms: { flagId: 'fish' } }
    } else if (info._id == "Card") {
      info.routerData = { name: 'slot', parms: { flagId: 'gameSkill' } }
    }
  }
  // log.info('routerHeaderList---', routerList)
  return routerList;
}

function navItemClick(item, index) {
  navObj.active = index;
  if (item.router) {
    if (item.routerData) {
      window.sessionStorage.setItem("slotRouterParams", JSON.stringify(item.routerData.parms));
      router.push({
        name: item.routerData.name,
        params: {
          parms: JSON.stringify(item.routerData.parms)
        }
      })
    } else {
      router.push({
        path: item.router,
      })
    }
  }
}

function itemClick(item) {
  //zxType:1,内部，2，外部
  if (item.zxType == 2) {
    $act.openBrowser(item.link);
  } else {
    router.push({
      path: '/introduce',
      query: {
        name: item._id
      }
    })
  }
}

//处理选中路由 
function setSelectIndex() {
  if (curRouter.query == null || curRouter.query == undefined || curRouter.query.name == '') {
    informationObj.active = -1;
    return
  }
  if (curRouter.path.includes('introduce')) {
    let hasInRouter = false;
    for (let key in informationObj.list) {
      let info = informationObj.list[key];
      if (info._id == curRouter.query.name) {
        informationObj.active = Number(key);
        hasInRouter = true;
        break;
      }
    }
    if (!hasInRouter) {
      informationObj.active = -1;
    }
  } else {
    informationObj.active = -1;
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
})

</script>


<style lang="scss" scoped>
.footer {
  background: #fff;
  overflow: hidden;
  .footer-wrapper-web {
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(30%, rgba(0, 0, 0, 0.9)),
      color-stop(90%, transparent)
    );
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 30%, transparent 90%);
    padding: 50px 0 10px;
    font-size: 14px;

    .footer-info-web {
      margin: 0 0 20px;
      text-align: center;

      h4 {
        margin: 0 0 10px;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.1;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;

        li {
          display: inline-block;
          padding: 0 10px;
          color: #96a3ea;

          a {
            color: #96a3ea;
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
          }

          a:hover,
          .active {
            color: #ec4;
            text-decoration: none;
          }
        }
      }
    }

    .license-wrapper {
      padding: 40px;

      .v-html-default {
        color: #fff;
      }
    }

    .footer-brand-web {
      margin: 0 0 20px;
      text-align: center;

      h4 {
        margin: 0 0 10px;
        color: #fff;
        font-size: 18px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;

        li {
          display: inline-block;
          padding: 0 10px;
          margin-bottom: 10px;

          img {
            height: 30px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .-inner-wrapper {
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #fff;
    padding: 2rem 0;

    .-image-wrapper {
      text-align: center;
      padding: 2rem 0;
      width: 100%;
      margin-right: auto;
      margin-left: auto;

      .-img {
        width: 180px;
      }

      .-sub-text {
        width: 86%;
        margin: 1rem auto 0;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        color: #404e67;

        .-highlight {
          font-weight: 700;
          color: #0f161f;
        }
      }
      .slide-seo-container {
        margin-bottom: 30px;
        .banner {
          color: #828282;
          overflow: hidden;
          .banner-wrapper {
            overflow: hidden;
            :deep(.swiper) {
              margin-bottom: 2rem;
              overflow: visible;
              .swiper-slide:first-child {
                margin-left: 0;
                padding-left: 0;
              }

              .swiper-slide {
                position: relative;
              }
              .swiper-pagination {
                bottom: -25px;
                left: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                .swiper-pagination-bullet {
                  margin: 0 5px;
                  display: inline-block;
                  padding: 0;
                  position: relative;
                  cursor: pointer;
                  width: 10px;
                  height: 10px;
                  background: #5586d4;
                  color: transparent;
                  opacity: 0.7;
                }

                .swiper-pagination-bullet-active {
                  background: #0051d6;
                  opacity: 1;
                }
              }
            }
            .-text {
              font-size: 1.25rem;
              font-weight: 700;
              color: #404e67;
            }
            p {
              width: 86%;
              margin: 0.5rem auto 0;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: #828282;
            }
          }
        }
      }
    }
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .x-footer-seo {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;

      .-footer-seo-title {
        margin-bottom: 1rem !important;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }

      .-tags {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding-bottom: 0;
        text-align: center;
        border-bottom: none;

        .btn {
          display: inline-block;
          font-weight: 400;
          text-align: center;
          vertical-align: middle;
          margin-bottom: 6px;
          padding: 5px 15px;
          line-height: 1.5;
          font-size: 0.875rem;
          border-radius: 10px;
          color: #fff;
          background: #0051d6;
          transition: all 0.2s;
          margin-right: 8px;
        }
      }
    }
    .-footer-menu {
      display: block;
      margin-top: 0;
      .-text-title {
        color: #000;
        margin-top: 1.5rem;
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      .-list-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .-list-item {
          padding: 0.25rem;
          a {
            display: block;
            padding: 2px 5px;
            width: 100%;
            overflow: hidden;
            font-size: 0.875rem;
            color: #fff;
            background-color: #0f161f;
            text-align: center;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 5px;
            transition: background-color 0.2s ease;
            text-transform: capitalize;
            padding: 2px 10px;
            border-radius: 8px;
          }
          a:hover {
            color: #f7f7f7;
            background-color: #ff8a00;
          }
        }
      }
      .-block-provider,
      .-block-menu,
      .-block-tag {
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
  }

  .-animater-footer {
    position: relative;
  }

  .-copy-right-container {
    background-color: #f3f5f6;
    padding: 0.5rem 0;
    text-align: center !important;

    .-copy-right-text {
      font-weight: 500;
      color: #000;
    }
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .d-lg-none {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
</style>
