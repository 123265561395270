<template>
  <div class="commonTip">
    <el-dialog v-model="show" @close="close()">
      <div class="vex vex-theme-plain">
        <div class="vex-content">
          <div class="vex-dialog-message">{{ props.data.title }}</div>
          <div class="vex-dialog-buttons">
            <button v-if="props.data.cannel" type="button" class="close" @click="cannel()">{{t('Cancel')}}</button>
            <button type="button" class="ok" @click="confirm()">{{ props.data.confirmTitle ? props.data.confirmTitle : t('Confirm')}}</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {
    title: '',
    confirmTitle: '',
    cannel: false,
    func: null,
  }
})


function confirm() {
  if (props.data.func) {
    props.data.func();
  }
  close();
}

function cannel() {
  close()
}

function close() {
  bus.emit(bus.event.commonTipPop, { bool: false, data: {} });
}
</script>

<style lang="scss" scoped>
.commonTip {
  position: relative;
  z-index: 100000;

  :deep(.el-dialog) {
    width: 450px;
    max-width: 90%;
    padding: 0;
    border-radius: 10px;
    font-size: 16px;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;

      .vex-theme-plain {
        .vex-content {
          background: linear-gradient(180deg, #f3f5f6, #ffffff);
          color: #000;
          border-radius: 10px;
          padding: 1em;
          position: relative;
          text-align: center;
          margin: 0 auto;
          max-width: 100%;
          width: 100%;
          font-size: 1.1em;
          line-height: 1.5em;

          .vex-dialog-message {
            margin-bottom: 1.5em;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }

          .vex-dialog-buttons {
            display: flex;
            justify-content: center;

            .close {
              color: #fafafa;
              background: #cc0e30;
              min-width: 120px;
              margin: 0 8px;
              padding: 15px;
              font-size: 16px;
              border-radius: 10px;
              transition: all 0.2s;
              border: 0;
              float: right;
              font-family: inherit;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              line-height: 1em;
              cursor: pointer;
            }

            .ok {
              color: #fafafa;
              background: #7be96c;
              min-width: 120px;
              margin: 0 8px;
              padding: 15px;
              font-size: 16px;
              border-radius: 10px;
              transition: all 0.2s;
              border: 0;
              float: right;
              font-family: inherit;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              line-height: 1em;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>