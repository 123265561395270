<template>
  <div id="mobile-root">
    <Header />
    <router-view class="root-router" />
    <Footer />
    <FooterFix />
    <!-- <LiveChatWidget v-if="openLiveChat && liveChatId" :license="liveChatId" group="0" /> -->

    <!-- 最高优先级 -->
    <VersionPop v-if="showVersionPop" />
  </div>
</template>


<script setup>
import { ref, onMounted } from "vue";
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import log from "@/utils/logger";
import { bus } from "@/components/core/bus";
import { watch } from "vue";
import { reactive } from "vue";
import { LiveChatWidget } from '@livechat/widget-vue'
import Header from "@/views/mobile/components/Header.vue";
import Footer from "@/views/mobile/components/Footer.vue";
import FooterFix from "@/views/mobile/components/FooterFix.vue";
import VersionPop from "@/views/mobile/components/VersionPop.vue";
import tools from '@/utils/tools'

import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
const { t } = useI18n();

const router = useRouter();
const curRouter = useRoute();
const { isLogged } = useGetters("tcp", ["isLogged"]);
const { version } = useGetters("global", ["version"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);

const openLiveChat = ref(false);
const showVersionPop = ref(false)
bus.off(bus.event.jumpToTop);
bus.on(bus.event.jumpToTop, () => {
  jumpToTop();
})
bus.on(bus.event.openLiveChat, (bool) => {
  openLiveChat.value = true;
})

function jumpToTop() {
  document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  let pcRoot = document.getElementById('mobile-root');
  if (pcRoot) {
    pcRoot.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

getVersion()
bus.on("getVersion", (val) => {
  getVersion()
});

function getVersion() {
  let platform = tools.platform()
  if (version.value.indexOf('_') >= 0) {
    let currentVersion = version.value.split('_')[1]
    if (platform == 'android') {
      if (parseInt(lobbyInfo.value.VersionGet.android.VersionCode) > parseInt(currentVersion)) {
        showVersionPop.value = true;
      }
    }
  }
}

</script>

<style lang="scss">
#mobile-root {
  position: relative;
  width: 100%;
  // 顶部安全区
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.root-router {
}
.root-router:before {
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    visibility: hidden;
    display: none;
  }
}
</style>
