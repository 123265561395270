<template>
  <div class="vipHistory">
    <div class="memberform-fuild">
      <div class="listrecord">
        <el-row class="hidden-xs-only list-th">
          <el-col :span="7">
            {{t('Transaction Number')}}
          </el-col>
          <el-col :span="6">
            {{t('Transaction Time')}}
          </el-col>
          <el-col :span="6">
            {{t('Transaction Amount')}}
          </el-col>
          <el-col :span="5">
            {{t('Bonus Type')}}
          </el-col>
        </el-row>
        <div v-if="recordObj.allList.length <= 0" class="norecord">{{t('No Record')}}</div>
        <div v-else class="even-list">
          <el-row v-for="(item, index) in recordObj.list" class="list-td">
            <el-col :span="7">
              <div class="el-col-div"> {{ item.oid }}</div>
            </el-col>

            <el-col :span="6">
              <div class="el-col-div"> {{ tools.formatDate(item.create_at, "yyyy-MM-dd") }}
                {{ tools.formatDate(item.create_at, "hh:mm") }}</div>
            </el-col>
            <el-col :span="6">
              <div class="el-col-div"> {{ tools.formatNum2k(item.get) }}</div>
            </el-col>
            <el-col :span="5">
              <div class="el-col-div">{{ t(item.member_type) }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
        <el-pagination background layout="prev, pager, next" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const recordObj = reactive({
  allList: [],
  list: []
})

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 10,
  total: 0,
})

if (isLogged.value) {
  getRecord()
}
bus.on('onConnected', () => {
  getRecord();
})
function getRecord() {
  let parms = {
  }
  mqant.request(topic.queryVipRecord, parms, function (data, topicName, msg) {
    log.info('billRecord--', data.Data)
    if (data.Code == 0) {
      recordObj.allList = data.Data.BetRecord;
      //记录总数
      paginationObj.total = recordObj.allList.length;
      //分页截取
      sliceArr();
    } else {
    }
  });
}
const handleSizeChange = (val) => {
  sliceArr();
}
const handleCurrentChange = (val) => {
  sliceArr();
}
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}

</script>

<style lang="scss" scoped>
.vipHistory {
  position: relative;
  color: #0f161f;

  .memberform-fuild {
    .listrecord {
      margin-bottom: 30px;

      .norecord {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background: #fff;
        color: #0f161f;
        border: 1px solid #dbe0e4;
        border-top: 0;
        padding: 50px 10px;
        text-align: center;
      }

      .list-th {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: #f7f7f7;
        border: 1px solid #dbe0e4;

        > .el-col {
          border-right: 1px solid #dbe0e4;
          font-weight: 600;
        }
      }

      .list-td {
        background: #fff;
        border: 1px solid #dbe0e4;
        border-top: 0;

        > .el-col {
          border-right: 1px solid #dbe0e4;
          padding: 0;
        }
      }

      .el-row {
        .el-col {
          display: table-cell;
          float: none;
          vertical-align: middle;
          padding: 10px;
          text-align: center;
          position: relative;

          .el-col-div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;

      :deep(.el-pagination) {
        .is-active {
          background-color: #6c5f4e !important;
        }
      }
    }
  }
}
</style>