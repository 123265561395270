<template>
  <div class="promoHistory">
    <div class="memberform-fuild">
      <div class="listrecord">
        <div v-if="recordObj.allList.length <= 0" class="norecord">{{t('No Record')}}</div>
        <div v-else class="even-list">
          <div class="item" v-for="(item, index) in recordObj.list">
            <div class="item-row">
              <div class="item-row-col-1">{{t('Transaction Number')}}</div>
              <div class="item-row-col-2">{{ item.Oid }}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1">{{t('Transaction Time')}}</div>
              <div class="item-row-col-2">{{ $tools.formatDate(item.UpdateAt, "yyyy-MM-dd") }}
                {{ $tools.formatDate(item.UpdateAt, "hh:mm") }}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1">{{t('Bonus Amount')}}</div>
              <div class="item-row-col-2">{{ $tools.formatNum2k(item.Award) }}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1">{{t('Memo')}}</div>
              <div class="item-row-col-2">{{ item.ActivityName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
        <el-pagination background layout="prev, pager, next" :pager-count="5" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const recordObj = reactive({
  allList: [],
  list: []
})

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 5,
  total: 0,
})

if (isLogged.value) {
  getRecord()
}
bus.on('onConnected', () => {
  getRecord()
})
function getRecord() {
  let parms = {
  }
  mqant.request(topic.queryUserJoinedActs, parms, function (data, topicName, msg) {
    log.info('queryUserJoinedActs--', data.Data)
    if (data.Code == 0) {
      // recordObj.allList = getListByType(data.Data.JoinActs);
      recordObj.allList = data.Data.JoinActs;
      //记录总数
      paginationObj.total = recordObj.allList.length;
      //分页截取
      sliceArr();
    } else {
    }
  });
}

function getListByType(allList) {
  let arr = [];
  for (let key in allList) {
    if (allList[key].Status == "Received") {
      arr.push(allList[key]);
    }
  }
  return arr
}

const handleSizeChange = (val) => {
  sliceArr();
}
const handleCurrentChange = (val) => {
  sliceArr();
}
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}

</script>

<style lang="scss" scoped>
.promoHistory {
  position: relative;
  color: #333333;

  .top-title {
    background-image: none;
    position: relative;
    margin-bottom: 15px;
    height: auto;
    text-align: center;

    h3 {
      background: none;
      width: 100%;
      height: auto;
      position: relative;
      border-bottom: none;
      padding: 0 30px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
      font-size: 24px;
    }
  }

  .memberform-fuild {
    border-radius: 10px;
    padding: 20px;
    background: #fff;

    .listrecord {
      margin-bottom: 30px;

      .norecord {
        border-radius: 5px;
        border: 1px solid #dbe0e4;
        margin-bottom: 20px;
        background: #fff;
        color: #0f161f;
        padding: 50px 10px;
        text-align: center;
      }

      .even-list {
        .item {
          border-radius: 5px;
          border: 1px solid #ddd;
          margin-bottom: 20px;

          .item-row {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ddd;

            .item-row-col-1 {
              padding: 10px;
              font-weight: 600;
              width: 40%;
              text-align: center;
              border-right: 1px solid #ddd;
            }

            .item-row-col-2 {
              padding: 10px;
              font-weight: 600;
              width: 60%;
              text-align: center;
              word-break: break-all;
            }
          }

          .item-row:last-child {
            border-bottom: none;
          }
        }
      }

      .list-th {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: #f7f7f7;
        border: 1px solid #dbe0e4;

        > .el-col {
          border-right: 1px solid #dbe0e4;
          font-weight: 600;
        }
      }

      .list-td {
        background: #fff;
        border: 1px solid #dbe0e4;
        border-top: 0;

        > .el-col {
          // border-right: 1px solid #dbe0e4;
          padding: 0;
        }
      }

      .el-row {
        .el-col {
          display: table-cell;
          float: none;
          vertical-align: middle;
          padding: 10px;
          text-align: center;
          position: relative;

          .el-col-div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;

      :deep(.el-pagination) {
        .is-active {
          background-color: #6c5f4e !important;
        }
      }
    }
  }
}
</style>