<template>
  <div class="introduce">
    <div class="x-cover">
      <img src="@/assets/pc/home/bg-index-login.png" alt="">
    </div>
    <div class="description-nav">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in informationObj.list" class="item" :class="informationObj.active == index ? 'active' : ''" @click="itemClick(item, index)">
          <span>{{ tools.getCurLangValue(item.title) }}</span>
        </div>
      </div>
    </div>
    <div class="web-description">
      <div class="com-container wrapperBg">
        <div class="footerpgStyle">
          <div v-html="tools.getCurLangValue(informationObj.list[informationObj.active].content)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
//信息
let informationObj = reactive({
  active: 0,
  list: getInfomationList()
})

function getInfomationList() {
  let arr = [];
  lobbyInfo.value.MainCustomContent.forEach(info => {
    if (info.type == 'information' && info.status && info.zxType == 1) {
      arr.push(info);
    }
  });
  arr.sort((a, b) => {
    return a.sort - b.sort;
  })
  return arr;
}

//处理选中路由 
function setSelectIndex() {
  if (curRouter.query == null || curRouter.query == undefined || curRouter.query.name == '') {
    informationObj.active = 0;
    return
  }
  if (curRouter.path.includes('introduce')) {
    let hasInRouter = false;
    for (let key in informationObj.list) {
      let info = informationObj.list[key];
      if (info._id == curRouter.query.name) {
        informationObj.active = Number(key);
        hasInRouter = true;
        break;
      }
    }
    if (!hasInRouter) {
      informationObj.active = 0;
    }
  }

}
watch(() => {
  curRouter.path, setSelectIndex();
})

function itemClick(item) {
  //zxType:1,内部，2，外部
  if (item.zxType == 2) {
    $act.openBrowser(item.link);
  } else {
    router.push({
      path: '/introduce',
      query: {
        name: item._id
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.introduce {
  .x-cover {
    img {
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }
  }
  p {
    margin: 0 0 10px;
  }

  .description-nav {
    padding: 30px 0;
    margin: 0;
    text-align: center;

    .swiper-wrapper {
      padding: 30px 0;
      width: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 0 auto;

      .item {
        display: inline-block;
        padding: 0;
        width: 180px;
        min-height: 20px;

        span {
          position: relative;
          color: #0f161f;
          cursor: pointer;
        }

        span:after {
          content: "";
          width: 100%;
          height: 3px;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -5px;
          background: #fff;
        }

        span:hover::after {
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      .item:not(:first-child) {
        border-left: 1px solid #0f161f;
      }

      .active {
        span {
          color: #ec4;
        }
        span:after {
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
  }

  .web-description {
    padding: 15px;
    min-height: 54vh;

    .wrapperBg {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 10px;
      padding: 30px 50px;
      color: #fff;
      font: 15px bold;
      width: 65%;
      *zoom: 1;
      margin-right: auto;
      margin-left: auto;
      position: relative;
    }
  }
}
</style>