import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(".", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // messages[locale] = locales(key).default
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getCurLanguage() {
  let defaultLang = "bm";
  let locale = localStorage.getItem("localMyLanguage");
  if (!locale) return defaultLang;
  let langList = ["en", "th", "bm"]; //comApi.getLanguageList
  for (let key in langList) {
    if (langList[key] == locale) {
      return locale;
    }
  }
  return defaultLang;
}

export default createI18n({
  legacy: false,
  locale: getCurLanguage(),
  // locale: process.env.VUE_APP_I18N_LOCALE || "bm",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "bm",
  messages: loadLocaleMessages(),
});
