<template>
  <div class="profile">
    <div class="member-section">
      <div class="container">
        <div class="member-menu">
          <div class="member-section">
            <div class="accountdata">
              <div class="mem_main_menu">
                <div class="mem-slide">
                  <swiper slides-per-view="auto">
                    <swiper-slide class="" v-for="(item, index) in obj.list" :key="item" @click="itemClick(item, index)">
                      <a :class="index == obj.active ? 'active' : ''">{{ t(item.label) }}</a>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
              <a class="back-hall" @click="back()"></a>
            </div>
          </div>
        </div>
        <div class="member-right">
          <div class="member-content">
            <div class="tab-content">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

let obj = reactive({
  active: 0,
  list: [
    {
      label: 'Personal Information',
      router: '/member/profile/personal'
    },
    {
      label: 'Bet History',
      router: '/member/profile/betHistory'
    },
    // {
    //   label: 'Password management',
    //   router: '/member/profile/password/passwordLogin'
    // }
  ]
})

//处理选中路由 
function setSelectIndex() {
  if (curRouter.path.includes('profile')) {
    //选择
    for (let key in obj.list) {
      let info = obj.list[key];
      if (info.router == curRouter.path) {
        obj.active = Number(key);
      }
    }
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
})

function itemClick(item, index) {
  obj.active = index
  if (item.router) {
    router.push({
      path: item.router
    })
  }
}

function back() {
  router.push(
    {
      path: '/member/memberHall'
    }
  );
}
</script>

<style lang="scss" scoped>
.profile {
  position: relative;

  .member-section {
    padding-top: 0;
    padding-bottom: 15px;

    .container {
      width: 100%;
      padding: 0 10px;

      .member-menu {
        .member-section {
          .accountdata {
            color: #fff;
            position: relative;

            .mem_main_menu {
              .mem-slide {
                :deep(.swiper) {
                  position: relative;
                  padding: 10px 0 0;
                  margin-left: 40px;

                  .swiper-slide {
                    padding: 0 1.8% 0 0;
                    width: auto;

                    a {
                      border-radius: 5px;
                      background-color: #f7f7f7;
                      border: 1px solid hsla(0, 0%, 100%, 0.2);
                      color: #0f161f;
                      line-height: 20px;
                      text-align: center;
                      display: block;
                      padding: 10px 30px;
                    }

                    .active {
                      background-image: linear-gradient(
                          0deg,
                          #fe725d 4%,
                          #ff8a00 100%
                        ),
                        linear-gradient(#000000, #000000);
                      color: #fff;
                    }
                  }
                }
              }
            }

            .back-hall {
              width: 30px;
              height: 30px;
              display: block;
              background: url("@/assets/pc/common/back-hall.png");
              top: 15px;
              position: absolute;
              z-index: 3;
              line-height: 30px;
              color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>