<template>
  <div class="mailBoxTitle">
    <MemberTitle :title="t('Bonus')" />
    <div class="memberform-fuild">
      <div class="bulk-action-button-group">
        <button class="left-btn" :class="getDeleteState() ? 'disabled' : ''" @click="deleteAll()">
          {{ t("Delete All") }}
        </button>
        <button :class="getReceiveState() ? 'disabled' : ''" class="right-btn" @click="receiveAll()">
          {{ t("Receive All") }}
        </button>
      </div>
      <div v-if="!recordObj.loading" class="message_promotion">
        <div class="listrecord">
          <el-row class="hidden-xs-only list-th">
            <el-col :span="5">
              {{ t("Promotions") }}
            </el-col>
            <el-col :span="4">
              {{ t("Bonus") }}
            </el-col>
            <el-col :span="4">
              {{ t("Release Time") }}
            </el-col>
            <el-col :span="4">
              {{ t("Received Time") }}
            </el-col>
            <el-col :span="4">
              {{ t("Draw End Date") }}
            </el-col>
            <el-col :span="3">
              {{ t("Status") }}
            </el-col>

          </el-row>
          <div v-if="recordObj.allList.length <= 0" class="norecord">
            {{ t("No Record") }}
          </div>
          <div v-else class="even-list">
            <el-row v-for="(item, index) in recordObj.list" class="list-td">
              <el-col :span="5">
                <div class="el-col-div text-center">
                  <!-- <i class="fa fa-envelope-open"></i> -->
                  <span>{{ item.EventType }}</span>
                </div>
              </el-col>

              <el-col :span="4">
                <div class="el-col-div text-center">
                  {{ tools.formatNum2k(item.Amount) }}
                </div>
              </el-col>
              <el-col :span="4">
                <div class="el-col-div text-center">
                  {{ tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
                  {{ tools.formatDate(item.CreateAt, "hh:mm") }}
                </div>
              </el-col>
              <el-col :span="4">
                <div class="el-col-div text-center">
                  {{item.ReceiveTime}}
                </div>
              </el-col>
              <el-col :span="4">
                <div class="el-col-div text-center">
                  {{ item.OverTime }}
                </div>
              </el-col>
              <el-col :span="3">
                <div class="el-col-div text-center">
                  <button v-if="item.DetailStatus == 'Done'" class="release-btn" @click="toReceive(item)">{{t('Received')}}</button>
                  <span v-else> {{ t(item.DetailStatus) }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
          <el-pagination background layout="prev, pager, next" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);

const curType = "";
const recordObj = reactive({
  allList: [],
  list: [],
  mailUnreadNum: 0,
  loading: false,
});

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 10,
  total: 0,
});

if (isLogged.value) {
  getRecord();
}

bus.on('onConnected', () => {
  getRecord();
})

function getRecord() {
  let parms = {
  };
  mqant.request(topic.actionGetManualAwardList, parms, function (data, topicName, msg) {
    log.info("actionGetManualAwardList--", data.Data);
    if (data.Code == 0) {
      recordObj.allList = data.Data;
      //记录总数
      paginationObj.total = recordObj.allList.length;
      //分页截取
      sliceArr();
      recordObj.loading = false;
    } else {
    }
  });
}

function getDeleteState() {
  if (recordObj.allList.length <= 0) {
    return true;
  }
  let allReceive = true;
  for (let key in recordObj.allList) {
    if (recordObj.allList[key].DetailStatus !== "Done") {
      allReceive = false;
    }
  }
  if (allReceive) {
    return true;
  }
  return false;
}

function getReceiveState() {
  if (recordObj.allList.length <= 0) {
    return true;
  }
  for (let key in recordObj.allList) {
    if (recordObj.allList[key].DetailStatus == "Done") {
      return false;
    }
  }
  return true;
}


function receiveAll() {
  if (getReceiveState()) return;
  ElMessageBox.confirm(
    t("Are you sure you want to receive all?"),
    t("Receive All"),
    {
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
      type: "warning",
    }
  )
    .then(() => {
      let parms = {
      };
      log.info("parms--", parms);
      mqant.request(topic.actionReceiveManualAward, parms, function (data, topicName, msg) {
        log.info("actionReceiveManualAward--", data.Data);
        if (data.Code == 0) {
          getRecord();
        } else {
        }
      });
    })
    .catch(() => { });
}
function deleteAll() {
  if (getDeleteState()) return;
  ElMessageBox.confirm(
    t("Are you sure you want to delete all?"),
    t("Delete All"),
    {
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
      type: "warning",
    }
  )
    .then(() => {
      let parms = {
      };
      log.info("parms--", parms);
      mqant.request(topic.actionDeleteManualAward, parms, function (data, topicName, msg) {
        log.info("actionDeleteManualAward--", data.Data);
        if (data.Code == 0) {
          getRecord();
        } else {
        }
      });
    })
    .catch(() => { });
}

function toReceive(item) {
  let parms = {
    Oid: item.oid,
  }
  log.info('parms--', parms)
  mqant.request(topic.actionReceiveManualAward, parms, function (data, topicName, msg) {
    log.info('actionReceiveManualAward--', data.Data)
    if (data.Code == 0) {
      getRecord();
      ElMessage.success(t('Operation successful'));
    } else {
    }
  });
}

const handleSizeChange = (val) => {
  sliceArr();
};
const handleCurrentChange = (val) => {
  sliceArr();
};
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}


</script>

<style lang="scss" scoped>
.mailBoxTitle {
  position: relative;
  color: #333;
  .memberform-fuild {
    padding: 0 40px 100px;

    .bulk-action-button-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      margin-bottom: 20px;

      .left-btn,
      .right-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 5px 20px;
        min-width: 120px;
        min-height: 50px;
        font-size: 20px;
        text-align: center;
        border-radius: 5px;
        border: none;
        cursor: pointer;
      }

      .disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
      }

      .left-btn {
        margin-right: 15px;
        color: #353535;
        border: 1px solid #ff8a00 !important;
      }

      .right-btn {
        color: #fff;
        background: #ff8a00;
      }
    }

    .message_promotion {
      .listrecord {
        margin-bottom: 30px;

        .norecord {
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          background: #fff;
          border: 1px solid #dbe0e4;
          border-top: 0;
          padding: 50px 10px;
          text-align: center;
        }

        .list-th {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          background: #f7f7f7;
          border: 1px solid #dbe0e4;

          > .el-col {
            border-right: 1px solid #dbe0e4;
            font-weight: 600;
          }
        }

        .list-td {
          background: #fff;
          border: 1px solid #dbe0e4;
          border-top: 0;

          > .el-col {
            border-right: 1px solid #dbe0e4;
            padding: 0;
          }
        }

        .list-td:hover {
          background: #167c7c;

          .el-col-div {
            color: #fff !important;
          }
        }

        .el-row {
          .el-col {
            display: table-cell;
            float: none;
            vertical-align: middle;
            padding: 10px;
            text-align: center;
            position: relative;

            .el-col-div {
              cursor: pointer;
              text-align: left;
              position: relative;
              top: 50%;
              transform: translateY(-50%);

              i {
                cursor: pointer;
                margin-right: 5px;
              }
            }

            .text-center {
              text-align: center !important;
            }
            .release-btn {
              width: 100%;
              padding: 5px;
              font-size: 18px;
              color: #fff;
              background: #ea9f13;
              border-radius: 3px;
              border: 0;
              cursor: pointer;
            }
            .unread {
              color: #555;
            }

            .isread {
              color: #999;
            }
          }
        }
      }

      .pagination-wrapper {
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
        position: relative;

        :deep(.el-pagination) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);

          .is-active {
            background-color: #6c5f4e !important;
          }
        }
      }
    }
  }
}
</style>
