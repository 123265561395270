<template>
  <div class="referralRebateBonus">
    <MemberTitle :title="t('Referral Rebate Bonus')" />
    <div v-if="obj.loading">
      <div class="info">
        <el-row class="info-el-row">
          <el-col :span="14" class="info-el-col">
            <div class="referral-rebate-bonus-block summary-block">
              <div class="block-top">
                <div class="block-title">
                  {{$t('Overview')}}
                </div>
                <div class="block-right" @click="toHistory()">
                  <span>{{$t('Bonus History')}}</span>
                  <a>
                    <img src="@/assets/pc/referral/arrow-right.svg">
                  </a>
                </div>
              </div>
              <div class="block-content">
                <el-row>
                  <el-col :span="8">
                    <div class="block-column">
                      <div class="column-title">{{t('Today’s profit')}}</div>
                      <div class="column-value">
                        <span>{{$tools.formatNum2k(obj.data.todayGet)}}</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="block-column">
                      <div class="column-title">{{t('Total recommended')}}</div>
                      <div class="column-value">
                        <span>0</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="block-column">
                      <div class="column-title">{{t('Total referral bonus')}}</div>
                      <div class="column-value">
                        <span>0</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="block-column">
                      <div class="column-title">{{t('Yesterday’s profit')}}</div>
                      <div class="column-value">
                        <span>{{$tools.formatNum2k(obj.data.yesterdayGet)}}</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="block-column">
                      <div class="column-title">{{t('Total rebate bonus')}}</div>
                      <div class="column-value">
                        <span>{{$tools.formatNum2k(obj.data.totalGet)}}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col :span="10" class="info-el-col">
            <div class="recommend-now">
              <div class="recommend-now-title">{{t('Invite a Partner')}}</div>
              <div class="recommend-now-content">
                <div class="recommand-now-qrcode-wrap">
                  <vue-qr v-if="tools.getQueryShareUrl(obj.data.shareLink).length > 0" class="qr-img" :text="tools.getQueryShareUrl(obj.data.shareLink)" :size="112" :margin='10'></vue-qr>
                  <div class="qrcode-button-wrap">
                    <div class="download-btn">
                      <img src="@/assets/pc/referral/download.svg">
                    </div>
                    <div class="copy-btn">
                      <img src="@/assets/pc/referral/copy_1.svg">
                    </div>
                  </div>
                </div>
                <div class="recommand-now-link-wrap">
                  <div class="recommend-now-code">
                    <div class="recommand-now-link-title">
                      {{t('Referral Code')}}
                    </div>
                    <div class="recommand-now-link-content">
                      <div class="recommand-now-link-input-wrap">
                        <div class="recommand-now-link-input">{{getValueAfterEqualSign(obj.data.shareLink)}}</div>
                        <div class="copy-link-btn" @click="copyUrl(getValueAfterEqualSign(obj.data.shareLink))"></div>
                      </div>
                    </div>
                  </div>
                  <div class="recommand-now-link">
                    <div class="recommand-now-link-title">{{t('Referral URL')}}</div>
                    <div class="recommand-now-link-content">
                      <div class="recommand-now-link-input-wrap">
                        <div class="recommand-now-link-input">{{tools.getQueryShareUrl(obj.data.shareLink)}}</div>
                        <div class="copy-link-btn" @click="copyUrl(tools.getQueryShareUrl(obj.data.shareLink))"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="rebateCombin">
        <div class="leaderboard">
          <h4>{{t('Rebate Bonus Leaderboard')}}</h4>
          <div class="board">
            <div class="item" v-for="(item,index) in obj.data.RankList">
              <div class="index">
                <img :src="require('@/assets/pc/referral/rank' + index + '.png')">
              </div>
              <div class="username">{{getStarName(item.Account)}}</div>
              <div class="amount" style="">{{$tools.formatNum2k(item.Get)}}</div>
            </div>
          </div>
        </div>
        <div class="received">
          <h4>{{t('Who received the bonus')}}</h4>
          <swiper :modules="modules" direction="vertical" :speed="1000" :autoplay="{ delay: 2000, disableOnInteraction: false }" :loop="true" :circular="true" slides-per-view="auto">
            <swiper-slide v-for="(item, index) in obj.data.record" :key="item">
              <div class="item">
                <div class="username">{{getStarName(item.Account)}}</div>
                <div class="tag">{{t('Get rebate bonus')}}</div>
                <div class="amount">{{$tools.formatNum2k(item.Get)}}</div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="bottom-mask"></div>
        </div>
      </div>
      <div class="bonus-tab-container">
        <div class="tab-navbar">
          <div v-for="(item,index) in tabObj.list" class="nav-item" :class="index == tabObj.index ?'active-tab':'' " @click="tabItemClick(item,index)">
            {{t(item.label)}}
          </div>
        </div>
        <div v-if="tabObj.index == 0" class="tab-content tab-promote">
          <div class="calculate-block">
            <h4 class="promote-title">{{t('Rebate bonus calculator')}}</h4>
            <div class="promote-description">{{t('Invite')}}
              <span class="member-count">{{sliderObj.list[sliderObj.index].Invite }}</span>
              {{t('active users expected monthly revenue')}}</div>
            <div class="promote-value">{{$tools.formatNum2k(sliderObj.list[sliderObj.index].Get) }}</div>
            <div class="promote-backup">
              <i class="el-icon-info"></i>
              <span>{{t('The above is the average value of rebate bonus, for reference only')}}</span>
            </div>
            <div class="promote-slider">
              <div class="slider-runway">
                <div v-for="(item,index) in sliderObj.list" class="slider-stop" :class="index == sliderObj.index ? 'target-stop':''" @click="sliderItemClick(item,index)">
                  {{item.Invite}}</div>
              </div>
            </div>
          </div>
          <el-row class="promote-info">
            <el-col class="promote-image" :span="12">
              <img :src="getTitleUrl()" alt="">
            </el-col>
            <el-col :span="12">
              <div class="promote-content">
                <div class="promote-info-content">
                  <div class="v-html-default" v-html="tools.getCurLangValue(obj.data.conf.ContentPc)"> </div>
                  <div class="bottom-mask"></div>
                </div>
                <button class="more-btn" @click="toPop()">
                  <span>{{t('More')}}</span>
                  <i class="el-icon-back"></i>
                </button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="tabObj.index == 1" class="tab-content tab-history" id="tabRebateBonus">
          <div class="tab-navbar">
            <div v-for="(item,index) in historyObj.list" class="nav-item" :class="index == historyObj.index ?'active-tab':'' " @click="historyItemClick(item,index)">
              {{t(item.label)}}
            </div>
          </div>
          <div v-if="historyObj.index == 0" class="tab-content">
            <div class="content-block">
              <div class="history-title">{{t('Statistics today')}}</div>
              <el-table :data="historyObj.list[0].statistics" style="width: 100%;height:250px">
                <el-table-column :label="t('Level')" prop="level" align="center">
                </el-table-column>
                <el-table-column :label="t('Deposit Amount')" prop="charge" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.charge) }}
                  </template>
                </el-table-column>

                <el-table-column v-if="obj.type=='validBet'" :label="t('Valid Bet Amount')" prop="bet" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.bet) }}
                  </template>
                </el-table-column>
                <el-table-column v-if="obj.type=='income'" :label="t('Player losses')" prop="income" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.income) }}
                  </template>
                </el-table-column>
                <el-table-column :label="t('Reward amount')" prop="award" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.award) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="content-block">
              <div class="history-title">
                <div class="table-title">{{t('Bonus History')}}</div>
                <div class="search-date">
                  <div class="date-label">{{t('Period')}}</div>
                  <el-date-picker v-model="historyObj.list[0].time" @change="getRecord" type="date" format="YYYY/MM/DD" size="large" />
                </div>
              </div>
              <el-table class="history-table" :data="historyObj.list[0].history" style="width: 100%;height:300px">
                <el-table-column :label="t('Level')" prop="level" align="center">
                </el-table-column>
                <el-table-column :label="t('Deposit Amount')" prop="charge" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.charge) }}
                  </template>
                </el-table-column>
                <el-table-column v-if="obj.type=='validBet'" :label="t('Valid Bet Amount')" property="bet" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.bet) }}
                  </template>
                </el-table-column>
                <el-table-column v-if="obj.type=='income'" :label="t('Player losses')" prop="income" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.income) }}
                  </template>
                </el-table-column>
                <el-table-column :label="t('Reward amount')" prop="award" align="center">
                  <template v-slot:default="scope">
                    {{  tools.formatNum2k(scope.row.award) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div v-if="historyObj.index == 1" class="tab-content tab-referral-bonus">
            <div class="content-block">
              <div class="history-title">
                <div class="table-title">{{t('Bonus History')}}</div>
                <div class="search-date">
                  <div class="date-label">{{t('Period')}}</div>
                  <el-date-picker v-model="historyObj.list[1].time" type="date" format="YYYY/MM/DD" size="large" />
                </div>
              </div>
              <el-table :data="historyObj.list[1].history" style="width: 100%">
                <el-table-column :label="t('Account')"></el-table-column>
                <el-table-column :label="t('Register Time')"></el-table-column>
                <el-table-column :label="t('Reward amount')"></el-table-column>
              </el-table>
            </div>
            <el-pagination background layout="prev, pager, next" :pager-count="5" :total="1000" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import comApi from "@/utils/comApi";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, } from "swiper";
import "swiper/swiper.css";

const { t, locale } = useI18n();
const { isLogged, wallet, basicInfo } = useGetters("tcp", ["isLogged", "wallet", "basicInfo"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);

const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const modules = ref([Autoplay]);
const obj = reactive({
  loading: false,
  data: {},
  type: '',//validBet,income
})
const tabObj = reactive({
  index: 0,
  list: [
    {
      label: 'Promote'
    },
    {
      label: 'Bonus History',
      list: [
        {

        },
        {

        }
      ]
    }
  ]
})
const sliderObj = reactive({
  index: 0,
  list: [
  ]
})
const historyObj = reactive({
  index: 0,
  list: [
    {
      label: 'Referral Rebate Bonus',
      time: '',
      statistics: [],
      history: []
    },
    // {
    //   label: 'Referral Bonus',
    //   time: '',
    //   history: []
    // }
  ]
})
historyObj.list[0].time = tools.getDateByType('yesterday')[0];
// historyObj.list[1].time = tools.getDateByType('yesterday')[0];
if (isLogged.value) {
  getConf();
}

bus.on('onConnected', () => {
  getConf();
})

function getConf() {
  let parms = {
    ActivityId: 'AllMemberAsAgent'
  }
  mqant.request(topic.getAllMemberAgentConf, parms, function (data, topicName, msg) {
    log.info("topic.getAllMemberAgentConf", data);
    if (data.Code == 0) {
      obj.data = data.Data;
      obj.type = data.Data.rules.AllMemberBasicInfo.CalcMethod
      historyObj.list[0].statistics = data.Data.todayStatistics;
      sliderObj.list = data.Data.rules.Calculator
      obj.loading = true;
    } else {
    }
  });
}

function getRecord() {
  if (!historyObj.list[0].time) return;
  let startDate = historyObj.list[0].time.Format("yyyy-MM-dd") + ' 00:00:00';
  let endDate = historyObj.list[0].time.Format("yyyy-MM-dd") + ' 23:59:59';
  let parms = {
    ActivityId: 'AllMemberAsAgent',
    startDate: startDate,
    endDate: endDate
  }
  log.info('parms', parms);
  mqant.request(topic.getAllMemberAgentRecord, parms, function (data, topicName, msg) {
    log.info("topic.getAllMemberAgentRecord", data);
    //在网页翻译成中文时，会导致数据不刷新，强制下一帧刷新
    if (data.Code == 0) {
      historyObj.list[0].history = [];
      nextTick(() => {
        historyObj.list[0].history = data.Data;
      })
    } else {
    }
  });
}

function toHistory() {
  tabObj.index = 1;
  getRecord();
  nextTick(() => {
    let tabRebateBonus = document.getElementById('tabRebateBonus');
    if (tabRebateBonus) {
      tabRebateBonus.scrollIntoView({ behavior: 'smooth' });
    }
  })
}

function getValueAfterEqualSign(str) {
  const match = str.match(/=\s*(\d+)/);
  return match ? match[1] : null;
}

function getStarName(name) {
  let str = ''
  for (var i = 0; i < name.length; i++) {
    if (i == 0 || i == name.length - 1) {
      str += name.substr(i, 1)
    } else {
      str += '*'
    }
  }
  return str
}

function getTitleUrl() {
  if (tools.isMobile()) {
    return comApi.getNormalImg(obj.data.conf.TitleUrlP);
  } else {
    return comApi.getNormalImg(obj.data.conf.TitleUrl);
  }

}

function toPop() {
  let item = {
    iconMobile: obj.data.conf.TitleUrlP,
    icon: obj.data.conf.TitleUrl,
    title: '',
    desc: obj.data.conf.ContentPc
  }
  bus.emit(bus.event.promotionPop, { bool: true, data: item })
}


function sliderItemClick(item, index) {
  sliderObj.index = index;
}

function tabItemClick(item, index) {
  tabObj.index = index;
  if (index == 1) {
    getRecord()
  }
}

function historyItemClick(item, index) {
  historyObj.index = index;
}

function copyUrl(text) {
  tools.copyText(text).then(
    (succ) => {
      ElMessage.success(t("Copy successful"));
    },
    (err) => {
    }
  );
}

</script>

<style lang="scss" scoped>
.referralRebateBonus {
  position: relative;
  padding: 24px;
  color: #333333;
  .info {
    position: relative;
    .info-el-row {
      margin-left: -8px;
      margin-right: -8px;
      .info-el-col {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .summary-block {
      background-color: #fff;
      border: 1px solid #dedede;
      padding: 16px;
      border-radius: 12px;
      height: 100%;
      .block-top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 20px;
        .block-title {
          font-size: 18px;
          font-weight: 700;
          color: #000;
        }
        .block-right {
          font-size: 14px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            img {
              width: 20px;
            }
          }
        }
      }
      .block-content {
        .el-row {
          margin-left: -10px;
          margin-right: -10px;
          position: relative;
          .el-col {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        .block-column {
          margin-bottom: 29px;
          .column-title {
            font-size: 14px;
            color: #6c5f4e;
          }
          .column-value {
            color: #000;
            font-size: 24px;
          }
        }
      }
    }
    .recommend-now {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 16px 16px 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      grid-gap: 20px;
      gap: 20px;
      border-radius: 10px;
      background: #f4f4f4;
      height: 100%;
      .recommend-now-title {
        font-size: 18px;
        font-weight: 700;
      }
      .recommend-now-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        grid-gap: 24px;
        gap: 24px;
        align-self: stretch;
        .recommand-now-qrcode-wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 6px 8px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          border-radius: 10px;
          border: 4px solid #000;
          background: #fff;
          margin-bottom: 20px;
          .qrcode-button-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            grid-gap: 12px;
            gap: 12px;
            .download-btn,
            .copy-btn {
              cursor: pointer;
            }
          }
        }
        .recommand-now-link-wrap {
          grid-gap: 12px;
          gap: 12px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          .recommend-now-code {
          }
          .recommand-now-link-title {
            margin-bottom: 4px;
            color: #6c5f4e;
            font-size: 14px;
            font-weight: 700;
          }
          .recommand-now-link-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            grid-gap: 8px;
            gap: 8px;
            .recommand-now-link-input-wrap {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              padding: 8px 8px 8px 12px;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              grid-gap: 12px;
              gap: 12px;
              align-self: stretch;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #fff;
              cursor: pointer;
              .recommand-now-link-input {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
                white-space: normal;
              }
              .copy-link-btn {
                width: 24px;
                height: 24px;
                border: none;
                outline: none;
                position: relative;
              }
              .copy-link-btn::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: url("@/assets/pc/referral/copy.svg") no-repeat 50%;
                background-size: 24px 24px;
              }
            }
          }
        }
      }
    }
  }
  .rebateCombin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    > div {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .leaderboard {
      margin-top: 15px;
      background: url("https://download.ocms.cloud/v3/ps8/3.91.6/memDesktop/img/rankBg_ada0e00.webp")
        no-repeat 100% 100%/100% #fee7b1;
      height: 324px;
      border-radius: 12px;
      h4 {
        font-weight: 700;
        color: #cc6300;
        text-align: center;
        font-size: 24px;
        padding: 15px 0 24px;
      }
      .board {
        margin: 0 auto;
        font-size: 16px;
        width: 80%;

        .item {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          width: 100%;
          background: #fff;
          text-align: center;
          margin-bottom: 2px;
          padding: 20px;
          min-height: 62px;
          grid-gap: 10px;
          gap: 10px;
          align-self: stretch;
          -webkit-box-shadow: 0 14px 24px 0 rgba(234, 146, 64, 0.6),
            0 4px 12px 0 rgba(204, 99, 0, 0.16);
          box-shadow: 0 14px 24px 0 rgba(234, 146, 64, 0.6),
            0 4px 12px 0 rgba(204, 99, 0, 0.16);
          .index {
            position: relative;
            width: 10%;
            img {
              position: absolute;
              top: -28px;
              left: -10px;
            }
          }
          .username {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 40%;
            color: #000;
            word-break: break-all;
          }
          .amount {
            width: 50%;
            color: #cc6300;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .item:first-child {
          border-radius: 12px 12px 0 0;
        }
      }
    }
    .received {
      position: relative;
      margin-top: 15px;
      margin-left: 10px;
      border-radius: 12px;
      border: 1px solid #8b7657;
      background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#b29469),
          to(#2a2a2a)
        ),
        -webkit-gradient(linear, left top, left bottom, from(#c5c5c5), to(#494949));
      background: linear-gradient(180deg, #b29469, #2a2a2a),
        linear-gradient(180deg, #c5c5c5, #494949);
      height: 324px;
      font-size: 16px;
      font-weight: 700;
      color: #ecd8a7;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      h4 {
        text-align: center;
        font-size: 24px;
        margin: 16px 0 24px;
      }
      :deep(.swiper) {
        height: 275px;
        width: 100%;
        .swiper-wrapper {
          height: 56px;
        }
        .swiper-slide {
          height: 56px;
          .item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            text-align: center;
            margin: 0 16px 16px;
            padding: 8px 12px;
            grid-gap: 16px;
            gap: 16px;
            align-self: stretch;
            background: hsla(0, 0%, 100%, 0.04);
            border-top: 1px solid #8b7657;
            border-radius: 100px;
            > div {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              white-space: normal;
            }
            .username {
              color: #fff;
              word-break: break-all;
              width: 20%;
            }
            .tag {
              color: #fff;
              width: 40%;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
            }
            .amount {
              width: 40%;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
            }
          }
        }
      }
      .bottom-mask {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 30%;
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(transparent),
          to(rgba(0, 0, 0, 0.8))
        );
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        z-index: 1;
      }
    }
  }
  .bonus-tab-container {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 15px;
    .el-row {
      flex-wrap: nowrap;
    }
    :deep(.el-table) {
      .el-table__inner-wrapper::before {
        height: 0;
      }
      thead {
        color: #909399;
        font-weight: 500;
      }
      th,
      tr {
        background-color: #fff;
      }
      tr {
        border-radius: 12px;
      }
      th {
        background-color: #f4f4f4;
        color: #6c5f4e;
        font-size: 16px;
        font-weight: 700;
      }
      .cell {
        word-break: break-word;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    :deep(.el-pagination) {
      display: flex;
      justify-content: center;
    }
    .tab-navbar {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0 auto 12px;
      border-radius: 100px;
      padding: 4px;
      border: 1.5px solid #e8e8e8;
      background-color: #f4f4f4;
      .nav-item {
        color: #6c5f4e;
        padding: 12px 20px;
        font-size: 18px;
        cursor: pointer;
      }
      .active-tab {
        color: #fff;
        border-radius: 100px;
        background-color: #ea9f13;
      }
    }
    .tab-promote {
      padding: 36px;
      border: 1px solid #695e4a;
      background: linear-gradient(178.2deg, #929292 -83.22%, #292929 48.15%);
    }
    .tab-content {
      border-radius: 12px;
    }
    .calculate-block {
      padding: 12px;
      .promote-title {
        font-size: 24px;
        color: #fff;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .promote-description {
        color: #959595;
        font-size: 18px;
        margin-top: 40px;
        .member-count {
          color: #ecd8a7;
        }
      }
      .promote-value {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        min-width: 250px;
        font-size: 32px;
        color: #ecd8a7;
        margin: 24px auto 0;
        padding: 10px 20px;
        border-radius: 8px;
        border: 1px solid #695e4a;
        background: #505050;
        -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
      }
      .promote-backup {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        margin: 40px auto;
        .el-icon-info {
          color: #959595;
          margin-right: 5px;
        }
      }
      .promote-slider {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .slider-runway {
          width: 94%;
          position: relative;
          vertical-align: middle;
          margin: 16px auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          overflow: hidden;
          padding: 2px;
          .slider-stop {
            padding: 6px 12px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            border-radius: 12px;
            color: #959595;
            background-color: #292929;
            outline: 1px solid #959595;
            z-index: 10;
            position: relative;
          }
          .target-stop {
            color: #292929;
            background-color: #ecd8a7;
            outline: 2px solid #ecd8a7;
            z-index: 9;
          }
          .target-stop::before {
            content: "";
            right: 100%;
            height: 2px;
            width: 100vw;
            background-color: #ecd8a7;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 1;
          }
        }
        .slider-runway:before {
          content: "";
          left: 1px;
          width: calc(100% - 2px);
          height: 2px;
          background-color: #959595;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
    .content-block:not(:first-child) {
      border-top: 1px solid #dedede;
    }
    .promote-info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-top: 40px;
      height: 350px;
      border-radius: 8px;
      border: 1px solid #695e4a;
      background: #505050;
      -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
      padding: 24px;
      .promote-image {
        height: 100%;
        img {
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }
      .promote-content {
        text-align: left;
        padding: 24px;
        .promote-title {
          font-size: 24px;
          color: #fff;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .promote-info-content {
          position: relative;
          margin-bottom: 20px;
          height: 160px;
          overflow: hidden;
          color: #bdbdbd;
          font-size: 16px;
          font-weight: 500;
          .v-html-default {
          }
          .bottom-mask {
            background: -webkit-gradient(
              linear,
              left top,
              left bottom,
              from(rgba(80, 80, 80, 0)),
              to(rgba(80, 80, 80, 0.9))
            );
            background: linear-gradient(
              rgba(80, 80, 80, 0),
              rgba(80, 80, 80, 0.9)
            );
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .more-btn {
          display: block;
          color: #959595;
          padding: 12px 20px;
          border-radius: 100px;
          border: 1px solid #dedede;
          background: #fff;
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          span {
          }
          .el-icon-back {
            color: #ea9f13;
            font-weight: 700;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            margin: 3px 0 0 5px;
          }
        }
      }
    }
    .tab-history {
      border-radius: 12px;
      border: 1px solid #dedede;
      background-color: #fff;
      text-align: left;
      .tab-navbar {
        margin: 12px;
        border: 1.5px solid #e8e8e8;
        background-color: #e8e8e8;
        .nav-item {
          padding: 12px;
          font-size: 14px;
        }
        .active-tab {
          color: #ea9f13;
          background-color: #fff;
        }
      }
      .tab-content {
        padding: 12px 0;
        .content-block {
          padding: 12px;
        }
        .history-title {
          font-size: 18px;
          color: #000;
          padding: 12px 0;
          position: relative;
        }
        .history-table {
          font-size: 16px;
          font-weight: 400;
          color: #000;
        }
        .search-date {
          position: absolute;
          right: 0;
          top: 0;
          .date-label {
            color: #6c5f4e;
            margin: 10px;
            font-size: 14px;
            display: inline-block;
          }
          :deep(.el-date-editor) {
            .el-ibput {
              width: 220px;
            }
          }
        }
      }
    }
  }
}
</style>