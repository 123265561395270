<template>
  <div class="promoUnlockPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="close-wrapper">
        <button type="button" class="dark-close" @click="close()"></button>
      </div>
      <div class="captcha-popup">
        <div class="remindinfo-block">
          <div class="title-block">
            <div class="popup-icon"></div>
            <h2 class="remindinfo-title"> {{t('Unlock')}}
            </h2>
          </div>
          <div class="remindinfo-bg">
            <div class="remindinfo-inner">
              <div class="FinalForm">
                <h4 class="promotion-title-message">
                  <p>{{t('Release')}} {{ tools.getCurLangValue(props.data.title) }} {{t('promotion?')}}</p>
                </h4>
                <div class="form-group">
                  <h4><span>{{t('Verification Code')}}</span></h4>
                  <div class="input-wrapper">
                    <input v-model="ruleForm.code" type="tel" class="form-input" @input="validateCode()">
                    <div class="input-addon checknum_img">
                      <img :src="ruleForm.codeImg" alt="">
                    </div>
                    <div v-show="codeRefErr" class="poperrorMsg">
                      {{t('Please enter a valid verification code.')}}
                    </div>
                  </div>
                </div>
                <div class="center-wrap">
                  <button type="button" class="confirm" @click="submit()">{{t('Confirm')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {
    id: '',
    title: ''
  }
})

const moneyRefErr = ref(false);
const codeRefErr = ref(false);
const ruleForm = reactive(
  {
    money: '',
    codeImg: '',
    codeId: '',
    code: '',
  }
)

getCode();

async function getCode() {
  let params = {

  };
  let rsp = await req.get(http.captcha, qs.stringify(params));
  log.info('getCode----', rsp)
  if (rsp.data.Code == 0) {
    ruleForm.codeImg = rsp.data.Data.img
    ruleForm.codeId = rsp.data.Data.id
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}


function submit() {
  validateCode();
  if (codeRefErr.value) {
    return;
  } else {
    let parms = {
      ActivityId: props.data.id,
      code: ruleForm.code,
      codeId: ruleForm.codeId,
    }
    log.info('parms----', parms)
    mqant.request(topic.userLeaveAct, parms, function (data, topicName, msg) {
      log.info('topic.userLeaveAct', data)
      if (data.Code == 0) {
        close();
        bus.emit(bus.event.promoNotice);
        ElMessage.success(data.ErrMsg);
      } else {
        getCode();
        ElMessage.error(data.ErrMsg);
      }
    })
  }
}


function validateCode() {
  if (!ruleForm.code || isNaN(Number(ruleForm.code, 10))) {
    codeRefErr.value = true;
  } else {
    codeRefErr.value = false;
  }
}

function close() {
  bus.emit(bus.event.promoUnlockPop, { bool: false, data: {} });
}
</script>

<style lang="scss" scoped>
.promoUnlockPop {
  position: relative;
  text-align: center;

  :deep(.el-dialog) {
    background: transparent;
    box-shadow: none;
    position: relative;
    margin: 0 auto 50px;
    width: 700px;
    max-width: 100%;
    border-radius: 2px;
    color: #606266;
    font-size: 14px;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #606266;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .close-wrapper {
        .dark-close {
          position: absolute;
          top: 80px;
          right: 40px;
          z-index: 10;
          padding: 0;
          border: 0;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          background: url("@/assets/pc/common/dark-close.png");
        }
      }

      .captcha-popup {
        .remindinfo-block {
          position: relative;
          padding-top: 45px;

          .title-block {
            background-color: #fff;
            background: #fff;
            padding: 20px;
            text-align: center;
            position: relative;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;

            .popup-icon {
              width: 90px;
              height: 90px;
              border-radius: 50%;
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              top: -45px;
              background: #ea9f13;
            }

            .popup-icon:before {
              background-image: url("@/assets/pc/common/checkcaptcha-icon.png");
              content: "";
              display: block;
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: 50%;
              filter: grayscale(100%) brightness(250%);
              width: 100%;
              height: 100%;
              position: absolute;
            }

            .remindinfo-title {
              color: #ea9f13;
              word-break: break-all;
              margin: 50px 0 0;
              word-wrap: break-word;
              font-size: 30px;
              font-weight: 500;
              line-height: 1.1;
            }
          }

          .remindinfo-bg {
            background-color: #fff;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            margin-top: -1px;
            padding-top: 1px;

            .remindinfo-inner {
              text-align: left;
              *zoom: 1;
              padding: 20px;

              .FinalForm {
                .promotion-title-message {
                  text-align: center;
                  margin-bottom: 30px;
                  font-size: 18px;
                  margin-top: 10px;
                  font-weight: 500;
                  line-height: 1.1;

                  p {
                    margin: 0 0 10px;
                  }
                }

                .form-group {
                  position: relative;
                  margin-bottom: 30px;
                  *zoom: 1;

                  h4 {
                    margin: 0;
                    color: #6c5f4e;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;

                    span {
                      position: relative;
                    }

                    span:after {
                      width: 5px;
                      height: 5px;
                      border-radius: 50%;
                      background: #c00;
                      -webkit-transform: translateY(-50%);
                      transform: translateY(-50%);
                      position: absolute;
                      top: 50%;
                      z-index: 1;
                      content: "";
                      display: inline-block;
                      right: -15px;
                    }
                  }

                  .el-select {
                    width: 100%;

                    .el-input__wrapper {
                      box-shadow: none;
                      border: none;
                      border-radius: 0;
                      border-bottom: 1px solid #ddd;

                      .el-input__inner {
                        height: 50px !important;
                        display: block;
                        width: 100%;
                        padding: 10px;
                        font-size: 16px;
                        line-height: 30px;
                        color: #616161;
                        background: #fff;

                        -webkit-box-shadow: none;
                        box-shadow: none;
                        -webkit-transition: none;
                        transition: none;
                        border-radius: 0;
                      }
                    }

                    .el-input--suffix .el-input__inner {
                      padding-right: 30px;
                    }
                  }

                  .input-wrapper {
                    position: relative;
                  }

                  .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding: 10px;
                    font-size: 16px;
                    line-height: 30px;
                    color: #616161;
                    background: #fff;
                    border: none;
                    border-bottom: 1px solid #ddd;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    -webkit-transition: none;
                    transition: none;
                    border-radius: 0;
                  }

                  .form-input[disabled] {
                    color: #aaa;
                    background: #f3f3f3;
                    border-radius: 5px;
                    border: 0 solid transparent;
                    opacity: 1;
                    -webkit-text-fill-color: #aaa;
                    cursor: not-allowed;
                  }

                  .form-input:focus {
                    color: #000;
                    border-color: #f7f7f7;
                    background-color: #f7f7f7;
                    outline: 0;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                  }

                  .checknum_img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom-right-radius: 5px;
                    border-top-right-radius: 5px;
                    position: absolute;
                    width: 30%;
                    height: 50px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: transparent;
                    white-space: nowrap;
                    vertical-align: middle;
                    text-align: center;
                    padding: 5px 10px;
                    text-align: center;
                    vertical-align: middle;

                    img {
                      height: 100%;
                    }
                  }

                  .poperrorMsg {
                    position: absolute;
                    z-index: 20;
                    bottom: 50px;
                    right: 0;
                    width: auto;
                    background: #c00;
                    color: #fff;
                    line-height: 20px;
                    padding: 5px 10px;
                    border-radius: 3px;
                    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
                    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
                  }

                  .poperrorMsg:before {
                    content: "";
                    font-family: glyphicon;
                    font-weight: 600;
                    margin-right: 5px;
                    top: 1px;
                    position: relative;
                  }

                  .poperrorMsg:after {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    margin: auto;
                    right: 10px;
                    bottom: -6px;
                    display: block;
                    border-color: #c00 transparent transparent;
                    border-style: solid;
                    border-width: 7px 7px 0;
                  }
                }

                .center-wrap {
                  text-align: center;
                  padding-bottom: 40px;

                  .confirm {
                    background-image: linear-gradient(
                      180deg,
                      #4d4d4d 0,
                      #343434
                    );
                    background-repeat: repeat-x;
                    box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
                    -webkit-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                    cursor: pointer;
                    display: inline-block;
                    width: auto;
                    height: auto;
                    padding: 10px 40px;
                    font-size: 18px;
                    color: #fff;
                    line-height: 30px;
                    letter-spacing: 1px;
                    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
                    border-radius: 50px;
                    border: 2px solid #343434;
                    margin: 0 5px;
                  }

                  .confirm:hover {
                    background-image: linear-gradient(
                      180deg,
                      #343434 0,
                      #4d4d4d
                    );
                    background-repeat: repeat-x;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>