// import { createLogger } from 'vue-logger-plugin'

let enabled = process.env.NODE_ENV != "production"
// create logger with options
// const log = createLogger({
//   enabled: enabled,
//   level: 'debug',
//   beforeHooks: [ ],
//   afterHooks: [ ],
//   prefixFormat: ({ level, caller }) => (
//     caller
//       ? `[${level.toUpperCase()}] [${caller?.fileName}:${caller?.functionName}:${caller?.lineNumber}]`
//       : `[${level.toUpperCase()}]`
//   ),
// })
const log = {
  info: function (...args) {
    if (enabled) {
      console.log(...args)
    }
  },
  warn: function (...args) {
    if (enabled) {
      console.log(...args)
    }
  },
  error: function (...args) {
    if (enabled) {
      console.error(...args)
    }
  },
  log: function (...args) {
    if (enabled) {
      console.log(...args)
    }
  },
  debug: function (...args) {
    if (enabled) {
      console.log(...args)
    }
  }
}

export default log