<template>
  <div class="memberTitle">
    <div class="top-title">
      <h3>{{ props.title }}</h3>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const props = defineProps({
  title: ''
})
</script>

<style lang="scss" scoped>
.memberTitle {
  position: relative;

  .top-title {
    text-align: center;
    position: relative;
    height: 80px;
    margin-bottom: 10px;

    h3 {
      width: auto;
      height: 80px;
      display: inline-block;
      background: #fff;
      margin: 0;
      padding: 0 20px;
      position: relative;
      color: #472c59;
      line-height: 80px;
      letter-spacing: 1px;
      font-size: 24px;
      font-weight: 500;
    }

    h3:before {
      left: -10px;
    }

    h3:after {
      right: -10px;
    }

    h3:before,
    h3:after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #8792ff;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      z-index: 1;
      display: inline-block;
    }
  }

  .top-title:before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    position: relative;
    top: 51.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-bottom: 2px solid #f7f7f7;
  }
}
</style>