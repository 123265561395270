<template>
  <div class="regulationPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="close-wrapper">
        <button type="button" class="dark-close" @click="close()"></button>
      </div>
      <div class="regulation-popup">
        <div class="regulation-bg">
          <div class="regulation-inner">
            <h2 class="regulation-title">{{ tools.getCurLangValue(props.data.title) }}</h2>
            <div class="agreerule-content">
              <div v-if="props.data.data" v-html="tools.getCurLangValue(props.data.data.content)"></div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {
    title: ''
  }
})
function close() {
  bus.emit(bus.event.comInfoPop, { bool: false, data: {} });
}
</script>

<style lang="scss" scoped>
.regulationPop {
  position: relative;
  text-align: center;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    border-radius: 2px;
    position: relative;
    margin: 0 auto 50px;
    width: 780px;
    max-width: 100%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #606266;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .close-wrapper {
        .dark-close {
          position: absolute;
          top: 30px;
          right: 40px;
          z-index: 10;
          padding: 0;
          border: 0;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          background: url("@/assets/pc/common/dark-close.png");
        }
      }

      .regulation-popup {
        .regulation-bg {
          border-radius: 10px;
          padding: 10px;
          background-image: linear-gradient(-2deg, #f3f5f6 0%, #ffff 100%),
            linear-gradient(#ffffff, #ffffff);

          .regulation-inner {
            border-radius: 10px;
            padding: 10px;
            background: #ececec;
            word-break: break-word;

            .regulation-title {
              text-align: center;
              border-bottom: 1px solid #b4b4b4;
              padding: 50px 0 30px;
              margin-top: 0;
              margin-bottom: 20px;
              color: #333;
              font-weight: 700;
              font-size: 30px;
            }

            .agreerule-content {
            }
          }
        }
      }
    }
  }
}
</style>