<template>
  <div class="bankSelectPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="close-wrapper">
        <button type="button" class="dark-close" @click="close()"></button>
      </div>
      <div class="remindinfo-block">
        <div class="title-block">
          <div class="popup-icon"></div>
          <h2 class="remindinfo-title">{{ t("Member Account Type") }}</h2>
        </div>
        <div class="remindinfo-bg">
          <div class="remindinfo-inner">
            <div class="bank-root">
              <div v-if="bankList.length > 0" class="bank-root">
                <h4>{{ t("Bank") }}</h4>
                <div class="list">
                  <div v-for="(item, index) in bankList" class="bankItem">
                    <div class="bank-list" @click="selecChange(item)">
                      <input type="radio" @change="selecChange(item)" />
                      <span>{{ item.BtName }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="walletList.length > 0" class="bank-root">
                <h4>{{ t("Wallet") }}</h4>
                <div class="list">
                  <div v-for="(item, index) in walletList" class="bankItem">
                    <div class="bank-list" @click="selecChange(item)">
                      <input type="radio" @change="selecChange(item)" />
                      <span>{{ item.BtName }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const show = ref(true);
const props = defineProps({
  data: {
    list: [],
  },
});
const bankList = ref(getListByType("bank"));
const walletList = ref(getListByType("ebank"));

function getListByType(type) {
  let arr = [];
  for (let key in props.data.list) {
    if (props.data.list[key].BtType == type) {
      arr.push(props.data.list[key]);
    }
  }
  return arr;
}

function selecChange(item) {
  bus.emit(bus.event.getBankSelectInfo, item);
  close();
}

function close() {
  bus.emit(bus.event.bankSelectPop, { bool: false, data: {} });
}
</script>

<style lang="scss" scoped>
.bankSelectPop {
  position: relative;
  text-align: center;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    border-radius: 2px;
    position: relative;
    margin: 0 auto 50px;
    width: 900px;
    max-width: 90%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #606266;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .close-wrapper {
        .dark-close {
          position: absolute;
          top: 80px;
          right: 40px;
          z-index: 10;
          padding: 0;
          border: 0;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          background: url("@/assets/pc/common/dark-close.png");
        }
      }

      .remindinfo-block {
        position: relative;
        padding-top: 45px;

        .title-block {
          background: #fff;
          padding: 20px;
          text-align: center;
          position: relative;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;

          .popup-icon {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -45px;
            background: #2bb;
          }

          .popup-icon:before {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 50%;
            filter: grayscale(100%) brightness(250%);
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: url("@/assets/pc/pop/bankselect-icon.png");
          }

          .remindinfo-title {
            word-break: break-all;
            margin: 50px 0 0;
            word-wrap: break-word;
            font-size: 30px;
            font-weight: 500;
          }
        }

        .remindinfo-bg {
          background-color: #fff;
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
          margin-top: -1px;
          padding-top: 1px;

          .remindinfo-inner {
            text-align: left;
            font-size: 16px;
            padding: 20px;

            h4 {
              color: rgba(96, 98, 102, 0.8);
              font-size: 18px;
              margin-top: 10px;
              margin-bottom: 10px;
              font-weight: 500;
              line-height: 1.1;
            }

            .bank-root {
              position: relative;

              .list {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }

  .bankItem {
    margin-bottom: 10px;
    width: calc(100% / 3);

    .bank-list {
      word-break: break-all;

      input[type="checkbox"],
      input[type="radio"] {
        line-height: normal;
        zoom: 1.5;
        vertical-align: top;
        margin-top: 2px;
        margin-right: 3px;
      }

      cursor: pointer;
    }
  }
}

@media (max-width: 1000px) {
  .bankSelectPop {
    .bankItem {
      width: calc(100% / 1) !important;
    }
  }
}
</style>
