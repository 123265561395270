<template>
  <div class="promoInfoList">
    <MemberTitle :title="t('Promotion List')" />
    <div class="memberform-fuild">
      <!-- <div class="tips-box">
        {{t('Reminder: Currently, turnover limit needs 20 minutes to complete update.')}}
      </div> -->
      <div class="promotionList">
        <div v-if="recordObj.allList.length <= 0" class="nopromo">{{t('No Record')}}</div>
        <ul v-else>
          <li v-for="(item, index) in recordObj.list">
            <div class="list-content">
              <div class="bonus">
                <h2>
                  <span>{{t('Bonus Amount')}}</span>
                  {{ tools.formatNum2k(item.Award) }}
                </h2>
              </div>
              <div class="detail">
                <h3>{{ item.ActivityName }}</h3>
                <dl>
                  <dt>{{t('Current Turnover Limit')}}</dt>
                  <dd>{{ getDesByData(item) }} </dd>
                </dl>
                <ul class="time-block">
                  <li v-if="Object.keys(item.BindGames).length > 0">
                    <span>{{t('Platform')}}</span>{{
                                        getPromotionFactory(item)
                                    }}</li>
                  <li v-if="item.MaxAwardLimit > 0">
                    <span>{{t('Maximum bonus')}}</span>
                    {{tools.formatNum2k(item.MaxAwardLimit)}}</li>
                  <li><span>{{t('Joined Time')}}</span>{{ tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
                    {{ tools.formatDate(item.CreateAt, "hh:mm") }}</li>
                  <li v-if="item.Status == 'ForceCancel' || item.Status == 'Canceled' || item.Status == 'Received'">
                    <span>{{t('End Time')}}</span>{{ tools.formatDate(item.UpdateAt, "yyyy-MM-dd") }}
                    {{ tools.formatDate(item.UpdateAt, "hh:mm") }}
                  </li>
                  <li v-else>
                    <span>{{t('End Time')}}</span>{{t('In progress')}}
                  </li>
                </ul>
                <div class="status-box">
                  <div v-if="item.Status == 'Received'" class="status">{{t('Status【 Complete 】')}}</div>
                  <div v-else-if="item.Status == 'Canceled'" class="status">{{t('Status【 Complete 】')}}</div>
                  <div v-else-if="item.Status == 'Doing'" class="status">{{t('Status【 Locked 】')}}</div>
                  <button v-if="item.Status == 'Doing'" class="release-btn" @click="toUnlockPromoPop(item)">{{t('Unlock')}}</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
        <el-pagination background layout="prev, pager, next" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet",]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const recordObj = reactive({
  allList: [],
  list: [],
  type: -1,
})

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 5,
})

if (isLogged.value) {
  getRecord()
}
bus.on('onConnected', () => {
  getRecord();
})
bus.on(bus.event.promoNotice, () => {
  getRecord();
})

function getDesByData(item) {
  if (item.Status !== "Doing") {
    return "";
  }

  let str0 = tools.stringFormat(t("Bonus amount is [0]."), [
    tools.formatNum2k(item.Award),
  ]);
  let [str1, str2, str3, str4] = ["", "", "", ""]; //流水//输赢//钱包//余额少于
  if (item.NeedBet.betType > 0) {
    str1 = tools.stringFormat(
      t(
        "Wager requirement to leave promotion is [0], your current wager is [1]."
      ),
      [
        tools.formatNum2k(item.NeedBet.betValue),
        tools.formatNum2k(item.SumFlow),
      ]
    );
  }
  if (item.NeedWinLose > 0) {
    str2 = tools.stringFormat(
      t(
        "The required win or loss amount reaches [0]. The current win or loss amount is [1]."
      ),
      [
        tools.formatNum2k(item.NeedWinLose),
        tools.formatNum2k(item.SumIncome),
      ]
    );
  }
  if (item.NeedBalance > 0) {
    str3 = tools.stringFormat(
      t(
        "The activity wallet needs to reach [0]. The current activity wallet is [1]."
      ),
      [
        tools.formatNum2k(item.NeedBalance),
        tools.formatNum2k(wallet.value.BonusBalance),
      ]
    );
  }
  if (item.CancelBalance > 0) {
    str2 = tools.stringFormat(t("Promotion balance must be less than [0]."), [
      tools.formatNum2k(item.CancelBalance),
    ]);
  }
  return str0 + str1 + str2 + str3 + str4;
}

function getPromotionFactory(item) {
  let str = '';
  for (let key in item.BindGames) {
    str += tools.getFactoryName(key) + ','
  }
  return str.slice(0, -1);
}

function getRecord() {
  let parms = {
  }
  mqant.request(topic.queryUserJoinedActs, parms, function (data, topicName, msg) {
    log.info('queryUserJoinedActs--', data.Data)
    if (data.Code == 0) {
      recordObj.allList = data.Data.JoinActs;
      //记录总数
      paginationObj.total = recordObj.allList.length;
      //分页截取
      sliceArr();
    } else {
    }
  });
}


function toUnlockPromoPop(item) {
  // let unLockActiveId = '';
  // for (let key in item.activityId) {
  //     let id = item.activityId[key];
  //     for (let key1 in JoinActs) {
  //         let info = JoinActs[key1];
  //         if (id == info.ActivityId && info.Status == 'Doing') {
  //             unLockActiveId = id;
  //             break;
  //         }
  //     }
  // }
  bus.emit(bus.event.promoUnlockPop, { bool: true, data: { id: item.ActivityId, title: item.title } })
}

const handleSizeChange = (val) => {
  sliceArr();
}
const handleCurrentChange = (val) => {
  sliceArr();
}
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}
</script>

<style lang="scss" scoped>
.promoInfoList {
  position: relative;
  color: #0f161f;

  .memberform-fuild {
    padding: 0 40px 100px;

    .tips-box {
      border-radius: 5px;
      background: #f7f7f7;
      border: 1px solid #f7f7f7;
      padding: 20px 20px 20px 50px;
      position: relative;
      color: #642;
      margin-bottom: 30px;
    }

    .tips-box::before {
      content: "";
      left: 20px;
      top: 20px;
      position: absolute;
      z-index: 10;
      font-family: glyphicon;
      font-size: 18px;
      font-weight: 600;
    }

    .promotionList {
      .nopromo {
        margin-bottom: 30px;
        border-radius: 5px;
        background: #fff;
        color: #0f161f;
        border: 1px solid #dbe0e4;
        padding: 100px 10px;
        text-align: center;
      }

      > ul {
        > li {
          margin-bottom: 30px;
          *zoom: 1;
          background: #fff;

          .list-content {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            *zoom: 1;
            border: 1px solid #ddd;
            position: relative;
          }

          .bonus {
            background: 50% / cover no-repeat;
            background-image: url("@/assets/pc/common/reward_bg.png");

            margin-top: 30px;
            padding-top: 70px;
            width: 250px;
            height: 230px;
            position: relative;
            float: right;

            h2 {
              margin: 0;
              color: #fff;
              font-size: 60px;
              font-weight: 600;
              text-shadow: 0 0 10px rgba(0, 0, 0, 0.5),
                0 0 10px rgba(0, 0, 0, 0.5), 0 0 10px rgba(0, 0, 0, 0.5),
                0 0 10px rgba(0, 0, 0, 0.5);
              text-align: center;

              span {
                display: none;
              }
            }
          }

          .detail {
            padding: 20px 250px 20px 20px;

            h3 {
              border-bottom: 1px dotted #ccc;
              margin: 0;
              padding-bottom: 10px;
              color: #c00;
              font-weight: 600;
              font-size: 24px;
            }

            dl {
              margin: 0;
              padding: 15px 10px;

              dt {
                font-weight: 700;
              }

              dd,
              dt {
                margin-bottom: 5px;
                line-height: 1.428571429;
              }
            }

            ul {
              li {
                margin-bottom: 20px;
                word-wrap: break-word;

                span {
                  display: inline-block;
                  border-radius: 50px;
                  background: #f1f1f1;
                  margin-right: 10px;
                  padding: 5px 10px;
                  width: 150px;
                  text-align: center;
                }
              }
            }

            .status-box {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              width: 100%;

              .status {
                display: table;
                border-radius: 50px;
                background: #000;
                margin: 0 auto;
                padding: 0 40px;
                width: auto;
                color: #fff;
                line-height: 40px;
                display: block !important;
                margin: 0 20px 0 0 !important;
              }

              .release-btn {
                padding: 5px 30px;
                font-size: 18px;
                color: #fff;
                background: #ea9f13;
                border-radius: 3px;
                border: 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .pagination-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;

      :deep(.el-pagination) {
        .is-active {
          background-color: #6c5f4e !important;
        }
      }
    }
  }
}
</style>