<template>
  <div class="promotion">
    <div class="promo-menu">
      <div class="mob_view menu-fluid">
        <swiper slides-per-view="auto">
          <swiper-slide class="platform-menu-nav" :class="index == navObj.active ? 'active' : ''" v-for="(item, index) in navObj.list" :key="item" @click="itemClick(item, index)">
            <a>
              <span>{{ tools.getCurLangValue(item.name) }}</span>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="container-fluid">
      <ul class="promo-list">
        <li v-for="(item, index) in navObj.list[navObj.active].list">
          <div class="promo-list-block">
            <ul class="promo-grid" @click="toInfo(item)">
              <li>
                <div class="promo-banner">
                  <a>
                    <img :src="getBannerImg(item)" lazy="loaded">
                  </a>
                </div>
              </li>
              <li>
                <div class="promo-content">
                  <h2 class="promo-title">
                    <a>{{ tools.getCurLangValue(item.title) }}</a>
                  </h2>
                  <div class="promo-detail">
                    <p><b>{{t('Promotion Tag')}}</b><span>{{ getPromotionTag(item) }}</span></p>
                    <p v-if="item.factoryList.length > 0">
                      <b>{{t('Promotion Platform')}}</b>
                      <span>{{getPromotionFactory(item) }}</span></p>
                    <p><b>{{t('Promotional Period')}}</b>
                      <span>{{ item.openTime[0] }} ~ {{ item.openTime[1]}}</span></p>
                  </div>
                </div>
              </li>
            </ul>
            <div class="promo-btn" @click="toInfo(item)">
              <a>{{t('MORE')}}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import { isArray } from "xe-utils";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const curType = 'Activity';
const curTag = "activityArtical";
const curTypeObj = tools.deepClone(lobbyInfo.value.MainPageConf.find(info => info._id == curType)).Labels;
const curTypeLabelList = getLabelList(curTypeObj);

const navObj = reactive({
  active: 0,
  list: [
    { name: t('All Promotions'), list: getAllList() },
  ],
})
//设置分类的数据
for (let key in curTypeLabelList) {
  navObj.list.push(
    { name: tools.getCurLangValue(curTypeLabelList[key].Text), list: getListByFlag(curTypeLabelList[key].Navigate) }
  )
}

log.info('allList----', navObj.list);


watch(() => {
  curRouter.path, toPromotionPopById();
})

function toPromotionPopById(id) {
  if (curRouter.path == '/promotion') {
    if (curRouter.params && curRouter.params.id) {
      if (navObj.list[0].list && navObj.list[0].list.length > 0) {
        for (let key in navObj.list[0].list) {
          let info = navObj.list[0].list[key];
          if (info._id == curRouter.params.id) {
            toInfo(info);
            return;
          }
        }
      }
    }
  }

}

function getListByFlag(flag) {
  let arr = [];
  for (let key in navObj.list[0].list) {
    let info = navObj.list[0].list[key];
    for (let value of info.tag) {
      if (flag == value) {
        arr.push(info);
      }
    }
  }
  return arr;
}

function getLabelList(list) {
  let arr = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i].Status == 1) {
      arr.push(list[i]);
    }
  }
  return arr;
}
function getAllList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key];
    if (info.type == curTag && info.status) {
      let nowTime = Date.now();
      let startTime = Date.parse(info.openTime[0].replace(/-/g, '/'));
      let endTime = Date.parse(info.openTime[1].replace(/-/g, '/'));
      if (nowTime > startTime && nowTime < endTime) {
        arr.push(info);
      }

    }
  }
  arr.sort((a, b) => {
    return Number(a.sort) - Number(b.sort);
  })
  return arr;
}

function getBannerImg(item) {
  if (tools.isMobile()) {
    return comApi.getNormalImg(tools.getCurLangValue(item.iconMobile));
  } else {
    return comApi.getNormalImg(tools.getCurLangValue(item.icon));
  }
}

function getPromotionTag(item) {
  let str = '';
  for (let value of item.tag) {
    for (let key in curTypeLabelList) {
      let info = curTypeLabelList[key];
      if (info.Navigate == value) {
        str += tools.getCurLangValue(info.Text) + ','
      }
    }
  }
  return str.slice(0, -1);
}

function getPromotionFactory(item) {
  let str = '';
  for (let value of item.factoryList) {
    str += tools.getFactoryName(value) + ','
  }
  return str.slice(0, -1);
}


function itemClick(item, index) {
  navObj.active = index;
}

function toInfo(item) {
  bus.emit(bus.event.promotionPop, { bool: true, data: item })
}

</script>

<style lang="scss" scoped>
.promotion {
  position: relative;
  margin-top: 0 !important;
  background-color: #f3f5f6;
  .promo-menu {
    padding: 0;
    background: #f4f4fe;
    width: 100%;
    position: relative;

    .menu-fluid {
      .swiper {
        .swiper-slide {
          width: auto;

          a {
            display: block;
            padding: 0 10px;
            position: relative;
            line-height: 50px;
            color: #ccc;
          }

          a::before {
            content: "";
            width: 100%;
            height: 3px;
            background: #ffc107;
            position: absolute;
            left: 0;
            bottom: 0;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: -webkit-transform 0.3s ease;
            transition: -webkit-transform 0.3s ease;
            transition: transform 0.3s ease;
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
          }
        }

        .active {
          a {
            outline: none;
            background: #fe725d;
            color: #fff;
            text-decoration: none;
          }

          a::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  .container-fluid {
    *zoom: 1;
    padding-right: 10px;
    padding-left: 10px;

    .promo-list {
      min-height: 200px;
      display: block;
      margin: 0 -10px;
      padding: 0;

      > li {
        .promo-list-block {
          border-radius: 0;
          border-bottom: 1px solid rgba(80, 75, 158, 0.5);
          -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.5);
          padding: 10px;
          width: 100%;

          background-image: linear-gradient(-2deg, #fff 4%, #fff 100%),
            linear-gradient(#fff, #fff);
          border-radius: 5px;
          position: relative;
          overflow: hidden;
          -webkit-transition: all 0.4s linear;
          transition: all 0.4s linear;

          .promo-grid {
            li {
              width: 100%;
              position: relative;

              .promo-banner {
                padding-bottom: 50%;
                width: 100%;
                height: 0;
                position: relative;

                img {
                  border: 1px solid #888;
                  border-radius: 10px;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  margin: auto;
                  left: 0;
                  right: 0;
                }
              }

              .promo-content {
                padding: 10px 0 0;

                .promo-title {
                  margin-bottom: 10px;
                  font-size: 18px;
                  height: auto;
                  max-height: 50px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  line-height: 25px;
                  overflow: hidden;

                  a {
                    color: #0f161f;
                  }
                }

                .promo-detail {
                  width: 70%;
                  min-height: 40px;

                  p {
                    word-break: break-all;
                    display: block !important;
                    font-size: 12px;
                    margin-bottom: 0;

                    b {
                      color: #0f161f;
                      font-weight: 700;
                    }

                    b:after {
                      content: "：";
                    }

                    span {
                      color: #0f161f;
                    }
                  }
                }
              }
            }
          }

          .promo-btn {
            width: calc(30% - 10px);
            right: 10px;
            bottom: 10px;
            margin-right: 0;
            overflow: hidden;
            position: absolute;
            z-index: 10;

            a {
              display: block;
              background-image: linear-gradient(0deg, #0051d6 4%, #0051d6 50%),
                linear-gradient(#000000, #000000);
              box-shadow: inset 0 0 15px 0 rgba(114, 250, 250, 0.2);
              border-radius: 3px;
              padding: 5px 10px;
              color: #fff;
              font-weight: 600;
              line-height: 25px;
              text-align: center;
              text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
              text-decoration: none;
            }
          }
        }
      }

      > li:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>