<template>
  <div class="mailBoxNew">
    <div class="memberform-fuild">
      <div class="message_news">
        <div class="news-list">
          <div v-if="recordObj.allList.length <=0" class="no-data" style="text-align: center">
            {{ t("No Record") }}
          </div>
          <ul v-else>
            <li v-for="(item,index) in recordObj.list">
              <div class="time"> {{ tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}</div>
              <p class="text"> {{tools.getCurLangValue(item.content)}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="recordObj.allList.length  > 0" class="pagination-wrapper">
        <el-pagination background layout="prev, pager, next" :pager-count="5" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);

const recordObj = reactive({
  allList: [],
  list: [],
});

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 5,
  total: 0
});


recordObj.allList = comApi.getHorseLampList();
//记录总数
paginationObj.total = recordObj.allList.length;
//分页截取
sliceArr();


const handleSizeChange = (val) => {
  sliceArr();
};
const handleCurrentChange = (val) => {
  sliceArr();
};

function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
}
</script>

<style lang="scss" scoped>
.mailBoxNew {
  position: relative;
  color: #0f161f;
  .memberform-fuild {
    border-radius: 10px;
    padding: 20px;
    background: #fff;

    .message_news {
      .news-list {
        margin-bottom: 20px;

        .no-data {
          min-height: 60vh;
          line-height: 60vh;
          text-align: center;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          *zoom: 1;
          li {
            border-bottom: 1px dotted #bfc7fe;
            margin-bottom: 10px;
            padding: 0 0 10px 30px;
            position: relative;
            .time {
              border-right: none;
              width: 100%;
              left: 0;
              position: relative;
              z-index: 10;
              color: #642;
            }
            .text {
              word-wrap: break-word;
            }
          }
          li:last-child {
            margin-bottom: 0;
          }
          li:before {
            content: "";
            left: 0px;
            top: 0px;
            position: absolute;
            z-index: 10;
            color: #642;
            font-family: glyphicon;
          }
        }
      }
    }
    .pagination-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      position: relative;

      :deep(.el-pagination) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        .is-active {
          background-color: #6c5f4e !important;
        }
      }
    }
  }
}
</style>
