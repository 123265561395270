<template>
  <div class="pop">
    <!-- 普通类 -->
    <ResgisterPop v-if="obj.resgisterPop" />
    <LoginPop v-if="obj.loginPop" />
    <LoginRegisterPop v-if="obj.loginRegisterPop.bool" :type="obj.loginRegisterPop.type" />
    <WalletPop v-if="obj.walletPop" />

    <!-- 公告 -->
    <Notice v-if="obj.noticeIsOpen && isLogged && obj.noticePop" />

    <!-- 活动类 -->
    <PromotionPop v-if="obj.promotionPop.bool" :data="obj.promotionPop.data" />
    <PromoApplyPop v-if="obj.promoApplyPop.bool" :data="obj.promoApplyPop.data" />
    <PromoUnlockPop v-if="obj.promoUnlockPop.bool" :data="obj.promoUnlockPop.data" />
    <!-- 系统弹窗 -->
    <SystemInfoPop v-if="obj.systemInfoPop.bool" :data="obj.systemInfoPop.data" />
    <SystemMailPop v-if="obj.systemMailPop.bool" :data="obj.systemMailPop.data" />
    <BankSelectPop v-if="obj.bankSelectPop.bool" :data="obj.bankSelectPop.data" />
    <MobileVerifePop v-if="obj.mobileVerifePop" />
    <!-- 通用提示 -->
    <ComInfoPop v-if="obj.comInfoPop.bool" :data="obj.comInfoPop.data" />
    <CommonTipPop v-if="obj.commonTipPop.bool" :data="obj.commonTipPop.data" />

    <!-- 移动端弹窗 -->
    <IosDownPop v-if="obj.iosDownPop" />
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { theme } from "@/views/theme";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import LoginRegisterPop from "@/views/common/components/pop/LoginRegisterPop.vue";
import LoginPop from "@/views/common/components/pop/LoginPop.vue";
import ResgisterPop from "@/views/common/components/pop/ResgisterPop.vue";
import ComInfoPop from "@/views/common/components/pop/ComInfoPop.vue";
import CommonTipPop from "@/views/common/components/pop/CommonTipPop.vue";
import PromotionPop from "@/views/common/components/pop/PromotionPop.vue";
import PromoApplyPop from "@/views/common/components/pop/PromoApplyPop.vue";
import PromoUnlockPop from "@/views/common/components/pop/PromoUnlockPop.vue";
import SystemInfoPop from "@/views/common/components/pop/SystemInfoPop.vue";
import SystemMailPop from "@/views/common/components/pop/SystemMailPop.vue";
import BankSelectPop from "@/views/common/components/pop/BankSelectPop.vue";
import WalletPop from "@/views/common/components/pop/WalletPop.vue";
import MobileVerifePop from "@/views/common/components/pop/MobileVerifePop.vue";
import IosDownPop from "@/views/common/components/pop/IosDownPop.vue";

let Notice = require("@/views/" + theme + "/components/Notice.vue").default;

const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const obj = reactive({
  resgisterPop: false,
  loginPop: false,
  noticePop: false,
  noticeIsOpen: noticeIsOpenFunc(),
  walletPop: false,
  mobileVerifePop: false,
  iosDownPop: false,
  loginRegisterPop: {
    bool: false,
    type: 0
  },
  commonTipPop: {
    bool: false,
    data: {
      title: "",
    },
  },
  promotionPop: {
    bool: false,
    data: {
      title: "",
    },
  },
  promoApplyPop: {
    bool: false,
    data: {
      title: "",
    },
  },
  promoUnlockPop: {
    bool: false,
    data: {
      title: "",
    },
  },
  comInfoPop: {
    bool: false,
    data: {
      title: "",
    },
  },
  systemInfoPop: {
    bool: false,
    data: {
      title: "",
    },
  },

  systemMailPop: {
    bool: false,
    data: {
      title: "",
    },
  },

  bankSelectPop: {
    bool: false,
    data: {
      title: "",
    },
  },
});

function noticeIsOpenFunc() {
  for (let key in lobbyInfo.value.LobbyNotice) {
    if (lobbyInfo.value.LobbyNotice[key].status == 1) {
      return true;
    }
  }
  return false;
}


bus.on(bus.event.resgisterPop, (bool) => {
  //第二种登录注册，registerMethod =2
  if (lobbyInfo.value.registerMethod == 2) {
    obj.loginRegisterPop.bool = bool;
    obj.loginRegisterPop.type = 1;
  } else {
    obj.resgisterPop = bool;
  }
});

bus.on(bus.event.loginState, (bool) => {
  //第二种登录注册，registerMethod =2
  if (lobbyInfo.value.registerMethod == 2) {
    obj.loginRegisterPop.bool = bool;
    obj.loginRegisterPop.type = 0;
  } else {
    obj.loginPop = bool;
  }
});

bus.on(bus.event.noticeState, (bool) => {
  obj.noticePop = bool;
});

bus.on(bus.event.walletPop, (bool) => {
  obj.walletPop = bool;
});

bus.on(bus.event.mobileVerifePop, (bool) => {
  obj.mobileVerifePop = bool;
});

bus.on(bus.event.iosDownPop, (bool) => {
  obj.iosDownPop = bool;
});


bus.on(bus.event.comInfoPop, (data) => {
  obj.comInfoPop.bool = data.bool;
  obj.comInfoPop.data = data.data;
});

bus.on(bus.event.promotionPop, (data) => {
  obj.promotionPop.bool = data.bool;
  obj.promotionPop.data = data.data;
});

bus.on(bus.event.promoApplyPop, (data) => {
  obj.promoApplyPop.bool = data.bool;
  obj.promoApplyPop.data = data.data;
});
bus.on(bus.event.promoUnlockPop, (data) => {
  obj.promoUnlockPop.bool = data.bool;
  obj.promoUnlockPop.data = data.data;
});

bus.on(bus.event.commonTipPop, (data) => {
  obj.commonTipPop.bool = data.bool;
  obj.commonTipPop.data = data.data;
});

bus.on(bus.event.systemInfoPop, (data) => {
  obj.systemInfoPop.bool = data.bool;
  obj.systemInfoPop.data = data.data;
});

bus.on(bus.event.systemMailPop, (data) => {
  obj.systemMailPop.bool = data.bool;
  obj.systemMailPop.data = data.data;
});

bus.on(bus.event.bankSelectPop, (data) => {
  obj.bankSelectPop.bool = data.bool;
  (obj.bankSelectPop.data = data.data);
});
</script>

<style lang="scss" scoped>
.pop {
  position: relative;
  z-index: 1000;
}
</style>
