<template>
  <div class="vip">
    <div class="x-cover" :style="{'background-image':`url('${comApi.getPromoImg('VIP')}')`}">
    </div>
    <div class="container-fluid">
      <div class="article-page">
        <ul class="article-list">
          <li v-for="(item, index) in navObj.list" @click="itemClick(item)">
            <div class="article-list-block">
              <div class="article-grid">
          <li class="article-banner-container">
            <div class="article-banner">
              <img :src="getBannerImg(item)" lazy="loaded" />
            </div>
          </li>
          <li class="article-content-container">
            <div class="article-content">
              <h2 class="article-title">
                <a>{{ tools.getCurLangValue(item.title) }}</a>
              </h2>
              <div class="article-detail">
                <div class="v-html-default article-content overflowContent blockContent" v-html="tools.getCurLangValue(item.content)"></div>
              </div>
            </div>
          </li>
      </div>
    </div>
    </li>
    </ul>
  </div>
  </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch
} from "vue";

import {
  useGetters,
  useActions,
  useMutations
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);

const curType = "artical";
const navObj = reactive({
  active: 0,
  list: getAllList()
})

function getAllList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key];
    if (info.type == curType && info.status) {
      arr.push(info);
    }
  }
  arr.sort((a, b) => {
    return Number(a.sort) - Number(b.sort);
  })
  return arr;
}

function getBannerImg(item) {
  if (tools.isMobile()) {
    return comApi.getNormalImg(tools.getCurLangValue(item.icon));
  } else {
    return comApi.getNormalImg(tools.getCurLangValue(item.icon));
  }
}

function itemClick(item) {
  router.push({
    path: '/vipDetail',
    query: {
      id: item._id
    }
  });
}
</script>

<style lang="scss" scoped>
.vip {
  position: relative;
  .x-cover {
    background-image: url("@/assets/pc/sport/sport-cover-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 400px;
    overflow: hidden;
    z-index: 1;
  }

  .container-fluid {
    padding-right: 80px;
    padding-left: 80px;

    .article-page {
      .article-list {
        padding: 50px 0;

        li {
          cursor: pointer;
          display: list-item;

          .article-list-block {
            background-image: linear-gradient(-2deg, #fff 4%, #fff 100%),
              linear-gradient(#ffffff, #ffffff);
            border-radius: 5px;
            padding: 20px;
            position: relative;
            overflow: hidden;
            -webkit-transition: all 0.4s linear;
            transition: all 0.4s linear;
            height: 200px;

            .article-grid {
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -ms-flex-flow: row nowrap;
              flex-flow: row nowrap;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              -ms-flex-line-pack: stretch;
              align-content: stretch;
              padding: 0;
              overflow: hidden;

              .article-banner-container {
                display: flex;
                position: relative;
                height: 150px;

                .article-banner {
                  width: 100%;
                  position: relative;

                  img {
                    height: 100%;
                    max-width: 100%;
                    position: absolute;
                    left: -100%;
                    right: -100%;
                    margin: auto;
                  }
                }
              }

              li:first-child {
                width: 20%;
                margin-right: 30px;
              }

              li:last-child {
                width: 80%;
              }

              .article-content-container {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                position: relative;
                height: 150px;

                .article-content {
                  padding: 0 0 50px 20px;

                  .article-title {
                    margin: 0 0 20px;

                    a {
                      font-size: 26px;
                      line-height: 130%;
                      font-family: inherit;
                      font-weight: 500;
                      color: #0f161f;
                    }
                  }

                  .article-detail {
                    color: #0f161f;
                    overflow: hidden;
                  }

                  .overflowContent {
                    overflow-x: auto;
                  }
                }
              }
            }
          }

          .article-list-block:hover {
            background: linear-gradient(180deg, #fff, #ff8a00);
            -webkit-box-shadow: hsla(0, 0%, 100%, 0.5);
            box-shadow: hsla(0, 0%, 100%, 0.5);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            -webkit-transition: all 0.4s linear;
            transition: all 0.4s linear;
          }
        }

        > li:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>