<template>
  <div v-if="!navObj.loading" class="vipInfo">
    <div class="memberform-fuild">
      <div class="rank-block">
        <div class="member-rank">
          <div class="member-box">
            <img src="@/assets/pc/member/avater.png" class="member-avater">
            <div class="rank-info">
              <div class="rank-title">{{t('VIP')}}<span>{{ navObj.data.CurVipLevel }}</span></div>
              <div class="member-name">{{ userInfo.Account }}</div>
            </div>
          </div>
          <div class="des-btn" @click="toComInfoPop(t('VIP Bonus Statement'))">
            <i class="glyphicon glyphicon-info-sign"></i>
            <span>{{t('VIP Bonus Statement')}}</span>
          </div>
        </div>
        <div class="qualifications">
          <div class="qualifications-title">
            <p>{{t('Distance to next level:')}}
              <b> {{t('VIP')}} </b>
              <b>{{ (navObj.data.CurVipLevel + 1) > navObj.data.MaxLevel ? navObj.data.MaxLevel :
                                navObj.data.CurVipLevel + 1 }}</b>
            </p>
            <button v-if="navObj.data.CurVipLevel <= 1" class="level-btn" @click="toRouter('/member/profile/personal')">
              {{t('Personal Information')}}</button>
            <button v-else class="level-btn" @click="toRouter('/member/cashier/deposit/depositInfo')">
              {{t('Recharge')}}</button>
          </div>
          <div v-if="navObj.data.CurVipLevel <= 1" class="progress-box level-1">
            <span class="title">{{t('Completed Personal Informationt')}}</span>
            <div class="progress-bar">
              <el-progress :show-text="false" :stroke-width="30" :percentage="5" />
              <span class="progress-text">(0/1)</span>
            </div>
          </div>
          <div v-else>
            <div class="progress-box level-1">
              <span class="title">{{t('Deposit Amount')}}</span>
              <div class="progress-bar">
                <el-progress :show-text="false" :stroke-width="30" :percentage="(navObj.data.CurVipCharge / navObj.data.GoalVipCharge) * 100" />
                <span class="progress-text">({{ tools.formatNum2k(navObj.data.CurVipCharge) }}/ {{
                                    tools.formatNum2k(navObj.data.GoalVipCharge) }}
                  )</span>
              </div>
            </div>
            <div class="progress-box level-1">
              <span class="title">{{t('Total Bet Amount')}}</span>
              <div class="progress-bar">
                <el-progress :show-text="false" :stroke-width="30" :percentage="(navObj.data.CurVipBet / navObj.data.GoalVipBet) * 100" />
                <span class="progress-text">({{ tools.formatNum2k(navObj.data.CurVipBet) }}/ {{
                                    tools.formatNum2k(navObj.data.GoalVipBet) }}
                  )</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="ranks-rules">
        <span class="order"> {{ navObj.data.CurVipLevel }}/{{ navObj.data.MaxLevel }}</span>
        <swiper @swiper="setVSwiperRef($event)" :modules="navObj.modules" :grab-cursor="true" :navigation="navigation" slides-per-view="auto">
          <swiper-slide v-for="(item, index) in navObj.list" :key="item" @click="navItemClick(item, index)">
            <div class="rule-box accomplish" :class="index == navObj.active ? 'active' : ''">
              <h5>{{t('VIP')}} <span>{{ index + 1 }}</span></h5>
              <div class="rankContent">
                <div v-if="index == 0" class="rank-1 justCenter">
                  <div class="rule-item">{{t('Registered')}}</div>
                </div>
                <div v-else-if="index == 1" class="rank-1 justCenter">
                  <div class="rule-item">{{t('Full Name')}}</div>
                  <div class="rule-item">{{t('Phone')}}</div>
                  <div class="rule-item">{{t('Payment Info')}}</div>
                  <div class="rule-item">{{t('Mobile Verification')}}</div>

                </div>
                <div v-else>
                  <div class="privilegeWrapper">
                    <div class="privilegeWing">{{t('VIP Bonus')}}</div>
                  </div>
                  <div class="rank-other">
                    <div class="rule-item">{{t('Deposit Amount:')}}
                      <span>{{ tools.formatNum2k(item.ChargeNeed) }}</span>
                    </div>
                    <div class="rule-item">{{t('Total Bet Amount:')}}
                      <span>{{ tools.formatNum2k(item.BetNeed) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev-vip-info" />
        <div class="swiper-button-next-vip-info" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const navigation = ref({
  nextEl: ".swiper-button-next-vip-info",
  prevEl: ".swiper-button-prev-vip-info",
});

//导航栏
let navObj = reactive({
  modules: [Pagination, FreeMode, Navigation],
  swiperRef: null,
  active: -1,
  data: {
    CurVipLevel: 0,
    CurVipBet: 0,
    GoalVipBet: 0,
    CurVipCharge: 0,
    GoalVipCharge: 0,
    MaxLevel: 0,
    VipLevelGift: 0
  },
  list: [],
  loading: true,
})

if (isLogged.value) {
  getVipData()
}

bus.on(bus.event.vipUpdateNtf, () => {
  getVipData();
})
bus.on('onConnected', () => {
  getVipData();
})

function getVipData() {
  let parms = {
  }
  mqant.request(topic.vipNewActivity, parms, function (data, topicName, msg) {
    log.info('vipNewActivity--', data.Data)
    if (data.Code == 0) {
      navObj.data = data.Data;
      setVipList();
      navObj.loading = false;
    } else {
    }
  });
}

function setVipList() {
  let arr = [];
  for (let i = 1; i <= navObj.data.MaxLevel; i++) {
    let curVipObj = navObj.data.VipConf.find(info => info.Level == i);
    arr.push(curVipObj);
  }
  navObj.list = arr;
  for (let key in navObj.list) {
    if (navObj.list[key].Level == navObj.data.CurVipLevel) {
      navObj.active = Number(key);
    }
  }
  navObj.swiperRef?.slideTo(navObj.active, 1000, false);
}

function navItemClick(item, index) {
  navObj.active = index;
  navObj.swiperRef?.slideNext();
}
function setVSwiperRef(swiper) {
  navObj.swiperRef = swiper;
}

function toRouter(path) {
  router.push({
    path: path
  })
}

function toComInfoPop(str) {
  bus.emit(bus.event.comInfoPop, { bool: true, data: { title: str } });
}

</script>

<style lang="scss" scoped>
.vipInfo {
  position: relative;

  .memberform-fuild {
    .rank-block {
      border-radius: 10px;
      padding: 0;
      background-color: #ffffff;
      box-shadow: 0px 3px 8px 0px rgba(68, 68, 70, 0.14);
      border-radius: 8px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      min-height: 265px;

      .member-rank {
        margin: 0;
        padding: 20px 20px 20px 20px;
        position: relative;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 250px;
        flex: 0 0 250px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .member-box {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 20px;

          .rank-info {
            margin-left: 20px;
            line-height: 20px;

            .rank-title {
              color: #333333;
              font-size: 24px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              margin-bottom: 10px;

              span {
                color: #f39c12;
                font-size: 36px;
                margin-left: 10px;
              }
            }

            .member-name {
              font-size: 24px;
              font-weight: 700;
              color: #0071e3;
            }
          }
        }

        .des-btn {
          background-color: #ffe3d3;
          padding: 5px 8px 4px 10px;
          border-radius: 18px;
          font-size: 16px;
          color: #ac7b3c;
          margin-top: 25px;
          cursor: pointer;

          .glyphicon-info-sign {
            font-size: 25px;
            margin-right: 10px;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
          }
        }
      }

      .qualifications {
        padding: 0 40px;
        margin: 20px 0;
        width: 100%;
        color: #000;
        position: relative;

        .qualifications-title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: baseline;
          -ms-flex-align: baseline;
          align-items: baseline;
        }

        p {
          font-size: 18px;
          color: #1b1b1b;
          margin: 0 0 10px;
          white-space: nowrap;

          b {
            font-weight: 900;
          }

          b:last-child {
            color: #000;
          }
        }

        .level-btn {
          position: relative;
          min-width: 130px;
          height: 40px;
          font-size: 16px;
          margin-left: 25px;
          border-radius: 20px;
          padding: 9px 20px;
          border: none;
          color: #fff;
          background: #0071e3;
          box-shadow: 0 2px 0 0 #0071e3,
            inset -2px 1px 4px 2px hsla(0, 0%, 100%, 0.54);
          cursor: pointer;
          white-space: nowrap;
        }

        .progress-box {
          margin-bottom: 5px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 20px;

          .title {
            font-size: 16px;
          }

          .progress-bar {
            position: relative;

            :deep(.el-progress) {
              position: relative;
              line-height: 1;

              .el-progress-bar {
                padding-right: 0;
                margin-right: 0;
                display: block;

                .el-progress-bar__outer {
                  border-radius: 15px;
                  background-color: #f7f7f7;
                  overflow: hidden;
                  position: relative;
                  vertical-align: middle;
                  height: 30px !important;

                  .el-progress-bar__inner {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    background-color: #409eff;
                    text-align: right;
                    border-radius: 100px;
                    line-height: 1;
                    white-space: nowrap;
                    border-radius: unset;
                    background-color: #00a0f9;
                  }
                }
              }
            }
          }

          .progress-text {
            position: absolute;
            top: 6px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            font-size: 12px;
            color: #333333;
          }
        }
      }

      .qualifications:before {
        content: "";
        height: 100%;
        width: 1px;
        background-color: #e0e0e0;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }

    .ranks-rules {
      margin-top: 40px;
      position: relative;

      .order {
        position: absolute;
        top: -20px;
        right: 10px;
        color: #575757;
        font-size: 14px;
      }

      :deep(.swiper) {
        .swiper-slide {
          width: auto;
        }

        .rule-box {
          width: 250px;
          height: 250px;
          margin: 0 5px;
          padding: 16px 10px 0;
          border-radius: 10px;
          border: solid 1px #0071e3;
          background-image: linear-gradient(
              0deg,
              #ffffff 16%,
              #ffffff 50%,
              #ffffff 100%
            ),
            linear-gradient(#ffffff, #ffffff);
          position: relative;

          h5 {
            margin: 0 auto;
            font-size: 18px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.9px;
            text-align: center;
            color: #00a0f9;
          }

          .rankContent {
            height: calc(100% - 25px);
            overflow: hidden;

            .justCenter {
              margin: 0;
              height: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-flow: column;
              flex-flow: column;
            }

            .rule-item {
              color: #000;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: -0.8px;
            }

            .rank-1 {
              text-align: center;
            }

            .privilegeWrapper {
              color: #1b1b1b;
              margin-bottom: 20px;
              text-align: center;
              font-size: 14px;
              position: relative;

              .privilegeWing {
                font-size: 16px;
                position: relative;
                font-weight: 500;
                display: inline-block;
              }

              .privilegeWing:before,
              .privilegeWing:after {
                content: "";
                width: 48px;
                height: 40px;
                position: absolute;
                top: -13px;
                filter: brightness(0) saturate(100%) invert(84%) sepia(87%)
                  saturate(1064%) hue-rotate(313deg) brightness(91%)
                  contrast(95%);
              }

              .privilegeWing:before {
                background: url("@/assets/pc/common/vip_show_left.png");
                left: -52px;
              }

              .privilegeWing::after {
                background: url("@/assets/pc/common/vip_show_right.png");
                right: -52px;
              }
            }

            .privilegeWrapper:after {
              content: "";
              height: 1px;
              width: 80%;
              background-color: #e0e0e0;
              position: absolute;
              bottom: -11px;
              left: 10%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }

            .rank-other {
              margin-top: 30px;

              .rule-item {
                color: #000;
                padding-bottom: 2px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: -0.8px;
                margin-bottom: 5px;

                span {
                  float: right;
                }
              }
            }
          }
        }

        .active {
          border: solid 1px #0071e3;
          background-image: linear-gradient(
              -1deg,
              #fff1e6 8%,
              #fff9f4 50%,
              #ffffff 100%
            ),
            linear-gradient(#f7f7f7, #f7f7f7);
        }
      }

      .swiper-button-next-vip-info,
      .swiper-button-prev-vip-info {
        width: 20px;
        height: 20px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        z-index: 0;
        border: none;
        color: transparent;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        background: #8697a2;
      }

      .swiper-button-next-vip-info {
        right: -20px !important;
      }

      .swiper-button-prev-vip-info {
        left: -20px !important;
      }

      .swiper-button-prev-vip-info::after {
        content: "";
        width: 13px;
        height: 13px;
        position: absolute;
        top: -1px;
        right: -1px;
        background-color: #fff;
      }

      .swiper-button-next-vip-info::after {
        content: "";
        width: 13px;
        height: 13px;
        position: absolute;
        bottom: -1px;
        left: -1px;
        background-color: #fff;
      }

      .swiper-button-disabled {
        background: #8697a2;
        cursor: not-allowed;
      }
    }
  }
}
</style>