import Fingerprint from "fingerprintjs2";
import log from "@/utils/logger";
import { http, topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { req, qs } from "@/utils/request";
import tools from "@/utils/tools";

/**
 * lobby/info:
 {
  "DynamicPicture": 
}
 */
export const record = {
    namespaced: true,
    state: {
        betRecord: '',
        mailList: [
        ],
    },
    mutations: {
        setBetRecord(state, betRecord) {
            state.betRecord = betRecord
        },
        setMailList(state, mail) {
            state.mailList = mail
        },
    },
    actions: {
        requestBetRecord({ commit, dispatch, rootGetters, getters }, params) {
            //{ FactoryName: params.factoryName, startDate: params.startDate, endDate: params.endDate, }
            return new Promise((resolve, reject) => {
                mqant.request(topic.betRecord, params,
                    function (res, topic, msg) {
                        // console.log(res)
                        // console.log(msg.payloadString)
                        if (res.Code == 0) {
                            commit("setBetRecord", res.Data)
                            resolve()
                        } else {
                            reject()
                        }
                    });
            })
        },
        reqMailList({ commit, dispatch, rootGetters, getters }) {
            return new Promise((resolve, reject) => {
                mqant.request(topic.mailList, {}, function (res, topicName, msg) {
                    //console.log(res)
                    if (res.Code == 0) {
                        commit("setMailList", res.Data.mail)
                    }
                    resolve()
                });
            })

        },
        reqUpdateMail({ commit, dispatch, rootGetters, getters }, params) {
            return new Promise((resolve, reject) => {
                mqant.request(
                    topic.updateMail,
                    params,
                    function (res, topicName, msg) {

                       // console.log(res);
                        resolve(res)
                    }
                );
            })
        },
        delMail({ commit, dispatch, rootGetters, getters }, params){
            return new Promise((resolve, reject) => {
                mqant.request(topic.deleteMail, params, function(res, topicName, msg) {
                    resolve(res)
                });
            })
        }
    },
    getters: {
        betRecord(state) {
            return state.betRecord
        },
        mailList(state) {
            return state.mailList
        }
    }
}
