<template>
  <div class="walletPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="balancelist-wrapper">
        <div class="balancelist-bg">
          <div class="tabs-wrapper">
            <ul class="tabs-lists">
              <li class="tabs-list tabs-wallet active">
                <a class="tabs-tab">
                  <div class="icon-img rounded">
                    <img src="@/assets/pc/common/wallet-icon.png" alt="">
                  </div>
                  <p class="title">{{t('Wallet')}}</p>
                  <p class="price">{{ tools.formatNum2k(wallet.VndBalance + wallet.BonusBalance) }}</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="tabs-content">
            <div class="tabs-content-panel">
              <div class="balancelist-header">
                <div class="balancelist-total">
                  <div class="balancelist-title">
                    <div class="group">
                      <img src="@/assets/pc/header/wallet-icon.png">
                      <span>{{t('Wallet')}}</span>
                    </div>
                  </div>
                  <h3 class="balancelist-price">
                    <span> {{ tools.formatNum2k(wallet.VndBalance + wallet.BonusBalance) }}</span>
                    <i>
                      <img :class="isRefresh ? 'fa-spin' : ''" src="@/assets/pc/common/refresh-icon.png" alt="Refresh" class="" @click="refresh()">
                    </i>
                  </h3>
                  <ul class="balancelist-btn">
                    <li>
                      <a class="btn btn-deposit" title="Deposit" data-analytics="dpst-link" data_vue_tag="nuxt-link" @click="toRouter('/member/cashier/deposit/depositInfo')">{{t('Deposit')}}</a>
                    </li>
                    <li>
                      <a class="btn btn-withdrawal" title="Withdraw" data_vue_tag="nuxt-link" @click="toRouter('/member/cashier/withdrawal/withdrawalInfo')">{{t('Withdraw')}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="balancelist-content">
                <div class="balancelist-item main">
                  <ul>
                    <li>
                      <img src="@/assets/pc/header/balance-main.png"><span>{{t('Total Main Amount')}}</span>
                    </li>
                    <li class="isActive">{{ tools.formatNum2k(wallet.VndBalance) }}</li>
                  </ul>
                </div>
                <div class="balancelist-item promote">
                  <ul>
                    <li>
                      <img src="@/assets/pc/header/balance-promotion.png"><span>{{t('Total Promotion Amount')}}</span>
                      <!-- <span class="lock-icon"></span> -->
                    </li>
                    <li class="">{{ tools.formatNum2k(wallet.BonusBalance) }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="close-btn" @click="close()">{{t('Cancel')}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {}
})
const isRefresh = ref(false);
function refresh() {
  isRefresh.value = !isRefresh.value;
  setTimeout(() => {
    isRefresh.value = !isRefresh.value;
  }, 2000);
}

function toRouter(path) {
  router.push({
    path: path
  })
  close();
}

function close() {
  bus.emit(bus.event.walletPop, false);
}
</script>

<style lang="scss" scoped>
.walletPop {
  position: relative;
  text-align: center;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    margin-top: 10vh;
    width: 500px;
    max-width: 100%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #0f161f;
      font-size: 14px;
      overflow: visible;
      padding: 0;
      .balancelist-wrapper {
        position: relative;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        max-height: 95vh;
        height: 675px;
        padding-top: 0;
        padding-right: 15px;
        padding-left: 15px;
        width: 100%;
        border: 5px solid #ffffff;
        border-radius: 10px;
        z-index: 1;
        -webkit-box-shadow: none;
        box-shadow: none;

        .balancelist-bg {
          width: 100%;
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;

          .tabs-wrapper {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            padding-top: 15px;
            padding-bottom: 15px;
            overflow-x: auto;
            overflow-y: hidden;
            margin-right: -15px;
            margin-left: -15px;
            text-align: center;
            font-size: 0;

            .tabs-lists {
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              -webkit-box-align: stretch;
              -ms-flex-align: stretch;
              align-items: stretch;
              margin-right: 15px;
              margin-left: 15px;
              padding: 15px 0;
              border-radius: 5px;
              background: #f7f7f7;
              font-size: medium;
              border: 3px solid transparent;

              .tabs-list {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 150px;
                flex: 0 0 150px;
                width: 150px;
                position: relative;

                .tabs-tab {
                  position: relative;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                  flex-direction: column;
                  -webkit-box-pack: start;
                  -ms-flex-pack: start;
                  justify-content: flex-start;
                  padding: 0 15px;
                  opacity: 1;
                  -webkit-transition: width 0.2s ease-in;
                  transition: width 0.2s ease-in;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;

                  .icon-img {
                    background-color: #ff8a00;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    margin: auto;
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;

                    img {
                      max-width: 100%;
                      max-height: 100%;
                      width: 31px;
                      -webkit-filter: brightness(0) invert(1);
                      filter: brightness(0) invert(1);
                    }
                  }

                  p {
                    margin: 0 0 10px;
                  }

                  .title {
                    margin: 5px 0 1px;
                    font-weight: 700;
                    font-size: 12px;
                  }

                  .price {
                    font-weight: 700;
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .tabs-content {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            position: relative;

            .tabs-content-panel {
              position: absolute;
              top: 0;
              left: 0;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              overflow-x: hidden;
              overflow-y: hidden;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              width: 100%;
              height: 100%;
              -webkit-transition: all 0.3s ease-in;
              transition: all 0.3s ease-in;

              .balancelist-header {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;

                .balancelist-total {
                  position: relative;
                  padding: 15px 10px;
                  border: 0;
                  border-radius: 5px;
                  background: url("@/assets/pc/header/balance-total.jpg");
                  background-position: 50% 50%;
                  background-size: cover;
                  background-repeat: no-repeat;
                  color: #fff;
                  text-align: center;

                  .balancelist-title {
                    background: none;
                    color: #fff;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 0;

                    .group {
                      display: -webkit-inline-box;
                      display: -ms-inline-flexbox;
                      display: inline-flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      text-align: left;
                      font-size: medium;

                      img {
                        margin-right: 10px;
                        -webkit-filter: brightness(0) invert(1);
                        filter: brightness(0) invert(1);
                        max-width: 100%;
                        max-height: 100%;
                        width: 25px;
                      }
                    }
                  }

                  .balancelist-price {
                    margin-top: 15px;
                    margin-bottom: 0;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 500;
                    line-height: 1.1;

                    span {
                      text-shadow: 0 2px rgba(0, 0, 0, 0.35);
                      font-size: 24px;
                      line-height: 30px;
                    }

                    i {
                      display: inline-block;
                      margin-left: 15px;
                      width: 25px;
                      height: 25px;
                      cursor: pointer;

                      img {
                        width: 100%;
                        vertical-align: top;
                      }

                      .fa-spin {
                        transition: opacity 0.3s;
                        animation: fa-spin 2s linear infinite;
                      }

                      @keyframes fa-spin {
                        0% {
                          -webkit-transform: rotate(0deg);
                          transform: rotate(0deg);
                        }

                        100% {
                          -webkit-transform: rotate(359deg);
                          transform: rotate(359deg);
                        }
                      }
                    }
                  }

                  .balancelist-btn {
                    margin-top: 15px;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    li {
                      .btn {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        padding: 10px 20px;
                        min-width: 140px;
                        font-weight: 700;
                      }

                      .btn-deposit {
                        position: relative;
                        background-image: linear-gradient(
                            -2deg,
                            #ff8a00 13%,
                            #ff8a00 100%
                          ),
                          linear-gradient(#000000, #000000);
                        color: #fff;
                        border-radius: 5px;
                      }

                      .btn-withdrawal {
                        background-image: linear-gradient(
                            -2deg,
                            #08b8ea 13%,
                            #08b8ea 100%
                          ),
                          linear-gradient(#000000, #000000);
                        color: #fff;
                        margin-left: 10px;
                        position: relative;
                        border-radius: 5px;
                      }
                    }
                  }
                }
              }

              .balancelist-content {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                margin-top: 15px;
                padding: 0;

                .balancelist-item {
                  position: relative;
                  z-index: 2;
                  border-radius: 5px;
                  cursor: pointer;
                  *zoom: 1;

                  ul {
                    overflow: hidden;
                    border: 0;
                    border-radius: 5px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;

                    li {
                      position: relative;
                      width: 50%;
                      background-color: #f7f7f7;
                      vertical-align: top;
                      line-height: 30px;
                    }

                    li:first-child {
                      display: flex;
                      justify-content: start;
                      padding: 10px 5px 10px 10px;
                      text-transform: capitalize;
                      width: 60%;
                      background-color: #f7f7f7;
                      vertical-align: top;
                      line-height: 30px;

                      img {
                        position: absolute;
                        top: 50%;
                        margin-right: 10px;
                        width: 30px;
                        vertical-align: top;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                      }

                      span {
                        position: relative;
                        display: inline-block;
                        padding-left: 40px;
                        white-space: nowrap;
                      }

                      .lock-icon {
                        width: 20px;
                        height: 20px;
                        position: relative;
                        top: 5px;
                        margin-left: 5px;
                        padding-left: 0;
                        background: #fff;
                        border-radius: 50%;
                      }

                      .lock-icon:after {
                        width: 100%;
                        height: 100%;
                        content: "";
                        position: absolute;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-family: fontawesome;
                        color: #000;
                      }
                    }

                    li:last-child {
                      padding: 10px 60px 10px 0;
                      text-align: right;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: end;
                      -ms-flex-pack: end;
                      justify-content: flex-end;
                      padding-right: 10px;
                      width: 40%;
                    }
                  }
                }

                .main {
                  margin-bottom: 20px;
                }
              }
            }
          }

          .close-btn {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-right: -15px;
            margin-left: -15px;
            padding: 20px 0;
            width: calc(100% + 30px);
            height: 60px;
            color: #959595;
            text-align: center;
            text-transform: uppercase;
            font-size: 16px;
            line-height: normal;
            cursor: pointer;
            -webkit-transition-delay: 2s;
            transition-delay: 2s;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
          }

          .close-btn::before {
            display: inline-block;
            margin-right: 10px;
            width: 17px;
            height: 17px;
            background: url("@/assets/pc/common/dark-close.png");
            background-size: 100%;
            content: "";
            vertical-align: middle;
          }
        }
      }

      .balancelist-bg > *,
      .balancelist-wrapper > * {
        z-index: 1;
      }

      .balancelist-wrapper::after {
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(-2deg, #fff 0%, #fff 100%),
          linear-gradient(#ffffff, #ffffff);
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>