<template>
  <div class="slot">
    <div class="mobile-navbar-header">
      <div class="mobile-nav header-grey">
        <div class="col-left">
          <img class="beta-back" src="@/assets/pc/common/svg-mb-back.svg" alt="" @click="back()">
          <div class="provider-icon">
            <img :src="comApi.getFactoryIcon(navObj.list[navObj.index].factoryInfo.FactoryName)" alt="">
          </div>
        </div>
        <div class="col-right">
          <div>
            <div class="standard-select" @click.stop="factoryDropOpen= !factoryDropOpen">
              <span class="nav-title">{{navObj.list[navObj.index].factoryInfo.Name}}</span>
              <span class="standard-select-arrow"></span>
            </div>
            <div v-if="factoryDropOpen" ref="factoryDropRef" class="standard-select-dropdown sort-type-dropdown ">
              <div v-for="(item,index) in navObj.list" class="standard-select-options" :class="navObj.index == index ? 'active':''" @click="itemClick(item,index)">
                <img :src="comApi.getFactoryIcon(item.factoryInfo.FactoryName)" alt="">
                <p>{{item.factoryInfo.Name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-content-container">
      <div class="m-provider-content-container m-casino-container">
        <div class="provider-content">
          <div class="filter-container">
            <div class="sort-container">
            </div>
            <div class="search-form-container">
              <img src="@/assets/pc/common/svg-search.svg" alt="">
              <input v-model="searchObj.searchKey" type="seach" class="search-input" :placeholder="t('搜索')" v-on:input="searchFun">
            </div>
          </div>
          <div class="standard-tab-container tab-container">
            <div class="standard-tab provider-filter-tab">
              <div class="tab-header filter-header active">{{t('全部游戏')}}</div>
            </div>

          </div>
          <div v-if="!searchObj.isSearch" class="game-container">
            <div v-for="(item,index) in navObj.showList" class="game-box animated fadeInUp" @click="comApi.toFactory(item)">
              <div class="game-img-wrapper">
                <div class="game-img">
                  <img :src="comApi.getFactoryGameIcon(item,3)" alt="" lazy="loaded">
                </div>
              </div>
              <div class="game-details">
                <div class="game-name-info">
                  <p class="game-name">{{ item.GameNameEn }}</p>
                </div>
                <div class="game-others-info"></div>
              </div>
            </div>
          </div>
          <div v-else class="game-container">
            <div v-for="(item,index) in searchObj.list" class="game-box animated fadeInUp" @click="comApi.toFactory(item)">
              <div class="game-img-wrapper">
                <div class="game-img">
                  <img :src="comApi.getFactoryGameIcon(item,3)" alt="" lazy="loaded">
                </div>
              </div>
              <div class="game-details">
                <div class="game-name-info">
                  <p class="game-name">{{ item.GameNameEn }}</p>
                </div>
                <div class="game-others-info"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const factoryDropRef = ref(null);
const factoryDropOpen = ref(false);

onMounted(() => {
  document.addEventListener('click', (e) => {
    if (factoryDropRef.value) {
      if (!factoryDropRef.value.contains(e.target)) {
        if (factoryDropOpen.value) {
          factoryDropOpen.value = false;
        }
      }
    }
  })
})

let navList = comApi.getCurNavListByType('Slot');
let navObj = reactive({
  index: 0,
  showList: [],
  list: navList,
})

let searchObj = reactive({
  searchKey: '',
  isSearch: false,
  list: []
});

function setList() {
  navObj.showList = navObj.list[navObj.index].gameList;
}

function itemClick(item, index) {
  navObj.index = index;
  curRouter.query.fc = navObj.list[navObj.index].factoryInfo.FactoryName;
  navObj.showList = [];
  factoryDropOpen.value = false;
  nextTick(() => {
    setList();
  })
}

function searchFun(key) {
  if (searchObj.searchKey.length == 0) {
    searchObj.isSearch = false;
    return;
  }
  searchObj.list = [];
  navObj.showList.forEach(item => {
    if (item.GameNameEn) {
      if (item.GameNameEn.toLowerCase().indexOf(searchObj.searchKey.toLowerCase()) >= 0) {
        searchObj.list.push(item);
      }
    }
  });
  searchObj.isSearch = true;
}

//处理选中路由 
function setSelectIndex() {
  if (curRouter.path == '/slot') {
    if (curRouter.query.fc == null || curRouter.query.fc == undefined) {
      navObj.index = 0;
      nextTick(() => {
        setList();
      })
      return;
    }
    for (let key in navObj.list) {
      let info = navObj.list[key];
      if (info.factoryInfo.FactoryName == curRouter.query.fc) {
        navObj.index = Number(key);
        nextTick(() => {
          setList();
        })
        return;
      }
    }
  }
}

watch(() => {
  curRouter.path, setSelectIndex();
})

function back() {
  router.go(-1);
}

function toRegister() {
  bus.emit(bus.event.resgisterPop, true);
}

</script>

<style lang="scss" scoped>
.slot {
  .mobile-navbar-header {
    position: fixed;
    position: -webkit-fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    color: #0f161f;
    background: #fff;
    .mobile-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 45px;
      padding: 0 3px 0 10px;
      background: #fff;
      border-bottom: 2px solid #f3f5f6;
      background: #f3f5f6;
      .col-left {
        display: flex;
        align-items: center;
        .beta-back {
          width: 18px;
          overflow: hidden;
          height: 100%;
        }
        .provider-icon {
          position: relative;
          height: 25px;
          max-width: 120px;
          width: 100px;
          position: relative;
          img {
            overflow: hidden;
            width: 100%;
            height: 100%;
          }
        }
      }
      .col-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        margin: 0 0 0 10px;
        .standard-select {
          min-width: 130px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #fff;
          border-radius: 25px;
          padding: 8px 50px 7px 20px;
          font-size: 15px;
          color: #0f161f;
          cursor: pointer;
          .nav-title {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
          .standard-select-arrow {
            position: absolute;
            right: 16px;
            top: 10px;
            border-width: 0 2px 2px 0;
            border-color: #afb3b6;
            border-style: solid;
            display: inline-block;
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            color: #8697a2;
          }
          .active {
            transform: translate(0%, 50%) rotate(225deg);
          }
        }
        .standard-select-dropdown {
          position: absolute;
          left: -125px;
          right: 0;
          top: 40px;
          border: 1px solid #d6d6d6;
          background: #fff;
          z-index: 2;
          width: auto;
          overflow-y: scroll;
          padding: 18px 0;
          border-radius: 5px;
          max-height: 65vh;
          font-size: 15px;
          .standard-select-options {
            position: relative;
            display: grid;
            grid-template-columns: 3fr 7fr;
            grid-gap: 0 28px;
            align-items: center;
            padding: 0 20px;
            height: 31px;
            color: #8899a4;
            white-space: nowrap;
            border-radius: 25px;
            img {
              width: 100%;
              height: 60%;
              object-fit: cover;
              filter: grayscale(1) opacity(0.4);
            }
          }
          .standard-select-options:not(:last-child) {
            padding: 0 20px;
          }
          .active {
            background: #ff8a00;
            color: #fff;
            img {
              filter: grayscale(0%);
            }
          }
        }
      }
    }
  }
  .body-content-container {
    margin-top: 44px;
    overflow: auto;
    min-height: calc(100vh - 1rem);
    .m-provider-content-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      .provider-content {
        flex: 1;
        background: #f7f7f7;
        padding: 10px;
        font-size: 15px;
        color: #0f161f;
        .filter-container {
          display: grid;
          grid-template-columns: 4fr 6fr;
          grid-gap: 0 10px;
          height: 32px;
          .search-form-container {
            display: flex;
            align-items: center;
            background: #fff;
            border-radius: 25px;
            height: 100%;
            padding: 0 13px;
            img {
              width: 20px;
              height: 20px;
            }
            .search-input {
              margin: 0 0 0 13px;
              background: rgba(0, 0, 0, 0);
              font-size: 15px;
              color: #8899a4;
            }
            input {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 20px;
              padding: 2px;
              border: none;
              color: #000;
              background: #f5f5f5;
            }
          }
        }
        .standard-tab-container {
          width: 100%;
          margin: 18px 0 0;
          background: unset;
          overflow: scroll;
          .standard-tab {
            display: flex;
            width: fit-content;
            font-size: 13px;
            border-bottom: 0;
            padding: 0 5px;
            .tab-header {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 32px;
              padding: 0 22px;
              border-radius: 25px;
              color: #8899a4;
              width: auto;
              white-space: nowrap;
              flex-grow: inherit;
              margin: 0;
              font-weight: 500;
            }
            .active {
              background: #ff8a00;
              color: #fff;
            }
          }
        }
        .game-container {
          grid-template-columns: repeat(3, 1fr);
          display: grid;
          grid-gap: 20px 6px;
          margin: 22px 0;
          min-height: 300px;
          .game-box {
            min-width: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            .game-img-wrapper {
              width: 100%;
              position: relative;
              border-radius: 10px;
              overflow: hidden;
              .game-img {
                width: 100%;
                padding-bottom: 145%;
                height: 0;
                overflow: hidden;
                position: relative;
                background: rgba(0, 0, 0, 0.1019607843);
                img {
                  position: absolute;
                  left: 0;
                  right: 0;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .game-details {
              position: relative;
              margin: 8px 0 0;
              padding: 0 0 0 4px;
              .game-name-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                height: 15px;
                .game-name {
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>