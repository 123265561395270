<template>
  <div class="loading">
    <div class="main-cont">
      <div class="brand">
        <div class="brand-img-wrapper">
          <img src="https://f.kingroman999.com/beteasy/launch.png" class="logo">
        </div>
      </div>
      <div class="loading-cont">
        <img src="@/assets/common/loading.svg" class="loading-icon">

      </div>
    </div>
  </div>
</template>

<script setup>
import { bus } from "@/components/core/bus";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import { onMounted } from "vue";
import { onBeforeUnmount } from "vue";

</script>

<style lang="scss" scoped>
.loading {
  --card-height: 65vh;
  --card-width: 70vw;
  --rotate: 132deg;

  position: fixed;
  background-color: #242337;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: -apple-system-font, Helvetica Neue, PingFang SC, STHeitiSC-Light,
    Arial, sans-serif;
  z-index: 99999999;

  .-apple-system-font {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 70vw;
    max-width: 600px;
  }

  .main-cont {
    box-sizing: border-box;
    background-image: linear-gradient(180deg, #241d38 8%, #2b1e5d 100%),
      linear-gradient(#540000, #540000);
    width: var(--card-width);
    height: var(--card-height);
    max-width: 600px;
    padding: 3px;
    position: relative;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    .brand {
      height: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;

      .brand-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        width: 70vw;
        max-width: 600px;

        img {
          width: 80%;
          max-width: 300px;
        }
      }

      .loading-msg {
        width: 100%;
        font-weight: normal;
        line-height: 1.4;
        font-size: 19px;
        padding: 0 5px;
        margin: 0.3em 0 0.83em 0;
      }
    }

    .loading-cont {
      position: absolute;
      bottom: 10px;
      width: 100%;
      color: #fff;
      text-shadow: 0 1px 1px #000;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px auto;
      font-size: 19px;
      opacity: 0.6;
      min-height: 30px;

      img {
        width: 30px;
      }
    }
  }

  .main-cont:before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border-radius: 13px;
    background-image: linear-gradient(
      var(--rotate),
      #bd81e3,
      #3c67e3 43%,
      #5ddcff
    );
    position: absolute;
    z-index: -1;
    top: -3px;
    left: -3px;
    animation: light-spin 4.5s linear infinite;
  }

  @keyframes light-spin {
    0% {
      --rotate: 0deg;
    }

    100% {
      --rotate: 360deg;
    }
  }

  .main-cont::after {
    position: absolute;
    content: "";
    top: calc(var(--card-height) / 6);
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.8);
    filter: blur(calc(var(--card-height) / 6));
    background-image: linear-gradient(
      var(--rotate),
      #5ddcff,
      #3c67e3 43%,
      #4e00c2
    );
    opacity: 1;
    transition: opacity 0.5s;
    animation: light-spin 4.5s linear infinite;
  }
}
</style>