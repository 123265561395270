
import tools from '@/utils/tools'
let mobile = process.env.VUE_APP_UI_MOBILE
let pc = process.env.VUE_APP_UI_ASSETS_PC

let img = function (src, subPath) {
    if (subPath) return require('@/assets/' + subPath + '/' + src)
    if (tools.isMobile()) {
        return require('@/assets/' + mobile + '/' + src)
    }
    return require('@/assets/' + pc + '/' + src)
}
export { img } 